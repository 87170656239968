import React, { useCallback, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createSelector, nanoid, unwrapResult } from "@reduxjs/toolkit";
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { update } from "./sliceA";
import {
  employmentStatusOptions,
  genderOptions,
  maritialOptions,
  yesNoOptions,
} from "../../../utils/options";
import useFormShortcuts from "../../../hooks/useFormShortcuts";
import { validateAnnuitySectionA } from "../../../thunks";
import { DevTool } from "@hookform/devtools";
import { cloneDeep } from "lodash";
import { calculateIssueAge } from "../../../utils/functions";
import ClientSystem from "./ClientSystem";

dayjs.extend(relativeTime);

const selector = createSelector(
  (state) => state.annuity_section_a,
  (state) => state.annuity,
  (state) => state.global,
  (section_a, annuity, global) => {
    return {
      formValues: { ...section_a },
      loading: section_a.loading === "pending",
      completed: annuity.completed,
      occupations: global.occupations,
    };
  }
);

const SectionA = ({ handleNext, visible }) => {
  const { formValues, loading, occupations, completed } = useSelector(selector);

  const dispatch = useDispatch();

  // initialize form with default values from store
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState,
    errors,
    reset,
  } = useForm({
    defaultValues: { ...formValues },
  });

  const { isSubmitting } = formState;

  const onNext = async (values) => {
    // Use unwrapResult to check if the request failed
    // A failed request or error in a thunk will never return
    // a rejected promisee

    // update values in the store
    dispatch(update(values));

    const promise = await dispatch(validateAnnuitySectionA()).unwrap();

    handleNext();
  };

  const handleOnNext = useCallback(() => {
    if (loading) return;
    // clear form errors before submitting
    clearErrors(undefined);

    // validate the section
    handleSubmit(onNext)();
  }, [handleSubmit, onNext, clearErrors]);

  const setManualErrors = useCallback(() => {
    reset(cloneDeep(formValues), { errors: false });

    if (formValues.inssurname.valid === false) {
      setError("inssurname.value", {
        type: "manual",
      });
    } else if (formValues.insno.valid === false) {
      setError("inssurname.value", {
        type: "manual",
        message: "No insured assigned in client system",
      });
    }

    if (
      formValues.insfname.valid === false ||
      formValues.insno.valid === false
    ) {
      setError("insfname.value", {
        type: "manual",
      });
    }

    if (formValues.insdob.valid === false) {
      setError("insdob.value", {
        type: "manual",
      });
    }

    if (formValues.insage.valid === false) {
      setError("insage.value", {
        type: "manual",
        message: "Invalid issue age for this plan",
      });
    }

    if (formValues.inscountry.valid === false) {
      setError("inscountry.value", {
        type: "manual",
      });
    }

    if (formValues.inssex.valid === false) {
      setError("inssex.value", {
        type: "manual",
      });
    }

    if (formValues.insemail.valid === false) {
      setError("insemail.value", {
        type: "manual",
      });
    }

    if (formValues.insmaritalst.valid === false) {
      setError("insmaritalst.value", {
        type: "manual",
      });
    }

    if (formValues.insid.valid === false) {
      setError("insiddp.value", { type: "manual" });
      setError("insidid.value", { type: "manual" });
      setError("insidpass.value", {
        type: "manual",
        message: "At least one ID needs to be entered",
      });
    } else {
      if (formValues.insiddp.valid === false) {
        setError("insiddp.value", {
          type: "manual",
        });
      }
    }

    if (formValues.insoccupation.valid === false) {
      setError("insoccupation.value", {
        type: "manual",
      });
    }

    if (formValues.instel.valid === false) {
      setError("instel.value", {
        type: "manual",
      });
    }

    if (formValues.insjobtitleduties.valid === false) {
      setError("insjobtitleduties.value", {
        type: "manual",
      });
    }

    if (formValues.insemployer.valid === false) {
      setError("insemployer.value", {
        type: "manual",
      });
    }

    if (formValues.insworkphone.valid === false) {
      setError("insworkphone.value", {
        type: "manual",
      });
    }

    if (formValues.insmailadd1.valid === false) {
      setError("insmailadd1.value", {
        type: "manual",
      });
    }
    if (formValues.insmailadd2.valid === false) {
      setError("insmailadd2.value", {
        type: "manual",
      });
    }
    if (formValues.insmailadd3.valid === false) {
      setError("insmailadd3.value", {
        type: "manual",
      });
    }

    if (formValues.inscurradd1.valid === false) {
      setError("inscurradd1.value", {
        type: "manual",
      });
    }
    if (formValues.inscurradd2.valid === false) {
      setError("inscurradd2.value", {
        type: "manual",
      });
    }
    if (formValues.inscurradd3.valid === false) {
      setError("inscurradd3.value", {
        type: "manual",
      });
    }

    if (formValues.insempadd1.valid === false) {
      setError("insempadd1.value", {
        type: "manual",
      });
    }

    if (formValues.insempadd2.valid === false) {
      setError("insempadd2.value", {
        type: "manual",
      });
    }

    if (formValues.insempadd3.valid === false) {
      setError("insempadd3.value", {
        type: "manual",
      });
    }

    if (formValues.empstatus.valid === false) {
      setError("empstatus.value", { type: "manual" });
    }
  }, [formValues]);

  // don't set errors when revalidating the section
  useEffect(() => {
    if (isSubmitting || loading) return;
    setManualErrors();
  }, [isSubmitting, loading]);

  const handleNextShortcut = useCallback(handleSubmit(onNext), []);
  useFormShortcuts(handleNextShortcut);

  return (
    <Grid centered columns={1}>
      <Grid.Column width={15}>
        <DevTool control={control} />
        <Form onSubmit={handleOnNext} size="large">
          <Segment padded stacked loading={loading}>
            <ClientSystem
              clientType="insured"
              label="Assign insured"
              onRefresh={handleOnNext}
            />
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="inssurname.value"
                render={({ value }) => (
                  <Form.Input
                    label="Surname"
                    fluid
                    value={value}
                    readOnly
                    error={errors?.inssurname?.value?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name="insfname.value"
                render={({ value }) => (
                  <Form.Input
                    label="Firstname"
                    fluid
                    readOnly
                    value={value}
                    error={!!errors.insfname}
                  />
                )}
              />
            </Form.Group>
            {/*  */}
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="insmidname.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    fluid
                    label="Middle name"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 20) {
                        return;
                      }

                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                  />
                )}
              />
              <Controller
                control={control}
                name="insmaidname.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    fluid
                    label="Maiden name"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 20) {
                        return;
                      }

                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.insmaidname}
                  />
                )}
              />
            </Form.Group>
            {/*  */}
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="insdob.value"
                render={({ value }) => (
                  <Form.Input
                    fluid
                    type="date"
                    label="Date of Birth"
                    value={value}
                    readOnly
                    error={!!errors.insdob}
                  />
                )}
              />
              <Controller
                control={control}
                name="insage.value"
                render={({ value }) => (
                  <Form.Input
                    fluid
                    type="number"
                    label="Age"
                    value={value}
                    readOnly
                    error={!!errors.insage && errors.insage?.value.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="inssex.value"
                render={({ value }) => (
                  <Form.Select
                    label="Gender"
                    placeholder="Select your gender"
                    value={value}
                    options={genderOptions}
                    error={!!errors.inssex}
                    fluid
                    readOnly
                  />
                )}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Controller
                control={control}
                name="inscountry.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    fluid
                    label="Country of Birth"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 30) {
                        return;
                      }

                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.inscountry}
                  />
                )}
              />
              <Controller
                control={control}
                name="citizenship.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    fluid
                    label="Country(ies) of Citizenship"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 100) {
                        return;
                      }

                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.citizenship}
                  />
                )}
              />

              <Controller
                control={control}
                name="insmaritalst.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    label="Maritial Status"
                    value={maritialOptions.find((m) => m.value === value)?.text}
                    readOnly
                    onBlur={onBlur}
                    error={!!errors.insmaritalst}
                    fluid
                  />
                )}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Controller
                control={control}
                name="insidpass.value"
                render={({ value, onBlur }) => (
                  <Form.Input
                    fluid
                    label="Passport"
                    value={value}
                    readOnly
                    onBlur={onBlur}
                    error={
                      !!errors.insidpass && errors.insidpass?.value.message
                    }
                  />
                )}
              />
              <Controller
                control={control}
                name="insiddp.value"
                render={({ value, onBlur }) => (
                  <Form.Input
                    fluid
                    label="Driver's License"
                    value={value}
                    readOnly
                    onBlur={onBlur}
                    error={!!errors.insiddp}
                  />
                )}
              />
              <Controller
                control={control}
                name="insidid.value"
                render={({ value, onBlur }) => (
                  <Form.Input
                    fluid
                    label="National ID"
                    value={value}
                    readOnly
                    onBlur={onBlur}
                    error={!!errors.insidid}
                  />
                )}
              />
            </Form.Group>
          </Segment>

          <Divider hidden />

          <Header content="Contact" />
          <Segment padded color="blue" stacked loading={loading}>
            <Controller
              control={control}
              name="insemail.value"
              render={({ value, onBlur }) => (
                <Form.Input
                  fluid
                  label="Email Address"
                  value={value}
                  readOnly
                  onBlur={onBlur}
                  error={!!errors.insemail}
                />
              )}
            />

            <Form.Group widths="equal">
              <Controller
                control={control}
                name="hometel.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    fluid
                    label="Home Tel No."
                    type="number"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 7) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.hometel}
                  />
                )}
              />
              <Controller
                control={control}
                name="mobileno.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    fluid
                    label="Mobile Tel No."
                    value={value}
                    type="number"
                    onChange={(event, data) => {
                      if (data.value.length > 7) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.mobileno}
                  />
                )}
              />
            </Form.Group>

            <Form.Field>
              <label>
                {" "}
                Mailing Address{" "}
                <small>
                  <em>Post Office Box/Physical Address</em>
                </small>
              </label>
            </Form.Field>

            <Form.Group widths="equal">
              <Controller
                control={control}
                name="insmailadd1.value"
                render={({ value }) => (
                  <Form.Input
                    fluid
                    readOnly
                    label="Line 1"
                    value={value}
                    error={!!errors.insmailadd1}
                  />
                )}
              />
              <Controller
                control={control}
                name="insmailadd2.value"
                render={({ value }) => (
                  <Form.Input
                    fluid
                    readOnly
                    label="Line 2"
                    value={value}
                    error={!!errors.insmailadd2}
                  />
                )}
              />
              <Controller
                control={control}
                name="insmailadd3.value"
                render={({ value }) => (
                  <Form.Input
                    fluid
                    label="Line 3"
                    readOnly
                    value={value}
                    error={!!errors.insmailadd3}
                  />
                )}
              />
            </Form.Group>

            <Form.Field>
              <label>Permanent Residential Address</label>
            </Form.Field>
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="inscurradd1.value"
                render={({ value }) => (
                  <Form.Input
                    label="Line 1"
                    readOnly
                    value={value}
                    fluid
                    error={!!errors.inscurradd1}
                  />
                )}
              />
              <Controller
                control={control}
                name="inscurradd2.value"
                render={({ value }) => (
                  <Form.Input
                    readOnly
                    label="Line 2"
                    value={value}
                    fluid
                    error={!!errors.inscurradd2}
                  />
                )}
              />
              <Controller
                control={control}
                name="inscurradd3.value"
                render={({ value }) => (
                  <Form.Input
                    readOnly
                    label="Line 3"
                    value={value}
                    fluid
                    error={!!errors.inscurradd3}
                  />
                )}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Controller
                control={control}
                name="postalcode.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    fluid
                    label="Postal Code"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 25) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.postalcode}
                  />
                )}
              />
              <Controller
                control={control}
                name="timeataddr.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    fluid
                    label="How long at this address?"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 30) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.timeataddr}
                  />
                )}
              />
              <Controller
                control={control}
                name="instel.value"
                rules={{
                  maxLength: {
                    value: 7,
                    message: "Must only be a number e.g 7778888",
                  },
                }}
                render={({ value }) => (
                  <Form.Input
                    fluid
                    readOnly
                    type="number"
                    label="Phone No."
                    value={value}
                    error={!!errors.instel}
                  />
                )}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Controller
                control={control}
                name="insprevadd.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    label="Previous Home Address"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 85) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.insprevadd}
                  />
                )}
              />
            </Form.Group>
          </Segment>

          <Divider hidden />

          <Header>Occupation</Header>
          <Segment padded stacked color="blue" loading={loading}>
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="empstatus.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Select
                    value={value}
                    width="6"
                    label="Employment status"
                    placeholder="Select employment status"
                    onChange={(event, data) => onChange(data.value)}
                    onBlur={onBlur}
                    search
                    clearable
                    options={employmentStatusOptions}
                    error={!!errors.empstatus}
                  />
                )}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="insoccupation.value"
                render={({ value, onBlur }) => (
                  <Form.Input
                    value={occupations.find((o) => o.value == value)?.text}
                    label="Job Title"
                    readOnly
                    onBlur={onBlur}
                    error={!!errors.insoccupation}
                  />
                )}
              />
              <Controller
                control={control}
                name="insjobtitleduties.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    fluid
                    label="Job Duties"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 60) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.insjobtitleduties}
                  />
                )}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Controller
                control={control}
                name="insemployer.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    fluid
                    label="Company/Business/Individual Name"
                    value={value}
                    readOnly
                    onBlur={onBlur}
                    error={!!errors.insemployer}
                  />
                )}
              />
              <Controller
                control={control}
                name="insworkphone.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    fluid
                    readOnly
                    type="number"
                    label="Main Contact No."
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.insworkphone}
                  />
                )}
              />
            </Form.Group>

            <Form.Field>
              <label>
                Address of Company where you are currently stationed
              </label>
            </Form.Field>
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="insempadd1.value"
                render={({ value }) => (
                  <Form.Input
                    readOnly
                    label="Line 1"
                    value={value}
                    error={!!errors.insempadd1}
                    fluid
                  />
                )}
              />
              <Controller
                control={control}
                name="insempadd2.value"
                render={({ value }) => (
                  <Form.Input
                    readOnly
                    label="Line 2"
                    value={value}
                    error={!!errors.insempadd2}
                    fluid
                  />
                )}
              />
              <Controller
                control={control}
                name="insempadd3.value"
                render={({ value }) => (
                  <Form.Input
                    readOnly
                    label="Line 3"
                    value={value}
                    error={!!errors.insempadd3}
                    fluid
                  />
                )}
              />
            </Form.Group>
          </Segment>

          <Divider hidden />

          <Header>Self Employed/Business Owner</Header>
          <Segment stacked padded color="blue" loading={loading}>
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="seregistered.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Select
                    value={value}
                    label="Business Registered?"
                    placeholder="None"
                    onChange={(event, data) => onChange(data.value)}
                    clearable
                    onBlur={onBlur}
                    options={yesNoOptions}
                    error={!!errors.seregistered}
                    fluid
                  />
                )}
              />
              <Controller
                control={control}
                name="setype.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Select
                    label="Type of Company"
                    value={value}
                    placeholder="None"
                    clearable
                    onChange={(event, data) => onChange(data.value)}
                    onBlur={onBlur}
                    fluid
                    options={[
                      {
                        key: nanoid(),
                        text: "Company",
                        value: "C",
                      },
                      {
                        key: nanoid(),
                        text: "Proprietor",
                        value: "P",
                      },
                    ]}
                    error={!!errors.setype}
                  />
                )}
              />
            </Form.Group>
            <Controller
              control={control}
              name="sename.value"
              render={({ value, onChange, onBlur }) => (
                <Form.Input
                  label="Name of Business"
                  value={value}
                  onChange={(event, data) => {
                    if (data.value.length > 100) {
                      return;
                    }
                    onChange(data.value);
                  }}
                  onBlur={onBlur}
                  error={!!errors.sename}
                />
              )}
            />
          </Segment>

          <Divider hidden />

          <Header>Part-time Occupation</Header>
          <Segment stacked padded color="blue" loading={loading}>
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="insptemployer.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    label="Employer"
                    fluid
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 40) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={errors.insptemployer}
                  />
                )}
              />

              <Controller
                control={control}
                name="insparttimejob.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    label="Job Title"
                    fluid
                    value={value}
                    onChange={(e, data) => {
                      if (data.value.length > 120) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.insparttimejob}
                  />
                )}
              />
            </Form.Group>

            <Divider hidden />

            <Segment basic clearing style={{ padding: "0" }}>
              <Button
                floated="right"
                primary
                size="large"
                type="button"
                onClick={handleOnNext}
                disabled={loading}
              >
                Save
              </Button>
            </Segment>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default SectionA;
