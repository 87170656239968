import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  resumeAnnuityApplication,
  initialize,
  saveAnnuity,
  getPolicyNumber,
  createPaymentEntry,
  sendClientPortalInformation,
  completeAnnuityApplication,
  validateAnnuitySectionA,
  validateAnnuitySectionB,
  validateAnnuitySectionC,
  validateAnnuitySectionD,
  validateAnnuityAgentCert,
  getAnnuitySectionB,
  getAnnuitySectionC,
  getAnnuitySectionD,
  getAnnuityAgentCert,
} from "../../thunks";
import { resetAnnuityApplication } from "../../actions";

const initialState = {
  appid: "",
  agent: "",
  client: "",
  appclient: "",
  quote: "",
  policy: "",
  final_policy: "",
  fakePolicy: "",
  pay_rwid: "",
  pay_id: null,
  pay_key: null,
  email_sent: false,
  has_waiver_premium: false,
  annuity_type: "",
  loading: "idle",
  error: null,
  currentRequestId: null,
  loading_message: "",
  completed: false,
  completed_sections: [],
  action: "",
  appref: "",
  token: "",
  is_application_valid: false,
};

// TODO: clean up the structure of handling annuity sections

const annuitySlice = createSlice({
  name: "annuity",
  initialState,
  reducers: {
    update: (state, action) => {
      return Object.assign(state, action.payload);
    },

    reset: (state, action) => {
      return Object.assign(state, initialState, action.payload);
    },
  },
  extraReducers: {
    [getAnnuitySectionB.fulfilled]: (state, { payload }) => {
      state.is_application_valid = payload.section_b.is_application_valid;

      if (
        payload.section_b.is_section_valid === true &&
        state.completed_sections.indexOf("annuity-section-b") === -1
      ) {
        state.completed_sections.push("annuity-section-b");
      }

      if (payload.section_b.is_section_valid === false) {
        state.completed_sections = state.completed_sections.filter(
          (value) => value !== "annuity-section-b"
        );
      }
    },
    [getAnnuitySectionC.fulfilled]: (state, { payload }) => {
      state.is_application_valid = payload.section_c.is_application_valid;

      if (
        payload.section_c.is_section_valid === true &&
        state.completed_sections.indexOf("annuity-section-c") === -1
      ) {
        state.completed_sections.push("annuity-section-c");
      }

      if (payload.section_c.is_section_valid === false) {
        state.completed_sections = state.completed_sections.filter(
          (value) => value !== "annuity-section-c"
        );
      }
    },
    [getAnnuitySectionD.fulfilled]: (state, { payload }) => {
      state.is_application_valid = payload.section_d.is_application_valid;

      if (
        payload.section_d.is_section_valid === true &&
        state.completed_sections.indexOf("annuity-section-d") === -1
      ) {
        state.completed_sections.push("annuity-section-d");
      }

      if (payload.section_d.is_section_valid === false) {
        state.completed_sections = state.completed_sections.filter(
          (value) => value !== "annuity-section-d"
        );
      }
    },
    [getAnnuityAgentCert.fulfilled]: (state, { payload }) => {
      state.is_application_valid = payload.agent_cert.is_application_valid;

      if (
        payload.agent_cert.is_section_valid === true &&
        state.completed_sections.indexOf("annuity-agent-certificate") === -1
      ) {
        state.completed_sections.push("annuity-agent-certificate");
      }

      if (payload.agent_cert.is_section_valid === false) {
        state.completed_sections = state.completed_sections.filter(
          (value) => value !== "annuity-agent-certificate"
        );
      }
    },
    [resetAnnuityApplication]: (state, action) => {
      return Object.assign(state, initialState);
    },
    [validateAnnuitySectionA.fulfilled]: (state, action) => {
      const { payload } = action;

      state.is_application_valid = payload.section_a.is_application_valid;

      if (
        payload.section_a.is_section_valid === true &&
        state.completed_sections.indexOf("annuity-section-a") === -1
      ) {
        state.completed_sections.push("annuity-section-a");
      }
    },
    [validateAnnuitySectionA.rejected]: (state, { payload }) => {
      if (payload?.section_a) {
        state.is_application_valid = payload.section_a.is_application_valid;

        if (payload.section_a.is_section_valid === false) {
          state.completed_sections = state.completed_sections.filter(
            (value) => value !== "annuity-section-a"
          );
        }
      }
    },
    [validateAnnuitySectionB.fulfilled]: (state, { payload }) => {
      state.is_application_valid = payload.section_b.is_application_valid;

      if (
        payload.section_b.is_section_valid === true &&
        state.completed_sections.indexOf("annuity-section-b") === -1
      ) {
        state.completed_sections.push("annuity-section-b");
      }
    },
    [validateAnnuitySectionB.rejected]: (state, { payload }) => {
      if (payload?.section_b) {
        state.is_application_valid = payload.section_b.is_application_valid;

        if (payload.section_b.is_section_valid === false) {
          state.completed_sections = state.completed_sections.filter(
            (value) => value !== "annuity-section-b"
          );
        }
      }
    },
    [validateAnnuitySectionC.fulfilled]: (state, { payload }) => {
      state.is_application_valid = payload.section_c.is_application_valid;

      if (
        payload.section_c.is_section_valid === true &&
        state.completed_sections.indexOf("annuity-section-c") === -1
      ) {
        state.completed_sections.push("annuity-section-c");
      }
    },
    [validateAnnuitySectionC.rejected]: (state, { payload }) => {
      if (payload?.section_c) {
        state.is_application_valid = payload.section_c.is_application_valid;

        if (payload.section_c.is_section_valid === false) {
          state.completed_sections = state.completed_sections.filter(
            (item) => item !== "annuity-section-c"
          );
        }
      }
    },
    [validateAnnuitySectionD.fulfilled]: (state, { payload }) => {
      state.is_application_valid = payload.section_d.is_application_valid;

      if (
        payload.section_d.is_section_valid === true &&
        state.completed_sections.indexOf("annuity-section-d") === -1
      ) {
        state.completed_sections.push("annuity-section-d");
      }
    },
    [validateAnnuitySectionD.rejected]: (state, { payload }) => {
      if (payload?.section_d) {
        state.is_application_valid = payload.section_d.is_application_valid;

        if (payload.section_d.is_section_valid === false) {
          state.completed_sections = state.completed_sections.filter(
            (item) => item !== "annuity-section-d"
          );
        }
      }
    },
    [validateAnnuityAgentCert.fulfilled]: (state, { payload }) => {
      state.is_application_valid = payload.agent_cert.is_application_valid;

      if (
        payload.agent_cert.is_section_valid === true &&
        state.completed_sections.indexOf("annuity-agent-certificate") === -1
      ) {
        state.completed_sections.push("annuity-agent-certificate");
      }
    },
    [validateAnnuityAgentCert.rejected]: (state, { payload }) => {
      if (payload?.agent_cert) {
        state.is_application_valid = payload.agent_cert.is_application_valid;

        if (payload.agent_cert.is_section_valid === false) {
          state.completed_sections = state.completed_sections.filter(
            (item) => item !== "annuity-agent-certificate"
          );
        }
      }
    },
    [initialize.pending]: (state, action) => {
      const { requestId } = action.meta;
      state.loading = "pending";
      state.error = null;
      state.currentRequestId = requestId;
      state.action = "initialize";
    },
    [initialize.fulfilled]: (state, action) => {
      const {
        payload,
        meta: { requestId },
      } = action;

      let { currentRequestId, loading } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        state.loading = "idle";
        state.action = "";
        state.error = null;
        state.currentRequestId = null;
        state.appid = payload.appid;
        state.has_waiver_premium = payload.has_waiver_premium;
        state.annuity_type = payload.annuity_type;
        state.is_initialized = true;
      }
    },
    [initialize.rejected]: (state) => {
      state.loading = "idle";
      state.action = "";
      state.currentRequestId = null;
    },
    [saveAnnuity.pending]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "idle") {
        state.loading = "pending";
        state.error = null;
        state.currentRequestId = requestId;
        state.action = "save";
      }
    },
    [saveAnnuity.fulfilled]: (state, action) => {
      const {
        meta: { requestId },
        payload,
      } = action;
      let { currentRequestId, loading } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        state.loading = "idle";
        state.appid = state.appid || payload.appid || null;
        state.error = null;
        state.currentRequestId = undefined;
        state.action = "";
      }
    },
    [saveAnnuity.rejected]: (state, action) => {
      const {
        payload,
        meta: { requestId },
      } = action;
      const { loading, currentRequestId } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        state.loading = "idle";
        state.currentRequestId = null;
        state.action = "";
        if (payload) {
          state.error = payload;
        }
      }
    },
    [getPolicyNumber.pending]: (state, action) => {
      const { requestId } = action.meta;

      if (state.loading === "idle") {
        state.error = null;
        state.currentRequestId = requestId;
        state.loading = "pending";
      }
    },
    [getPolicyNumber.fulfilled]: (state, action) => {
      const {
        meta: { requestId },
        payload,
      } = action;
      let { currentRequestId, loading } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        state.error = null;
        state.currentRequestId = "";
        state.loading = "idle";
        if (payload) {
          state.final_policy = payload;
        }
      }
    },
    [getPolicyNumber.rejected]: (state, action) => {
      const {
        payload,
        meta: { requestId },
      } = action;
      const { loading, currentRequestId } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        state.currentRequestId = "";
        state.loading = "idle";
        if (payload) {
          state.error = payload;
        }
      }
    },
    [createPaymentEntry.pending]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "idle") {
        state.error = null;
        state.currentRequestId = requestId;
        state.loading = "pending";
      }
    },
    [createPaymentEntry.fulfilled]: (state, action) => {
      const {
        meta: { requestId },
        payload,
      } = action;
      let { currentRequestId, loading } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        state.error = null;

        if (payload) {
          state.pay_rwid = payload.PAY_RWID;
          state.pay_id = payload.PAY_ID;
          state.pay_key = payload.PAY_KEY;
        }
        state.currentRequestId = "";
        state.loading = "idle";
      }
    },
    [createPaymentEntry.rejected]: (state, action) => {
      const {
        payload,
        meta: { requestId },
      } = action;
      const { loading, currentRequestId } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        state.currentRequestId = "";
        state.loading = "idle";
        if (payload) {
          state.error = payload;
        }
      }
    },
    [sendClientPortalInformation.pending]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "idle") {
        state.error = null;
        state.currentRequestId = requestId;
        state.loading = "pending";
      }
    },
    [sendClientPortalInformation.fulfilled]: (state, action) => {
      const {
        meta: { requestId },
      } = action;
      let { currentRequestId, loading } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        state.error = null;
        state.currentRequestId = "";
        state.email_sent = true;
        state.loading = "idle";
      }
    },
    [sendClientPortalInformation.rejected]: (state, action) => {
      const {
        payload,
        meta: { requestId },
      } = action;
      const { loading, currentRequestId } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        state.currentRequestId = "";
        state.loading = "idle";
        if (payload) {
          state.error = payload;
        }
      }
    },
    [completeAnnuityApplication.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.error = null;
        state.currentRequestId = action.meta.requestId;
        state.loading = "pending";
      }
    },
    [completeAnnuityApplication.fulfilled]: (state) => {
      return Object.assign(state, initialState, { completed: true });
    },
    [completeAnnuityApplication.rejected]: (state, action) => {
      if (
        state.loading === "pending" &&
        state.currentRequestId === action.meta.requestId
      ) {
        state.currentRequestId = "";
        state.loading = "idle";
        if (action.payload) {
          state.error = action.payload;
        }
      }
    },
    [resumeAnnuityApplication.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
        state.error = null;
        state.action = "resume";
      }
    },
    [resumeAnnuityApplication.fulfilled]: (state, action) => {
      const { loading, currentRequestId } = state;
      const {
        payload,
        meta: { requestId },
      } = action;
      if (loading === "pending" && currentRequestId === requestId) {
        state.loading = "idle";
        state.error = null;
        state.currentRequestId = null;
        state.action = "";

        if (payload) {
          state.appid = payload?.appid;
          state.appref = payload?.appref;
          state.client = payload?.client;
          state.appclient = payload?.appclient;
          state.agent = payload?.agent;
          state.policy = payload?.policy;
          state.quote = payload?.quote;
          state.has_waiver_premium = payload?.has_waiver_premium;
          state.annuity_type = payload?.annuity_type;
          state.completed = payload?.completed;
          state.email_sent = payload?.email_sent;

          if (payload?.completed === true) {
            state.final_policy = payload?.policy;
          }

          if (payload.section_a.is_section_valid) {
            state.completed_sections.push("annuity-section-a");
          }

          if (
            payload.section_b.is_section_valid &&
            payload.annuity_type === "platinum"
          ) {
            state.completed_sections.push("annuity-section-b");
          }

          if (payload.section_c.is_section_valid) {
            state.completed_sections.push("annuity-section-c");
          }

          if (
            payload.section_d.is_section_valid &&
            payload.has_waiver_premium
          ) {
            state.completed_sections.push("annuity-section-d");
          }

          if (payload.agent_cert.is_section_valid) {
            state.completed_sections.push("annuity-agent-certificate");
          }

          if (payload.section_a.is_application_valid) {
            state.is_application_valid = payload.section_a.is_application_valid;
          }
        }
      }
    },
    [resumeAnnuityApplication.rejected]: (state, action) => {
      const { loading, currentRequestId } = state;
      const {
        payload,
        meta: { requestId },
      } = action;
      if (loading === "pending" && currentRequestId === requestId) {
        // logic here
        state.loading = "idle";
        state.error = null;
        state.currentRequestId = null;
        state.action = "";
      }
    },
  },
});

export const { update, reset } = annuitySlice.actions;
export default annuitySlice.reducer;

export const selectWhiteScreen = createSelector(
  [
    (state) => state.annuity,
    (state) => state.annuity_section_a,
    (state) => state.annuity_section_b,
  ],
  (annuity, sectionA, sectionB) => ({
    policy: annuity.policy,
    xagt: annuity.agent,
    type: annuity.annuity_type,
    client: annuity.client,
    appclient: annuity.appclient,
    inssurname: sectionA.inssurname.value,
    insfname: sectionA.insfname.value,
    appsurname: sectionB.owncompany.value,
  })
);
export const selectSectionC = createSelector(
  (state) => state.annuity_section_c,
  (state) => state.global,
  (state) => state.annuity,
  (section, global, annuity) => {
    return {
      formValues: { ...section },
      loading: section.loading === "pending",
      is_initialized: section.is_initialized,
      has_policy: annuity.policy !== "",
      relationships: global.relationships,
      completed: annuity.completed,
    };
  }
);
