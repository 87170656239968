import { lifeApi } from "../../../services/lifeApi";

const extendedApi = lifeApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getBeneficiaries: build.query({
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const { annuity } = api.getState();
        const { agent, policy } = annuity;

        const response = await baseQuery({
          url: "/eapps/beneficiaries",
          params: {
            agent,
            policy,
          },
        });
        return response;
      },
      keepUnusedDataFor: 0,
      providesTags: (result) => {
        if (result) {
          return [
            ...result.map(({ ABCLTNO: id }) => ({ type: "Beneficiaries", id })),
            { type: "Beneficiaries", id: "LIST" },
          ];
        }

        return [{ type: "Beneficiaries", id: "LIST" }];
      },
    }),
    makePrimary: build.mutation({
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const { annuity } = api.getState();
        const { agent, policy } = annuity;

        const response = await baseQuery({
          url: "/eapps/beneficiaries/primary",
          method: "POST",
          body: {
            agent,
            policy,
            client: arg,
          },
        });

        return response;
      },
      invalidatesTags: [{ type: "Beneficiaries", id: "LIST" }],
    }),
    deleteBeneficiary: build.mutation({
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const { annuity } = api.getState();
        const { agent, policy } = annuity;

        const response = await baseQuery({
          url: "/eapps/beneficiaries/delete",
          method: "DELETE",
          body: {
            agent,
            policy,
            client: arg,
          },
        });

        return response;
      },
      invalidatesTags: [{ type: "Beneficiaries", id: "LIST" }],
    }),
  }),
});

export const {
  useGetBeneficiariesQuery,
  useMakePrimaryMutation,
  useDeleteBeneficiaryMutation,
} = extendedApi;
