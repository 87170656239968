import React, { useEffect } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import {
  Segment,
  Table,
  Button,
  Container,
  Header,
  List,
  Icon,
  Label,
  Grid,
  GridColumn,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
// import { getApplications, showSelectedApp } from "../../actions";

dayjs.extend(advancedFormat);

const Applications = () => {
  // const dispatch = useDispatch();

  // const { apps, loading, error } = useSelector((state) => state.applications);

  // useEffect(() => {
  //   dispatch(getApplications());
  // }, []);

  // const renderType = (type) => {
  //   switch (type) {
  //     case "MLCL":
  //       return "Life Application";
  //     default:
  //       return;
  //   }
  // };

  // const renderStatus = (status) => {
  //   switch (status) {
  //     case "N":
  //       return "New";
  //   }
  // };

  return (
    <Container className='page'>
      {/* <Grid padded>
        <GridColumn computer={5} tablet={5} only='tablet computer'>
          <Header size='huge' color='dark-blue'>
            Applications
          </Header>
        </GridColumn>
        <GridColumn mobile={16} only='mobile'>
          <Header size='huge' color='dark-blue'>
            Applications
          </Header>
        </GridColumn>
        <GridColumn tablet={11} computer={11} mobile={16}>
          <Segment basic loading={loading} style={{ minHeight: "200px" }}>
            {!loading &&
              apps.length > 0 &&
              apps.map((app, i) => (
                <div key={i} style={{ marginBottom: "2em" }}>
                  <p
                    style={{
                      textAlign: "right",
                      marginBottom: "0",
                      fontSize: "0.9rem",
                      color: "#a8a8a8",
                    }}
                  >
                    {dayjs(app["FIELDS"]["UPDATED_AT"]).format("hh:mm, MMM Do")}
                  </p>
                  <Segment style={{ marginTop: "2px" }}>
                    <Table basic='very' columns={4} unstackable>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell colSpan={2}>
                            <List size='medium'>
                              <List.Item>
                                <List.Content verticalAlign='middle'>
                                  <List.Header as={Header} size='medium'>
                                    {`${app["FIELDS"]["FNAME"]} ${app["FIELDS"]["SNAME"]}`}
                                  </List.Header>
                                  <List.Description>
                                    {app["FIELDS"]["EMAIL"]}
                                  </List.Description>
                                </List.Content>
                              </List.Item>
                            </List>
                          </Table.Cell>
                          <Table.Cell textAlign='right'>
                            <Label circular color='teal'>
                              {renderStatus(app["STATUS"])}
                            </Label>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell textAlign='center'>
                            {renderType(app["TYPE"])}
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            Created{" "}
                            {dayjs(app["CREATED_AT"]).format("MMM Do, YYYY")}
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <Button
                              onClick={() => dispatch(showSelectedApp(app))}
                              style={{ background: "transparent" }}
                            >
                              Open
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Segment>
                </div>
              ))}
            {!error && !loading && apps.length === 0 && (
              <Header
                content='There are no applications at this time'
                textAlign='center'
              />
            )}

            {error && !loading && (
              <Header
                content='An error occured please try again later'
                textAlign='center'
              />
            )}
          </Segment>
        </GridColumn>
      </Grid> */}
    </Container>
  );
};

export default Applications;
