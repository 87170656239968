import * as yup from "yup";

export const sectionAValidator = yup.object().shape({
  ans1: yup.string().required("Please select an option"),
  ans2: yup.string().when("ans1", {
    is: "T",
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string().optional(),
  }),
  ans3: yup.string().required("Please select an option"),
  ans4: yup.string().when("ans3", {
    is: "T",
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string().optional(),
  }),
  ans5: yup.string().required("Please select an option"),
  ans6: yup.string().when("ans5", {
    is: "T",
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string().optional(),
  }),
  ans7: yup.string().required("Please select an option"),
  ans8: yup.string().when("ans7", {
    is: "T",
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string().optional(),
  }),
  ans9: yup.string().required("Please select an option"),
  ans10: yup.string().when("ans9", {
    is: "T",
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string().optional(),
  }),
  ans11: yup.string().required("Please select an option"),
  ans12: yup.string().when("ans11", {
    is: "T",
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string().optional(),
  }),
  ans13: yup.string().required("Please select an option"),
  ans14: yup.string().when("ans13", {
    is: "T",
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string().optional(),
  }),
  ans15: yup.string().required("Please select an option"),
  ans16: yup.string().when("ans15", {
    is: "T",
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string().optional(),
  }),
  ans17: yup.string().required("Please select an option"),
  ans18: yup.string().when("ans17", {
    is: "T",
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string().optional(),
  }),
});

export const sectionBValidator = yup.object().shape({
  terms: yup
    .string()
    .required("You must agree to the terms and conditions")
    .test(
      "accepted",
      "You must agree to the terms and conditions",
      (v) => v === "T"
    ),
});
