import React from "react";
import GoogleLogin, { GoogleLogout } from "react-google-login";
import { NavLink } from "react-router-dom";
import { Icon, Menu, Sidebar as SemanticSidebar } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { close, selectSidebar } from "./sidebarSlice";
import { login, logout } from "../auth/authSlice";
import { googleClientId } from "../../utils/options";

const Sidebar = () => {
  const dispatch = useDispatch();

  const { open, isAuthenticated } = useSelector(selectSidebar);
  const closeSidebar = () => dispatch(close());
  const signOut = () => dispatch(logout());
  const sigIn = (res) => {
    dispatch(login(res));
    closeSidebar();
  };

  return (
    <SemanticSidebar
      as={Menu}
      animation="overlay"
      vertical
      borderless
      visible={open}
      // className='sidebar__maritime'
      onHide={closeSidebar}
      style={{ paddingTop: "4rem", paddingBotton: "4rem" }}
    >
      <Menu.Item as={NavLink} to="/applications" onClick={closeSidebar}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon size="large" name="file text" />
          <span style={{ marginLef: "0.3em", fontSize: "1.1rem" }}>
            Applications
          </span>
        </div>
      </Menu.Item>
      <Menu.Item as={NavLink} to="/accidental" onClick={closeSidebar}>
        <div>
          <Icon name="medkit" size="large" />
          <span style={{ marginLeft: "0.3em", fontSize: "1.1rem" }}>
            Addicental Death
          </span>
        </div>
      </Menu.Item>
      <Menu.Item as={NavLink} to="/mpp" onClick={closeSidebar}>
        <div>
          <Icon name="life ring" size="large" />
          <span style={{ marginLeft: "0.3em", fontSize: "1.1rem" }}>
            Simple Life
          </span>
        </div>
      </Menu.Item>

      <Menu.Item as={NavLink} to="/mlcl" onClick={closeSidebar}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon size="large" name="medrt" />
          <span style={{ marginLeft: "0.3em", fontSize: "1.1rem" }}>
            Life Application
          </span>
        </div>
      </Menu.Item>
      {!isAuthenticated && (
        <GoogleLogin
          clientId={googleClientId}
          onSuccess={sigIn}
          onFailure={(err) => {
            console.error(err);
            // alert("login failed");
          }}
          isSignedIn={true}
          cookiePolicy={"single_host_origin"}
          render={({ onClick, disabled }) => (
            <Menu.Item onClick={onClick}>
              <div>
                <Icon name="google" size="large" /> Sign in with Google
              </div>
            </Menu.Item>
          )}
        />
      )}
      {isAuthenticated && (
        <GoogleLogout
          clientId={googleClientId}
          buttonText="Sign out"
          onLogoutSuccess={signOut}
          render={({ onClick }) => (
            <Menu.Item onClick={onClick}>
              <div>
                <Icon name="google" size="large" /> Sign out
              </div>
            </Menu.Item>
          )}
        />
      )}
    </SemanticSidebar>
  );
};

export default Sidebar;
