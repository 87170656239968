import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: process.env.REACT_APP_REQUEST_TIMEOUT,
  headers: {
    Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiIxMjguMS4xLjM0IiwiYXVkIjoiZXhhbXBsZS5jb20iLCJqdGkiOiI2ZXU1T1FSY05Qak05b2JadkJsOHhPIiwiaWF0IjoxNjMwNjEyODQ3fQ.cWd1IU4IGyT4xXLu9EriMWZaUkxR5shlAFNdBodZfRWLMBKRA9p2RAKO2v5Tt6QtTBDaXwHOJuKcMFsjaX6ChMK00rC2RdNMPl-3eTqN_-tIhcv-L2DrZrIRiV1AWLYQeuvAM7LDcxmRlsLMqDsZFExhwNz1-V8uLUkhxcsBs8BN0U_twY27DauSQQV8oIVAsheIyc1EofjesneZbCG5OgvXLhcxQPdv0LRXxWYGlmOenBWKRI2AiMxLaomLHE_nNQkUHJPyP_21P4-pPGXQfMBecFX946gAiO78un-htYSGIjSdg3H4F347zZAk6nUrPf6v2UW3b3gYJtBNdEUqIH3xK57MaiRcRLdipiRfQTh-hURIkBlLdz_QGRP3hvdlkrNxUBMJltW2Bzsv_2nI5ecS-1nOWD5x1w4vISVWaOZN_BbPNCa1tJ39LzGIEII3cMKCYexmWnYWA7qyQXP4cJ_IMHgLl6PKKMFo7Fz5Rcr-V7xJj1XaamUV6vOPvcq2y4DxQkT0emFZUkCMTVOWTk1l_RXz7gnZdF5UQ9tKzk1DVIbyQ5xdLWsrgptl38eLWN68wuajT51SRndoPoUuvCTyaYV5J5Nq8M0LoehsXpwVmLxqC0-Np2kGXyIJFFN4BNXkOE7g0Ngh_8wb72j0_R-rRwP0P-fPIf9nHSSPAx8`,
  },
  validateStatus: function (status) {
    return status === 200;
  },
});
// export default axios.create({
//   baseURL: "http://localhost:3300",
//   // timeout: 1000,
// });
