import Swal from "sweetalert2";
import { v4 as uuid_v4 } from "uuid";

export const googleClientId =
  "887107359145-81l0kuui3oo511apg2g3b7c16oi3362l.apps.googleusercontent.com";

export const Toast = Swal.mixin({
  toast: true,
  position: "bottom-left",
  showConfirmButton: false,
  showCloseButton: true,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const currencyFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const employmentStatusOptions = [
  { key: uuid_v4(), text: "Employed externally", value: "E" },
  { key: uuid_v4(), text: "Self employed", value: "S" },
  { key: uuid_v4(), text: "Part time", value: "P" },
];

export const idOptions = [
  { key: "n", text: "National ID", value: "national_id" },
  { key: "p", text: "Passport", value: "passport" },
];

export const phoneOptions = [
  { key: "m", text: "Mobile", value: "mobile" },
  { key: "h", text: "House", value: "house" },
  { key: "w", text: "Work", value: "work" },
];

export const genderOptions = [
  { key: uuid_v4(), text: "Male", value: "M" },
  { key: uuid_v4(), text: "Female", value: "F" },
];

export const maritialOptions = [
  { key: uuid_v4(), text: "Single", value: "S" },
  { key: uuid_v4(), text: "Married", value: "M" },
  { key: uuid_v4(), text: "Common Law", value: "C" },
  { key: uuid_v4(), text: "Divorced", value: "D" },
  { key: uuid_v4(), text: "Widowed", value: "W" },
];

export const premiumFrequencyOptions = [
  {
    key: uuid_v4(),
    text: "Annually",
    value: "A",
  },
  {
    key: uuid_v4(),
    text: "Semi-Annually",
    value: "H",
  },
  {
    key: uuid_v4(),
    text: "Quarterly",
    value: "Q",
  },
  {
    key: uuid_v4(),
    text: "Monthly",
    value: "M",
  },
];

export const incomeOptions = [
  { key: "i1", label: "< $30,000", value: "LESS THAN 30000" },
  { key: "i2", label: "$30,000 - $50,000", value: "BETWEEN 30000 AND 50000" },
  { key: "i3", label: "$50,000 - $100,000", value: "BETWEEN 50000 AND 100000" },
  {
    key: "i4",
    label: "$100,000 - $150,000",
    value: "BETWEEN 100000 AND 150000",
  },
  {
    key: "i5",
    label: "$150,000 - $300,000",
    value: "BETWEEN 150000 AND 300000",
  },
  { key: "i6", label: "> $300,000", value: "GREATER THAN 300000" },
];

export const weightChangeOptions = [
  { key: "n", label: "None", value: "none" },
  { key: "g", label: "Gain", value: "gain" },
  { key: "l", label: "Loss", value: "loss" },
];

export const weightTypeOptions = [
  { key: uuid_v4(), text: "lbs", value: "lbs" },
  { key: uuid_v4(), text: "kg", value: "kg" },
];

export const checkupReasons = [
  { key: "e", label: "Annual Check-Up", value: "A" },
  { key: "m", label: "Employment", value: "E" },
  { key: "i", label: "Immigration", value: "I" },
  { key: "u", label: "University", value: "U" },
  { key: "o", label: "Other", value: "O" },
];

export const yesNoOptions = [
  { key: uuid_v4(), text: "Yes", value: "Y" },
  { key: uuid_v4(), text: "No", value: "N" },
];

/**
 * pregnancy complications options
 */
export const pregnancyCompOptions = [
  { key: uuid_v4(), label: "No Complications", value: "Y", name: "PREGCN" },
  {
    key: uuid_v4(),
    label: "Gestational diabetes",
    value: "Y",
    name: "PREGCG",
  },
  { key: uuid_v4(), label: "Caesarian section", value: "Y", name: "PREGCC" },
  { key: uuid_v4(), label: "Eclampsia", value: "Y", name: "PREGCE" },
  { key: uuid_v4(), label: "Thrombosis", value: "Y", name: "PREGCT" },
  { key: uuid_v4(), label: "Diabetes", value: "Y", name: "PREGCD" },
  { key: uuid_v4(), label: "Miscarriage", value: "Y", name: "PREGCM" },
  { key: uuid_v4(), label: "Hypertension", value: "Y", name: "PREGCH" },
];

export const termConditions = [
  {
    key: uuid_v4(),
    label:
      "I DELCARE that to the best of my knowledge and belife all the statements above are accurate, true and complete in all respects and that no material fact has been withheld or surpressed.",
    value: "Y",
    name: "TERMAT",
  },
  {
    key: uuid_v4(),
    label:
      "I UNDERSTAND taht failure to disclose any material fact, that full nature of any material fact, or change to any material fact may lead to the Company avoiding the Policy applied for and/ or modification or rejection of any claim thereunder.",
    value: "Y",
    name: "TERMDM",
  },
  {
    key: uuid_v4(),
    label:
      "IT IS UNDERSTOOD AND AGREED that this application, shall become part of every policy issued hereon: that the insurance applied for shall take effect only if and when (a) this application is approved by the Company at its Head Office in Bataria, Trinidad and (b) the first premium is paid while the Proposal Assured is in good health provided there is then no change in the insurability of the Proposed Assured since the date hereof.",
    value: "Y",
    name: "TERMGH",
  },
  {
    key: uuid_v4(),
    label:
      "I HEREBY CONSENT to the Policy being issued and declare that ownership of all benefits, values rights and privileges conferred by the Policy shall belong exclusively to the Applicant.",
    value: "Y",
    name: "TERMCD",
  },
  {
    key: uuid_v4(),
    label:
      "IT HEREBY AUTHORIZE MARITIME LIFE (CARIBBEAN) LIMITED to furnish all records including complete diagnosis and medical information to an appropriate medical review board, utilisation review organisation and/or any other insurance carrier, administration or medical facility for purposes of administration of the Policy applied for, If such information relates to fraud or other misrepresentation, MARITIME LIFE (CARIBBEAN) LIMITED may disclose it to legal authorities or use it in legal proceeding. A photocopy of this authorisation shall be as valid as the original.",
    value: "Y",
    name: "TERMFR",
  },
  {
    key: uuid_v4(),
    label:
      "I HEREBY AUTORISE any physician or practitioner who has observed me for diagnosis or treatment, or for any disease or ailment, or any hospital or clinic where I have been a patient for diagnosis, treatment, disease or ailment, or any insurance company to which I have applied, to give full particulars, including any pripr medical histor, to MARITIME LIFE (CARIBBEAN) LIMITED to which I am making applications for insurance. A photocopy of this authorisation shall be as valid as the original.",
    value: "Y",
    name: "TERMFP",
  },
  {
    key: uuid_v4(),
    label:
      "I UNDERSTAND that failure to disclose any Medical treatment, disease past or present, or other material facts could result in avoidance of the policy or denial of a client",
    value: "Y",
    name: "TERMPA",
  },
];
