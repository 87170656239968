import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import maritime from "../../utils/maritime";

const initialState = {
  fname: "",
  sname: "",
  ident: "",
  identyp: "national_id",
  email: "",
  tel: "",
  teltyp: "mobile",
  addrs: "",
  maddrs: "",
  saddrs: "",
  job: "",
  anninc: "",
  hsport: "",
  hsportrm: "",
  feet: "",
  inches: "",
  centi: "",
  weight: "",
  weightyp: "lbs",
  covid: "",
  covidrm: "",
  cva: "",
  cvarm: "",
  angina: "",
  anginarm: "",
  diabetic: "",
  diabeticrm: "",
  renial: "",
  renialrm: "",
  hiv: "",
  hivrm: "",
  ulcer: "",
  ulcerrm: "",
  smoker: "",
  somkerrm: "",
  narcotics: "",
  narcoticsrm: "",
  health: "",
  fact: "",
  legal: "",
  medical: "",
  touched: false,
  step: 0,
  payrwid: "",
  payid: "",
  paykey: "",
  loading: "idle",
  modal: false,
  error: null,
  quote: null,
  currentRequestId: undefined,
};

export const save = createAsyncThunk(
  "mpp/save",
  async (_, { getState, requestId }) => {
    // get the accidental informtion from the store
    const { mpp } = getState();
    const { loading, currentRequestId } = mpp;

    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }

    // create the params for the request
    // const body = {
    //   ...accidental,
    // };
    const body = {
      fname: "David",
      sname: "Baldeo",
      dob: "1990-01-01",
      age: "31",
      sex: "M",
      smoker: "0",
      freq: "A",
      mplan: 9,
      plan: {
        sa: 500000,
        type: 1106,
      },
    };

    // send request to maritime api
    const { data } = await maritime.post("/nova/mpp", body);
    console.log(data);
    return data;
  }
);

export const checkPayment = createAsyncThunk(
  "mpp/payment/check",
  async (_, { getState, requestId }) => {}
);

const mppSlice = createSlice({
  name: "mpp",
  initialState,
  reducers: {
    update: (state, { payload }) => {
      return { ...state, ...payload };
    },
    next: (state, _) => {
      state.step = Math.min(state.step + 1, 2);
    },
    previous: (state, _) => {
      state.step = Math.max(state.step - 1, 0);
    },
    closeModal: (state, _) => {
      state.modal = false;
    },
  },
  extraReducers: {
    [save.pending]: (state, action) => {
      const requestId = action.meta.requestId;
      if (state.loading === "idle") {
        state.loading = "pending";
        state.modal = false;
        state.error = null;
        state.currentRequestId = requestId;
      }
    },
    [save.fulfilled]: (state, action) => {
      const requestId = action.meta.requestId;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.quote = action.payload;
        state.modal = true;
        state.currentRequestId = undefined;
      }
    },
    [save.rejected]: (state, action) => {
      const requestId = action.meta.requestId;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});

const selectState = (state) => state;
export const mppSelector = createSelector(selectState, (state) => state.mpp);
export const { update, next, previous, closeModal } = mppSlice.actions;
export default mppSlice.reducer;
