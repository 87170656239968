import React, { useCallback } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { Button, Segment } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  completeAnnuityApplication,
  createPaymentEntry,
  getPolicyNumber,
  sendClientPortalInformation,
} from "../../../thunks";

const selector = createSelector(
  (state) => state.annuity,
  (annuity) => {
    return {
      disabled: annuity.is_application_valid === false,
      loading: annuity.loading === "pending",
      quoteId: annuity.quote,
    };
  }
);

const CompleteApplication = () => {
  const dispatch = useDispatch();

  const { disabled, loading, quoteId } = useSelector(selector);

  const handleComplete = useCallback(async () => {
    let url = process.env.REACT_APP_MARITIME_ONLINE;
    try {
      await dispatch(getPolicyNumber()).unwrap();
      await dispatch(createPaymentEntry()).unwrap();
      await dispatch(sendClientPortalInformation()).unwrap();
      await dispatch(completeAnnuityApplication()).unwrap();
      window.location.replace(`${url}?qid=${quoteId}`);
    } catch (err) {}
  }, [dispatch, quoteId]);

  return (
    <Segment style={{ background: "transparent !important" }}>
      <Button
        fluid
        size="large"
        disabled={disabled}
        loading={loading}
        color={disabled === true ? "grey" : "green"}
        onClick={handleComplete}
      >
        Submit Application
      </Button>
    </Segment>
  );
};

export default CompleteApplication;
