import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fields: {
    SNAME: "",
    FNAME: "",
    MIDNAME: "",
    MADNAME: "",
    DOB: "",
    AGE: "",
    CNTRY: "",
    GEN: "",
    MARSTS: "",
    IDENTY: "",
    IDENTYP: "national_id",
    ADDRS: "",
    ADDRSYR: "",
    TEL: "",
    MADDRS: "",
    EMAIL: "",
    JOB: "",
    EMPLYR: "",
    EMPLYRADRS: "",
    EMPLYRTEL: "",
    PTJOB: "",
    ANNINC: "",
    NTWRT: "",
    LIFEINS: "",
    EMERGNAM: "",
    EMERGADDRS: "",
    EMERGHTEL: "",
    EMERGWTEL: "",
    EMERGREL: "",
    SPOUNAM: "",
    SPOUINSAMT: "",
    SPOUANNINC: "",
    SPOUINCSRC: "",
    BENPAY: "",
    BENREL: "",
    BENAGE: "",
    BENDOB: "",
    BENTRST: "",
    COVERAGE: [],
    BNDPREM: "",
    MRGPREM: "",
    PRPTYPREM: "",
    TRITERMS: "",
    TRIDT: "",
    TRIWIT: "",
    TRIAPP: "",
    CHGRES: "",
    CHGRESRM: "",
    PILOT: "",
    PILOTRM: "",
    HSPORT: "",
    HSPORTRM: "",
    CRITC: "",
    CRITCRM: "",
    PENDCRIT: "",
    PENDCRITRM: "",
    BENFPAY: "",
    BENFPAYRM: "",
    LEGAL: "",
    LEGALRM: "",
    FIT: "",
    FITRM: "",
    FEET: "",
    INCHES: "",
    CENTI: "",
    WEIGHT: "",
    WEIGHTYP: "",
    WEIGHCHG: "",
    WEIGHTCHGAMT: "",
    WEIGHTCHGRM: "",
    RPHY: "",
    RPHYC: "",
    RPHYCR: "",
    RPHYRM: "",
    RPHYRES: "",
    RPHYMED: "",
    LPHY: "",
    LPHYC: "",
    LPHYCR: "",
    LPHYRM: "",
    LPHYRES: "",
    LPHYMED: "",
    BPRB: "",
    BPRBRM: "",
    FODIS: "",
    FODISRM: "",
    APAP: "",
    APAPRM: "",
    GYNO: "",
    GYNORM: "",
    PREG: "",
    PREGDDT: "",
    PREGLDV: "",
    PREGCOMP: {
      PREGCN: "",
      PREGCG: "",
      PREGCC: "",
      PREGCE: "",
      PREGCT: "",
      PREGCD: "",
      PREGCM: "",
      PREGCH: "",
    },
    TERMAT: "",
    TERMDM: "",
    TERMGH: "",
    TERMFR: "",
    TERMFP: "",
    TERMPA: "",
    TERMCS: "",
    TERMDT: "",
    TERMWIT: "",
    TERMAPP: "",
    AGNTREL: "",
    AGTNO: "",
    APPROACH: "",
    BANK: "",
    BARDT: "",
    BARMNG: "",
    BARTERMS: "",
    CERTDT: "",
    CERTSIG: "",
    CERTTERMS: "",
    DDEFF: "",
    DRUGS: "",
    KNOASUR: "",
    OPCAPP: "",
    OPCPRPA: "",
    REMARKS: "",
    RISK: "",
    SSEFF: "",
    SSEFFRM: "",
  },
  loading: false,
  ASSURED: false,
  touched: false,
  sections: [
    {
      path: "",
      title: "Section A",
      section: "A",
      completed: false,
    },
    {
      path: "/section-b",
      title: "Section B",
      section: "B",
      completed: false,
    },
    {
      path: "/section-c",
      title: "Section C",
      section: "C",
      completed: false,
    },
    {
      path: "/section-d",
      title: "Section D",
      section: "D",
      completed: false,
    },
    {
      path: "/section-e",
      title: "Section E",
      section: "E",
      completed: false,
    },
    {
      path: "/section-f",
      title: "Section F",
      section: "F",
      completed: false,
    },
    {
      path: "/section-g",
      title: "Section G",
      section: "G",
      completed: false,
    },
    {
      path: "/section-h",
      title: "Section H",
      section: "H",
      completed: false,
    },
    {
      path: "/section-i",
      title: "Section I",
      section: "I",
      completed: false,
    },
    {
      path: "/section-j",
      title: "Section J",
      section: "J",
      completed: false,
    },
    {
      path: "/section-l",
      title: "Section L",
      section: "L",
      completed: false,
    },
    {
      path: "/section-m",
      title: "Section M",
      section: "M",
      completed: false,
    },
    {
      path: "/agent",
      title: "Agent's Certificate",
      section: "AC",
    },
  ],
};

// create async thunk to save application
// handle async thunk actions in extraReducer

const mlclSlice = createSlice({
  name: "mlcl",
  initialState,
  reducers: {
    update: (state, _) => {},
  },
});

export const { update } = mlclSlice.actions;
export default mlclSlice.reducer;
