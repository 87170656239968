import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  apps: [],
  selected: null,
  loading: "idle",
  error: false,
};

// create async thunk for getting applications
// create async thunk for updating applications
// handle async thunk actions in extraReducers of the slice

const applicationSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload;
    },
  },
});

export const { setSelected } = applicationSlice.actions;
export default applicationSlice.reducer;
