import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Button, Icon, Segment } from "semantic-ui-react";
import { selectWhiteScreen } from "../annuitySlice";

const getType = (type) => {
  if (type === "gold") return "P";
  if (type === "platinum") return "C";
  return "";
};

const getRType = (value) => {
  switch (value) {
    case "owner":
      return 1;
    case "insured":
      return 2;
    case "trustee":
      return 4;
    default:
      return null;
  }
};

const styles = {
  padding: 0,
  marginBottom: "0.6rem",
};

const ClientSystem = ({ clientType, label, onRefresh }) => {
  const {
    policy,
    xagt,
    type,
    client,
    appclient,
    inssurname,
    insfname,
    appsurname,
  } = useSelector(selectWhiteScreen);

  const getXNum = (value) => {
    switch (value) {
      case "owner":
        return appclient;
      default:
        return client;
    }
  };

  const handeClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const rtype = getRType(clientType);
    const t = getType(type);
    const xnum = getXNum(clientType);
    const baseUrl = process.env.REACT_APP_CLIENT_SYSTEM_BASE_URL;

    if (clientType === "insured") {
      const win = window.open(
        `${baseUrl}/EA_CLT001.pgm?rtype=${rtype}&t=${t}&pol=${policy}&xsur=${inssurname}&xoth=${insfname}&xagt=${xagt}${
          xnum <= 700000 ? "&xnum=" + xnum : ""
        }`
      );
    }

    if (clientType === "owner") {
      const win = window.open(
        `${baseUrl}/EA_CLT001.pgm?rtype=${rtype}&t=${t}&pol=${policy}&xsur=${appsurname}&xoth=&xagt=${xagt}${
          xnum <= 700000 ? "&xnum=" + xnum : ""
        }`
      );
    }
  };

  const handleRefresh = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onRefresh();
  };

  return (
    <Segment basic clearing style={styles}>
      <Button icon floated="right" onClick={handeClick}>
        {label} <Icon name="user add" />
      </Button>
      <Button icon type="button" floated="right" onClick={handleRefresh}>
        <Icon name="refresh" />
      </Button>
    </Segment>
  );
};

export default ClientSystem;

ClientSystem.propTypes = {
  clientType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
