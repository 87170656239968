import React from "react";
import { Route, Switch } from "react-router-dom";

import Kyc from "../../pages/Kyc";
import MLCL from "../../pages/MLCL";
import Payments from "../../pages/Payments";
import Login from "../../pages/Login";
import Welcome from "../../pages/Welcome";
import IssuePolicy from "../../pages/IssuePolicy";
import NotFound from "../../pages/NotFound";
import ThankYou from "../../pages/ThankYou";
import Mpp from "../../features/mpp/Mpp";
import Accidental from "../../features/accidental/Accidental";
import Annuity from "../../features/annuity/Annuity";
import Applications from "../../pages/Applications";
import PrivateRoute from "../routes/PrivateRoute";
import ConfigRoute from "../routes/ConfigRoute";
import SelectedMlclApp from "../../pages/SelectedMlclApp";
import CheckoutPage from "../../pages/CheckoutPage";
import { Container, Button } from "semantic-ui-react";
import history from "../../utils/history";
import Life from "../../features/life/Life";
import ScrollToTop from "../../features/scroll-to-top/ScrollToTop";
import Application from "../../features/application/Application";

const Routes = () => {
  return (
    <Switch>
      {/* <Route exact path="/" component={Welcome} /> */}
      {/* <Route exact path="/checkout" component={CheckoutPage} /> */}
      {/* <Route exact path="/mpp" component={Mpp} /> */}
      {/* <Route exact path="/accidental" component={Accidental} /> */}
      {/* <Route exact path="/kyc" component={Kyc} /> */}
      {/* <Route exact path="/payments" component={Payments} /> */}
      {/* <Route exact path="/issue-policy" component={IssuePolicy} /> */}
      {/* <Route path="/mlcl" component={MLCL} /> */}
      {/* <Route exact path="/login" component={Login} /> */}
      {/* <PrivateRoute path="/applications/mlcl/:id" component={SelectedMlclApp} /> */}
      {/* <Route exact path="/thank-you" component={ThankYou} /> */}
      {/* <Route path="/application/complete" component={ThankYou} /> */}
      <ConfigRoute path="/applications/annuity" component={Annuity} />
      {/* <Route path="/applications/life" component={Life} /> */}
      {/* <Route exact path="/receipt">
        <Container
          className="page"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#00b5ad",
          }}
          fluid
        >
          <p
            style={{
              fontSize: "4.5rem",
              color: "#ffffff",
            }}
          >
            Receipt Information
          </p>
          <Button
            className="rounded"
            primary
            size="huge"
            onClick={() => {
              history.push("/contract");
            }}
          >
            Continue
          </Button>
        </Container>
      </Route>
      <Route exact path="/contract">
        <Container
          className="page"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#00b5ad",
          }}
          fluid
        >
          <p
            style={{
              fontSize: "4.5rem",
              color: "#ffffff",
            }}
          >
            Contract Information
          </p>
        </Container>
      </Route> */}
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
