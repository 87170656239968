import { createSlice } from "@reduxjs/toolkit";
import { resetAnnuityApplication } from "../../../actions";
import { merge } from "lodash";
import {
  getAnnuitySectionD,
  validateAnnuitySectionD,
  resumeAnnuityApplication,
  completeAnnuityApplication,
  getPolicyNumber,
  createPaymentEntry,
  sendClientPortalInformation,
} from "../../../thunks";

const initialState = {
  da: {
    value: "",
    valid: true,
  },
  db: {
    value: "",
    valid: true,
  },
  dc: {
    value: "",
    valid: true,
  },
  dd: {
    value: "",
    valid: true,
  },
  de: {
    value: "",
    valid: true,
  },
  df: {
    value: "",
    valid: true,
  },
  dg: {
    value: "",
    valid: true,
  },
  dh: {
    value: "",
    valid: true,
  },
  di: {
    value: "",
    valid: true,
  },
  dj: {
    value: "",
    valid: true,
  },
  dremark: {
    value: "",
    valid: true,
  },
  da_remark: {
    value: "",
    valid: true,
  },
  db_remark: {
    value: "",
    valid: true,
  },
  dc_remark: {
    value: "",
    valid: true,
  },
  dd_remark: {
    value: "",
    valid: true,
  },
  de_remark: {
    value: "",
    valid: true,
  },
  df_remark: {
    value: "",
    valid: true,
  },
  dg_remark: {
    value: "",
    valid: true,
  },
  dh_remark: {
    value: "",
    valid: true,
  },
  di_remark: {
    value: "",
    valid: true,
  },
  dj_remark: {
    value: "",
    valid: true,
  },
  agt: "",
  is_initialized: false,
  loading: "idle",
  error: null,
  currentRequestId: "",
};

const sliceD = createSlice({
  name: "annuity_section_d",
  initialState,
  reducers: {
    update: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: {
    [getPolicyNumber.pending]: (state) => {
      state.loading = "pending";
    },
    [getPolicyNumber.rejected]: (state) => {
      state.loading = "idle";
    },
    [getPolicyNumber.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [createPaymentEntry.pending]: (state) => {
      state.loading = "pending";
    },
    [createPaymentEntry.rejected]: (state) => {
      state.loading = "idle";
    },
    [createPaymentEntry.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [sendClientPortalInformation.pending]: (state) => {
      state.loading = "pending";
    },
    [sendClientPortalInformation.rejected]: (state) => {
      state.loading = "idle";
    },
    [sendClientPortalInformation.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [resetAnnuityApplication]: (state, action) => {
      return merge(state, initialState);
    },
    [completeAnnuityApplication.pending]: (state) => {
      state.pending = "pending";
    },
    [completeAnnuityApplication.rejected]: (state) => {
      state.pending = "idle";
    },
    [completeAnnuityApplication.fulfilled]: (state) => {
      return Object.assign({}, state, initialState);
    },
    [resumeAnnuityApplication.fulfilled]: (state, action) => {
      return merge(state, initialState);
    },
    [getAnnuitySectionD.pending]: (state, action) => {
      const { requestId } = action.meta;
      const { loading } = state;
      if (loading === "idle") {
        state.loading = "pending";
        state.error = null;
        state.currentRequestId = requestId;
      }
    },
    [getAnnuitySectionD.rejected]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        let _state = { loading: "idle", currentRequestId: "" };

        if (action?.payload?.section_d) {
          _state = merge(_state, { is_initialized: true });
          _state = merge(_state, action.payload.section_b);
        }

        // update against current state
        return merge(state, _state);
      }
    },
    [getAnnuitySectionD.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;

      if (loading === "pending" && currentRequestId === requestId) {
        let _state = {
          loading: "idle",
          error: null,
          currentRequestId: "",
          is_initialized: true,
        };
        if (action?.payload?.section_d) {
          _state = merge(_state, action.payload.section_d);
        }
        return merge(state, _state);
      }
    },
    [validateAnnuitySectionD.pending]: (state, action) => {
      const { requestId } = action.meta;
      const { loading } = state;

      if (loading === "idle") {
        state.loading = "pending";
        state.error = null;
        state.currentRequestId = requestId;
      }
    },
    [validateAnnuitySectionD.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;

      if (loading === "pending" && currentRequestId === requestId) {
        let _state = { loading: "idle", error: null, currentRequestId: "" };

        if (action?.payload.section_d) {
          _state = merge(_state, action.payload.section_d);
        }

        return merge(state, _state);
      }
    },
    [validateAnnuitySectionD.rejected]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;

      if (loading === "pending" && currentRequestId === requestId) {
        let _state = { loading: "idle", error: null, currentRequestId: "" };

        if (action?.payload?.section_d) {
          _state = merge(_state, action.payload.section_d);
        }

        if (action?.payload?.msg) {
          _state = merge(_state, { error: action.payload.msg });
        }

        return merge(state, _state);
      }
    },
  },
});

export const { update } = sliceD.actions;
export default sliceD.reducer;
