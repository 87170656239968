import * as yup from "yup";

/**
 * MPP general questions validator
 */
export const generalValidator = yup.object().shape({
  fname: yup.string().required("Please enter your firstname"),
  sname: yup.string().required("Please enter your lastname"),
  ident: yup.string().required("Please enter your ID number"),
  identyp: yup.string().required("Please select the ID type"),
  email: yup
    .string()
    .required("Please enter your email")
    .email("Please enter a valid email address"),
  tel: yup
    .string()
    .required("Please enter your telephone number")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Please enter a valid phone number"
    ),
  teltyp: yup.string().required("Please select your phone type"),
  addrs: yup.string().required("Please enter your address"),
  maddrs: yup.string().required("Please enter your mailling address"),
  saddrss: yup.boolean().optional(),
  job: yup.string().required("Please enter your occupation"),
  anninc: yup.number().typeError("Please enter a number amount"),
  hsport: yup.boolean().optional(),
  hsportrm: yup.string().when("hsport", {
    is: true,
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string(),
  }),
});

/**
 * MPP medical questions validator
 */
export const medicalValidator = yup.object().shape({
  feet: yup.string().when(["inches", "centi"], {
    is: (i, c) => (i == "" && c == "" ? true : false),
    then: yup.string().required("Please enter your height"),
    otherwise: yup.string(),
  }),
  inches: yup.string().optional(),
  centi: yup.string().optional(),
  weight: yup.string().required("Please enter your weight"),
  weightyp: yup.string().required("Please select the weight units"),
  covid: yup.string().required("Please select an option"),
  covidrm: yup.string().when("covid", {
    is: true,
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string(),
  }),
  cva: yup.string().required("Please select an option"),
  cvarm: yup.string().when("cva", {
    is: true,
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string(),
  }),
  angina: yup.string().required("Please select an option"),
  anginarm: yup.string().when("angina", {
    is: true,
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string(),
  }),
  diabetic: yup.string().required("Please select an option"),
  diabeticrm: yup.string().when("diabetic", {
    is: true,
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string(),
  }),
  renial: yup.string().required("Please select an option"),
  renialrm: yup.string().when("renial", {
    is: true,
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string(),
  }),
  hiv: yup.string().required("Please select an option"),
  hivrm: yup.string().when("hiv", {
    is: true,
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string(),
  }),
  ulcer: yup.string().required("Please select an option"),
  ulcerrm: yup.string().when("ulcer", {
    is: true,
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string(),
  }),
  smoker: yup.string().required("Please select an option"),
  smokerrm: yup.string().when("smoker", {
    is: true,
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string(),
  }),
  narcotics: yup.string().required("Please select an option"),
  narcoticsrm: yup.string().when("narcotics", {
    is: true,
    then: yup.string().required("Please enter your remarks"),
    otherwise: yup.string(),
  }),
});

/**
 * MPP declaration questions validator
 */

export const declarationValidator = yup.object().shape({
  health: yup
    .boolean()
    .test(
      "is-true",
      "You must agree to the terms and conditions",
      (v) => v == true
    )
    .required(),
  fact: yup
    .boolean()
    .test(
      "is-true",
      "You must agree to the terms and conditions",
      (v) => v == true
    )
    .required(),
  legal: yup
    .boolean()
    .test(
      "is-true",
      "You must agree to the terms and conditions",
      (v) => v == true
    )
    .required(),
  medical: yup
    .boolean()
    .test(
      "is-true",
      "You must agree to the terms and conditions",
      (v) => v == true
    )
    .required(),
});
