import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import maritime from "../../utils/maritime";

const initialState = {
  fname: "Ricardo",
  sname: "Augustine",
  dob: "1990-01-01",
  tel: "777-7777",
  email: "sdflj@dkljdf.com",
  age: "",
  sex: "M",
  smoker: "0",
  freq: "A",
  mplan: "8",
  plan: {
    sa: "100000",
    type: "0",
  },
  quote_step: 0,
  ans1: "N",
  ans2: "N",
  ans3: "N",
  ans4: "N",
  ans5: "N",
  ans6: "N",
  ans7: "N",
  ans8: "N",
  ans9: "N",
  ans10: "N",
  ans11: "N",
  ans12: "N",
  ans13: "N",
  ans14: "N",
  ans15: "N",
  ans16: "N",
  ans17: "N",
  ans18: "N",
  step: 0,
  terms: "",
  kyc: false,
  loading: "pending",
  payment: "S",
  modal: false,
  rejected: false,
  error: null,
  quote: null,
  selected_plan: null,
  selected_premium: "M",
  cost: null,
  currentRequestId: undefined,
};

export const save = createAsyncThunk(
  "accidental/save",
  async (_, { getState, requestId }) => {
    // get the accidental informtion from the store
    const { accidental } = getState();
    const { loading, currentRequestId } = accidental;

    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }

    // create the params for the request
    // const body = {
    //   ...accidental,
    // };
    const body = {
      fname: "David",
      sname: "Baldeo",
      dob: "1990-01-01",
      tel: "",
      email: "",
      age: "31",
      sex: "M",
      smoker: "0",
      freq: "A",
      mplan: "8",
      plan: {
        sa: accidental.sa || "0",
        type: "0",
      },
    };

    // send request to maritime api
    const { data } = await maritime.post("/nova/ado", body);
    console.log(data);
    return data;
  }
);

export const getQuote = createAsyncThunk(
  "accidental/get_quote",
  async (_, { getState, requestId, rejectWithValue }) => {
    // get the accidental informtion from the store
    // const { accidental } = getState();
    // const {
    //   loading,
    //   currentRequestId,
    //   fname,
    //   sname,
    //   dob,
    //   age,
    //   sex,
    //   smoker,
    //   freq,
    //   email,
    //   tel,
    //   mplan,
    //   plan,
    // } = accidental;

    // if (loading !== "pending" || requestId !== currentRequestId) {
    //   return;
    // }
    // const body = {
    //   fname,
    //   sname,
    //   dob,
    //   age: "31",
    //   sex,
    //   smoker,
    //   freq: "A",
    //   email,
    //   tel,
    //   mplan,
    //   plan,
    // };

    // try {
    //   // send request to maritime api
    //   const { data } = await maritime.post("/eapps/ado/quote", body);
    //   return data;
    // } catch (error) {
    //   return rejectWithValue(error.response.data);
    // }

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1700);
    });

    return {
      qid: "26812021080335511",
      frequency_premium: "1086.50",
      annual_premium: "1086.50",
      status: "OK",
    };
  }
);

/**
 * TODO
 *
 * create an action to validate the application on the back-end
 */
export const validate = createAsyncThunk(
  "accidental/create",
  async (_, { rejectWithValue }) => {
    try {
      const { data, status } = await maritime.post("/eapps/ado");
      return true;
    } catch (error) {
      return rejectWithValue(false);
    }
  }
);

const accidentalSlice = createSlice({
  name: "accidental",
  initialState,
  reducers: {
    update: (state, { payload }) => {
      return { ...state, ...payload };
    },
    update_quote_step: (state, { payload }) => {
      return { ...state, quote_step: payload };
    },
    next: (state, _) => {
      state.step = Math.min(state.step + 1, 2);
    },
    previous: (state, _) => {
      state.step = Math.max(state.step - 1, 0);
    },
    setPlan: (state, { payload }) => {
      /** updates the current plan and plans stores in alternatives */
      state.selected_plan = payload;
    },
    setPremium: (state, { payload }) => {
      /** switches between premiums mqsa */
      state.selected_premium = payload;
      switch (payload) {
        case "M":
          if (state.selected_plan)
            state.cost = state.selected_plan.monthly_premium;
          break;
        case "Q":
          if (state.selected_plan)
            state.cost = state.selected_plan.quarterly_premium;
          break;
        case "S":
          if (state.selected_plan)
            state.cost = state.selected_plan.semi_annual_premium;
          break;
        case "A":
          if (state.selected_plan)
            state.cost = state.selected_plan.annual_premium;
          break;
        default:
          throw new Error("Unexpected case");
      }
    },
    closeModal: (state, _) => {
      state.modal = false;
    },
    reset: (state, _) => {
      return { ...state, ...initialState };
    },
  },
  extraReducers: {
    [save.pending]: (state, action) => {
      const requestId = action.meta.requestId;
      if (state.loading === "idle") {
        state.loading = "pending";
        state.modal = false;
        state.error = null;
        state.currentRequestId = requestId;
      }
    },
    [save.fulfilled]: (state, action) => {
      const requestId = action.meta.requestId;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.quote = action.payload;
        state.modal = true;
        state.currentRequestId = undefined;
      }
    },
    [save.rejected]: (state, action) => {
      const requestId = action.meta.requestId;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [getQuote.pending]: (state, action) => {
      const requestId = action.meta.requestId;
      if (state.loading === "idle") {
        state.loading = "pending";
        state.modal = false;
        state.error = null;
        state.currentRequestId = requestId;
      }
    },
    [getQuote.fulfilled]: (state, action) => {
      const requestId = action.meta.requestId;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        //state.loading = "idle";
        //state.quote = action.payload;
        //state.quote_step = 1;
        //state.modal = true;
        //state.currentRequestId = undefined;
        return {
          ...state,
          loading: "idle",
          quote: { ...action.payload },
          quote_step: 1,
          selected_plan: {},
          cost: "3904.00",
          currentRequestId: undefined,
        };
      }
    },
    [getQuote.rejected]: (state, action) => {
      const requestId = action.meta.requestId;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        const { messages } = action.payload;
        const message = messages.reduce((a, v) => {
          if (v.all) {
            return v.all;
          }
        }, null);
        if (message.length > 0) {
          Swal.fire({
            icon: "error",
            title: message,
            toast: true,
            position: "bottom-left",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
        state.loading = "idle";
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [validate.pending]: (state, action) => {
      const requestId = action.meta.requestId;
      if (state.loading === "idle") {
        state.loading = "pending";
        state.error = null;
        state.currentRequestId = requestId;
      }
    },
    [validate.fulfilled]: (state, action) => {
      const requestId = action.meta.requestId;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        //state.quote = action.payload;
        //state.quote_step = 1;
        //state.modal = true;
        state.currentRequestId = undefined;
      }
    },
    [validate.rejected]: (state, action) => {
      const requestId = action.meta.requestId;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.rejected = true;
        state.currentRequestId = undefined;
      }
    },
  },
});

export const selectAccidental = (state) => state.accidental;
export const {
  update,
  next,
  previous,
  closeModal,
  update_quote_step,
  setPlan,
  setPremium,
  reset,
} = accidentalSlice.actions;
export default accidentalSlice.reducer;
