import * as yup from "yup";
import dayjs from "dayjs";

export const detailsValidator = yup.object().shape({
  plan: yup.object().shape({
    sa: yup.string().required(),
    type: yup.string().required(),
  }),
  fname: yup.string().required("Please enter your firstname"),
  sname: yup.string().required("Please enter your surname"),
  dob: yup
    .string()
    .required("Please enter your date of birth")
    .test("is-date", "Please enter a valid date", (v) => dayjs(v).isValid()),
  tel: yup.string().required("Please enter your phone number"),
  email: yup
    .string()
    .required("Please enter your email")
    .email("Please enter a valid email"),
  smoker: yup.string().required("Please select an option"),
  sex: yup.string().required("Please select an option"),
});
