import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Divider,
  Form,
  Header,
  Segment,
  Button,
  Icon,
  Grid,
  Message,
} from "semantic-ui-react";
import Section from "../../../components/forms/sections/Section";
import useFormShortcuts from "../../../hooks/useFormShortcuts";
import { DevTool } from "@hookform/devtools";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnnuityAgentCert,
  getAnnuitySectionC,
  validateAnnuityAgentCert,
} from "../../../thunks";
import { useEffect } from "react";
import { update } from "./slice";
import { cloneDeep } from "lodash";
import { useRef } from "react";

const selector = createSelector(
  (state) => state.annuity_agent_cert,
  (state) => state.global,
  (state) => state.annuity_section_c,
  (state) => state.annuity,
  (section, global, section_c, annuity) => {
    return {
      formValues: { ...section },
      loading: section.loading === "pending",
      is_initialized: section.is_initialized,
      section_c_initialized: section_c.is_initialized,
      has_policy: annuity.policy !== "",
      banks: global.banks,
      agents: global.agents,
      sources: global.sources,
      source: section_c.source.value,
      completed: annuity.completed,
    };
  }
);

const AgentCertificate = React.memo(({ back, next, save, defaultValues }) => {
  const dispatch = useDispatch();

  const {
    formValues,
    is_initialized,
    section_c_initialized,
    loading,
    agents,
    banks,
    sources,
    source,
    has_policy,
    completed,
  } = useSelector(selector);

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState,
    reset,
    errors,
  } = useForm({
    defaultValues: { ...formValues },
  });

  const { isSubmitting } = formState;

  const onNext = async (values) => {
    try {
      dispatch(update(values));
      const promise = await dispatch(validateAnnuityAgentCert()).unwrap();
      next();
    } catch (error) {}
  };

  const onBack = (values) => {
    dispatch(update(values));
    back();
  };

  const handleOnNext = useCallback(() => {
    if (loading) return;
    clearErrors();
    handleSubmit(onNext)();
  }, [handleSubmit, onNext, clearErrors]);

  const handleOnBack = useCallback(() => {
    handleSubmit(onBack)();
  }, [handleSubmit, onBack]);

  const setFormErrors = useCallback(() => {
    const newState = cloneDeep(formValues);

    reset(newState, { errors: false });

    // set errors here
    if (formValues.ac1.valid === false) {
      setError("ac1.value", { type: "manual" });
    }

    if (formValues.ac2.valid === false) {
      setError("ac2.value", { type: "manual" });
    }

    if (formValues.ac3a.valid === false) {
      setError("ac3a.value", { type: "manual" });
    }

    if (formValues.ac3b.valid === false) {
      setError("ac3b.value", { type: "manual" });
    }

    if (formValues.ac4.valid === false) {
      setError("ac4.value", { type: "manual" });
    }

    if (formValues.ac5.valid === false) {
      setError("ac5.value", { type: "manual" });
    }

    if (formValues.acr.valid === false) {
      setError("acr.value", { type: "manual" });
    }

    if (formValues.ac7b.valid === false) {
      setError("ac7b.value", { type: "manual" });
    }

    if (formValues.ac8b.valid === false) {
      setError("ac8b.value", { type: "manual" });
    }

    if (formValues.acss.valid === false) {
      setError("acss.value", { type: "manual" });
    }

    if (formValues.acbank.valid === false) {
      setError("acbank.value", { type: "manual" });
    }

    if (formValues.acbankbr.valid === false) {
      setError("acbankbr.value", { type: "manual" });
    }

    if (formValues.acr.valid === false) {
      setError("acr.value", { type: "manual" });
    }

    if (formValues.agtno.valid === false) {
      setError("agtno.value", {
        type: "manual",
        message: "Invalid agent number(s) for split",
      });
    }

    if (formValues.agtno2.valid === false) {
      setError("agtno2.value", {
        type: "manual",
        message: "Invalid agent number(s) for split",
      });
    }

    if (formValues.agtno3.valid === false) {
      setError("agtno3.value", {
        type: "manual",
        message: "Invalid agent number(s) for split",
      });
    }

    if (formValues.agtsp1.valid === false) {
      setError("agtsp1.value", {
        type: "manual",
        message: "Invalid agent split %",
      });
    }

    if (formValues.agtsp2.valid === false) {
      setError("agtsp2.value", {
        type: "manual",
        message: "Invalid agent split %",
      });
    }

    if (formValues.agtsp3.valid === false) {
      setError("agtsp3.value", {
        type: "manual",
        message: "Invalid agent split %",
      });
    }
  }, [formValues, reset]);

  const promise = useRef(null);

  // initialize the section
  useEffect(() => {
    // don't initialize the section if already initialized

    const init = async () => {
      try {
        if (section_c_initialized === false) {
          dispatch(update({ loading: "pending" }));
          promise.current = await dispatch(getAnnuitySectionC());
          dispatch(update({ loading: "idle" }));
        }

        if (is_initialized === false && has_policy === true) {
          promise.current = await dispatch(getAnnuityAgentCert());
        }
      } catch (err) {}
    };

    init();

    return () => {
      if (promise.current?.abort) {
        dispatch(update({ loading: "idle" }));
        promise.current.abort();
      }
    };
  }, [dispatch, is_initialized, has_policy, section_c_initialized]);

  // set form errors
  useEffect(() => {
    if (isSubmitting || loading || !is_initialized) return;
    setFormErrors();
  }, [isSubmitting, loading]);

  // setup shortcuts
  useFormShortcuts(handleOnNext, handleOnBack);

  return (
    <Grid centered columns={1}>
      <Grid.Column width={16}>
        <Form onSubmit={handleSubmit(next)}>
          <DevTool control={control} />
          <Segment padded stacked loading={loading}>
            <Message info icon>
              <Icon name="info circle" />
              <Message.Content>
                <Message.Header>Notice</Message.Header>
                <p>
                  Any <em>YES</em> answers must have the remarks field filled
                  out.
                </p>
              </Message.Content>
            </Message>
            <Form.Group grouped>
              <Controller
                control={control}
                name="ac1.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    label="How long have you known the Proposed Assured?"
                    fluid
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 15) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors?.ac1?.value}
                  />
                )}
              />
              <Controller
                control={control}
                name="ac2.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    fluid
                    label="Relationship"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 20) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.ac2}
                  />
                )}
              />
            </Form.Group>

            <Divider hidden />

            <Header as="h3">Have you known or heard of</Header>

            <Form.Group grouped>
              <Controller
                control={control}
                name="ac3a.value"
                rules={{
                  required: "Select an option",
                }}
                render={({ value, onChange, onBlur }) => (
                  <Form.Select
                    label="His/her excess drinking or use of drugs?"
                    placeholder="Select an option"
                    value={value}
                    onChange={(event, data) => onChange(data.value)}
                    onBlur={onBlur}
                    error={!!errors?.ac3a?.value}
                    options={[
                      {
                        key: "Y",
                        text: "Yes",
                        value: "1",
                      },
                      {
                        key: "N",
                        text: "No",
                        value: "0",
                      },
                    ]}
                  />
                )}
              />
            </Form.Group>

            <Form.Group grouped>
              <Controller
                control={control}
                name="ac3b.value"
                rules={{
                  required: "Select an option",
                }}
                render={({ value, onChange, onBlur }) => (
                  <Form.Select
                    value={value}
                    label="Anything which might affect the risk which is not so stated on the application"
                    placeholder="Select an option"
                    onChange={(event, data) => onChange(data.value)}
                    onBlur={onBlur}
                    error={!!errors?.ac3b?.value}
                    options={[
                      {
                        key: "Y",
                        text: "Yes",
                        value: "1",
                      },
                      {
                        key: "N",
                        text: "No",
                        value: "0",
                      },
                    ]}
                  />
                )}
              />
            </Form.Group>

            <Form.Group grouped>
              <Controller
                control={control}
                name="ac4.value"
                rules={{
                  required: "Select an option",
                }}
                render={({ value, onChange, onBlur }) => (
                  <Form.Select
                    value={value}
                    onChange={(event, data) => onChange(data.value)}
                    onBlur={onBlur}
                    label="Did the client approach you?"
                    placeholder="Select an option"
                    error={!!errors?.ac4?.value}
                    options={[
                      {
                        key: "Y",
                        text: "Yes",
                        value: "1",
                      },
                      {
                        key: "N",
                        text: "No",
                        value: "0",
                      },
                    ]}
                  />
                )}
              />

              <Controller
                control={control}
                name="ac5.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    value={value}
                    label="Please state annual income of Proposed Annuitant"
                    type="number"
                    onChange={(event, data) => {
                      if (data.value.length > 10) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.ac5}
                  />
                )}
              />

              <Controller
                control={control}
                name="acr.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.TextArea
                    onChange={(event, data) => {
                      if (data.value.length > 2000) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    fluid
                    value={value}
                    label="Remarks"
                    error={!!errors?.acr?.value}
                  />
                )}
              />
            </Form.Group>
          </Segment>

          <Divider hidden />

          <Header>Source of Payment</Header>

          <Segment padded stacked color="blue" loading={loading}>
            {source === "S" && (
              <Form.Group grouped>
                <Controller
                  control={control}
                  name="ac7a.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.Checkbox
                      checked={value === "1"}
                      label="S.S. Effective"
                      placeholder="Select an option"
                      onChange={(event, data) =>
                        onChange(data.checked ? "1" : "0")
                      }
                      error={!!errors.ac7a}
                      onBlur={onBlur}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="ac7b.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.Input
                      value={value}
                      onChange={(event, data) => {
                        if (data.value.length > 20) {
                          return;
                        }
                        onChange(data.value);
                      }}
                      onBlur={onBlur}
                      error={!!errors.ac7b && "Please enter effective date"}
                    />
                  )}
                />
              </Form.Group>
            )}
            {source === "B" && (
              <Form.Group grouped>
                <Controller
                  control={control}
                  name="ac8a.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.Checkbox
                      error={!!errors.ac8a}
                      checked={value === "1"}
                      label="D.D. Effective"
                      placeholder="Select an option"
                      onChange={(event, data) =>
                        onChange(data.checked ? "1" : "0")
                      }
                      onBlur={onBlur}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="ac8b.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.Input
                      value={value}
                      onChange={(event, data) => {
                        if (data.value.length > 20) {
                          return;
                        }
                        onChange(data.value);
                      }}
                      onBlur={onBlur}
                      error={!!errors.ac8b && "Please enter effective date"}
                    />
                  )}
                />
              </Form.Group>
            )}

            {source === "S" && (
              <Form.Group grouped>
                <Controller
                  control={control}
                  name="acss.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.Select
                      fluid
                      label="S.S. Source"
                      value={value}
                      onChange={(event, data) => onChange(data.value)}
                      onBlur={onBlur}
                      options={sources}
                      error={!!errors.acss}
                      search
                      clearable
                      placeholder="Select a source"
                    />
                  )}
                />
              </Form.Group>
            )}

            {source === "B" && (
              <Form.Group widths="equal">
                <Controller
                  control={control}
                  name="acbank.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.Select
                      fluid
                      label="Name of Bank"
                      value={value}
                      options={banks}
                      placeholder="Select a bank"
                      search
                      clearable
                      onChange={(event, data) => onChange(data.value)}
                      onBlur={onBlur}
                      error={!!errors?.acbank?.value}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="acbankbr.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.Input
                      fluid
                      error={!!errors.acbankbr}
                      label="Branch"
                      value={value}
                      onChange={(event, data) => {
                        if (data.value.length > 50) {
                          return;
                        }
                        onChange(data.value);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              </Form.Group>
            )}

            <Form.Group widths="equal">
              <Controller
                control={control}
                name="opaysrc.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    fluid
                    label="Other Payment Source(s)"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 30) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.opaysrc}
                  />
                )}
              />
            </Form.Group>

            {/*  */}
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="comments.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.TextArea
                    fluid
                    label="Comments"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 1000) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.comments}
                  />
                )}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Controller
                control={control}
                name="agtno.value"
                render={({ value }) => (
                  <Form.Input
                    label="Agent's Number"
                    value={value}
                    readOnly
                    error={
                      !!errors.agtno && {
                        content: errors.agtno.value.message,
                      }
                    }
                    fluid
                  />
                )}
              />
              <Controller
                control={control}
                name="agtsp1.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    label="% Split"
                    inputmode="numeric"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    fluid
                    error={
                      !!errors.agtsp1 && {
                        content: errors.agtsp1.value.message,
                      }
                    }
                  />
                )}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Controller
                control={control}
                name="agtno2.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Select
                    label="Agent's Number"
                    value={value}
                    fluid
                    placeholder="Select an agent"
                    search
                    clearable
                    onChange={(event, data) => onChange(data.value)}
                    onBlur={onBlur}
                    options={agents}
                    error={
                      !!errors.agtno2 && {
                        content: errors.agtno2.value.message,
                      }
                    }
                  />
                )}
              />
              <Controller
                control={control}
                name="agtsp2.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    label="% Split"
                    type="text"
                    inputMode="numeric"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={
                      !!errors.agtsp2 && {
                        content: errors.agtsp2.value.message,
                      }
                    }
                    fluid
                  />
                )}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="agtno3.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Select
                    label="Agent's Number"
                    value={value}
                    placeholder="Select an agent"
                    search
                    clearable
                    fluid
                    options={agents}
                    onBlur={onBlur}
                    onChange={(event, data) => onChange(data.value)}
                    error={
                      !!errors.agtno3 && {
                        content: errors.agtno3.value.message,
                      }
                    }
                  />
                )}
              />
              <Controller
                control={control}
                name="agtsp3.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    label="% Split"
                    inputMode="numeric"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    type="number"
                    error={
                      !!errors.agtsp3 && {
                        content: errors.agtsp3.value.message,
                      }
                    }
                    fluid
                  />
                )}
              />
            </Form.Group>
            <Divider hidden />
            <Segment basic clearing style={{ padding: "0" }}>
              <Button
                floated="right"
                primary
                size="large"
                type="button"
                disabled={loading}
                onClick={handleOnNext}
              >
                Save
              </Button>
              <Button
                type="button"
                floated="left"
                size="large"
                basic
                primary
                disabled={loading}
                onClick={handleOnBack}
              >
                Back
              </Button>
            </Segment>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
});

export default AgentCertificate;
