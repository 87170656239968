import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import payments from "../../utils/payments";

const initialState = {
  app: null,
  quote: null,
  cost: 0,
  premium: "M",
  delivery: "N",
  alternatives: [],
  pay_rwid: null,
  pay_id: null,
  pay_key: null,
  loading: "idle",
};

const createPayment = createAsyncThunk(
  "quote/create/payment",
  async (_, { getState, requestId }) => {
    const { state } = getState();
    const { loading, currentRequestId, cost } = state;

    // if the request is already in flight return
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }

    const { data } = await payments.post(
      "/ext_check.php",
      {},
      {
        params: {
          appol: "",
          appclt: "0",
          apdesc: "Accidental Death Online",
          amt: cost,
          damt: "25.00",
          fname: "Ricardo",
          sname: "Augustine",
          link: process.env.REACT_APP_PAYMENTS_REDIRECT_URI,
          ref: "",
        },
      }
    );

    if (data.STATUS === "SUCCESSFUL") {
      // clear the cart
      // go to congratulations page
    }

    if (data.STATUS === "UNSUCCESSFUL") {
      // show error
      // increase payment attempt
    }
  }
);

const checkPayment = createAsyncThunk(
  "quote/check/payment",
  async (_, { getState, requestId }) => {
    const { quote } = getState();
    const { loading, currentRequestId, pay_rwid, pay_id, pay_key } = quote;

    // if the payment isnt created, return
    if (pay_rwid === null || pay_id === null || pay_key === null) {
      return;
    }

    // if the request is already in flight return
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }

    const { data } = await payments.post(
      "/ext_check.php",
      {},
      { params: { pay_rwid, pay_id, pay_key } }
    );

    if (data.STATUS === "SUCCESSFUL") {
      // clear the cart
      // go to congratulations page
    }

    if (data.STATUS === "UNSUCCESSFUL") {
      // show error
      // increase payment attempt
    }
  }
);

const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    setApp: (state, { payload }) => {
      state.app = payload;
    },
    setQuote: (state, { payload }) => {
      state.quote = payload;

      if (state.quote) {
        switch (state.premium) {
          case "M":
            state.cost = state.quote.monthly_premium;
            break;
          case "Q":
            state.cost = state.quote.quarterly_premium;
            break;
          case "S":
            state.cost = state.quote.semi_annual_premium;
            break;
          case "A":
            state.cost = state.quote.annual_premium;
            break;
          default:
          //do nothing
        }
      }
    },
    setAlternatives: (state, { payload }) => {
      state.alternatives = payload;
    },
    setPremium: (state, { payload }) => {
      state.premium = payload;

      if (state.quote) {
        switch (payload) {
          case "M":
            state.cost = state.quote.monthly_premium;
            break;
          case "Q":
            state.cost = state.quote.quarterly_premium;
            break;
          case "S":
            state.cost = state.quote.semi_annual_premium;
            break;
          case "A":
            state.cost = state.quote.annual_premium;
            break;
          default:
          //do nothing
        }
      }
    },
    toggleDelivery: (state) => {
      switch (state.delivery) {
        case "Y":
          state.delivery = "N";
          break;
        case "N":
          state.delivery = "Y";
          break;
      }
    },
  },
});

export const selectQuote = (state) => state.quote;

export const { setQuote, setAlternatives, setApp, setPremium, toggleDelivery } =
  quoteSlice.actions;
export default quoteSlice.reducer;
