import React from "react";
import { Router } from "react-router-dom";
import { Sidebar as SemanticSidebar } from "semantic-ui-react";
import history from "./utils/history";
import Sidebar from "./features/sidebar/Sidebar";
import AppContent from "./AppContent";
import ScrollToTop from "./features/scroll-to-top/ScrollToTop";

const App = () => {
  return (
    <Router history={history}>
      <SemanticSidebar.Pushable
        style={{
          transform: "none",
        }}
      >
        <Sidebar />
        <AppContent />
      </SemanticSidebar.Pushable>
    </Router>
  );
};

export default App;
