import React, { useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Button, Divider, Form, Grid, List } from "semantic-ui-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Header, Segment } from "semantic-ui-react";
import useFormShortcuts from "../../../hooks/useFormShortcuts";
import {
  getAnnuitySectionC,
  getClientNumber,
  validateAnnuitySectionC,
} from "../../../thunks";
import { update } from "./slice";
import * as _ from "lodash";
import { useEffect } from "react";
import { DevTool } from "@hookform/devtools";
import { range } from "lodash";
import Beneficiary from "./Beneficiary";
import { selectSectionC } from "../annuitySlice";
import RiskSelector from "./RiskSelector";
import { premiumFrequencyOptions, Toast } from "../../../utils/options";

const SectionC = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();

  const {
    formValues,
    loading,
    is_initialized,
    relationships,
    has_policy,
    completed,
  } = useSelector(selectSectionC);

  // initialize form
  const {
    control,
    handleSubmit,
    watch,
    clearErrors,
    setError,
    formState,
    reset,
    errors,
  } = useForm({
    defaultValues: { ...formValues },
  });

  const { isSubmitting } = formState;

  const benfname = watch("benfname.value");

  const onNext = async (values) => {
    try {
      // update the store
      dispatch(update(values));

      // get client number if relationship is not estate
      const fname = values.benfname.value;
      const sname = values.bensname.value;
      const p1 = await dispatch(getClientNumber({ fname, sname }));
      const p1Result = unwrapResult(p1);

      // validate section c
      const p2 = await dispatch(validateAnnuitySectionC());
      const p2Result = unwrapResult(p2);

      // go to the next section
      handleNext();
    } catch (error) {}
  };

  const onBack = (values) => {
    dispatch(update(values));
    handleBack();
  };

  const handleOnBack = useCallback(() => {
    handleSubmit(onBack)();
  }, []);

  const handleOnNext = useCallback(() => {
    if (loading) return;

    clearErrors(undefined);

    handleSubmit(onNext)();
  }, [handleSubmit, onNext, clearErrors]);

  const setFormErrors = useCallback(() => {
    const newState = _.cloneDeep(formValues);

    reset(newState, { errors: false });

    if (formValues.birno.valid === false) {
      setError("birno.value", { type: "manual" });
    }

    if (formValues.source.valid === false) {
      setError("source.value", { type: "manual" });
    }

    if (formValues.paidprem.valid === false) {
      setError("paidprem.value", { type: "manual" });
    }

    if (formValues.poldate.valid === false) {
      setError("poldate.value", {
        type: "manual",
      });
    }

    if (formValues.paysrc_othr.valid === false) {
      setError("paysrc_othr.value", {
        type: "manual",
      });
    }

    if (formValues.poldate1.valid === false) {
      setError("poldate1.value", {
        type: "manual",
        message: "The policy date entered is not a valid date",
      });
    }

    if (formValues.poldate2.valid === false) {
      setError("poldate2.value", {
        type: "manual",
        message:
          "The policy date entered will not give the minimum 10 year duration",
      });
    }

    if (formValues.poldate3.valid === false) {
      setError("poldate3.value", {
        type: "manual",
        message:
          "The policy date entered cannot be more than 6 months before the application date",
      });
    }

    if (formValues.poldate4.valid === false) {
      setError("poldate4.value", {
        type: "manual",
        message:
          "No date of birth entered for insured; cannot validate policy date",
      });
    }

    if (formValues.benfname.valid === false) {
      setError("benfname.value", {
        type: "manual",
      });
    }

    if (formValues.bensname.valid === false) {
      setError("bensname.value", {
        type: "manual",
      });
    }

    if (formValues.benrel.valid === false) {
      setError("benrel.value", {
        type: "manual",
      });
    }

    if (formValues.fund1.valid === false) {
      setError("fund1.value", {
        type: "manual",
        message: "Invalid fund allocation percentages, does not add up to 100",
      });
    }

    if (formValues.fund2.valid === false) {
      setError("fund2.value", {
        type: "manual",
        message:
          "Invalid fund allocation percentages, annual allocation to any fund must be at least $300.00",
      });
    }

    if (formValues.appdate.valid === false) {
      setError("appdate.value", { type: "manual" });
    }
  }, [formValues, reset]);

  const source = watch("source.value");

  // initialize the state
  useEffect(() => {
    if (is_initialized === false && has_policy === true) {
      const init = async () => {
        await dispatch(getAnnuitySectionC());
      };

      init();
    }
  }, [dispatch, is_initialized, has_policy]);

  // don't set errors when validating the section
  useEffect(() => {
    if (isSubmitting || loading) return;
    setFormErrors();
  }, [isSubmitting, loading]);

  // register shortcuts
  useFormShortcuts(handleOnNext, handleOnBack);

  return (
    <Grid container centered columns={1}>
      <Grid.Column width={15}>
        <Form size="large" onSubmit={handleSubmit(onNext)}>
          <DevTool control={control} />
          <Segment padded stacked loading={loading}>
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="birno.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    label="BIR File number"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 15) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors?.birno}
                    fluid
                  />
                )}
              />
              <Form.Field>
                <label>Actual premium payable</label>
                <Form.Group grouped>
                  <Controller
                    control={control}
                    name="mdtfpr.value"
                    render={({ value, onBlur }) => (
                      <Form.Input
                        type="number"
                        step="0.1"
                        readOnly
                        value={value}
                        error={!!errors?.mdtfpr}
                        onBlur={onBlur}
                        fluid
                      />
                    )}
                  />
                </Form.Group>
              </Form.Field>
            </Form.Group>

            <Controller
              control={control}
              name="mdfreq.value"
              render={({ value, onBlur }) => (
                <Form.Input
                  value={
                    premiumFrequencyOptions.find((v) => v.value == value)
                      ?.text ?? "N/A"
                  }
                  readOnly
                  onBlur={onBlur}
                  label="Premium Frequency"
                  error={!!errors?.mdfreq}
                />
              )}
            />

            <Controller
              control={control}
              name="source.value"
              render={({ value, onChange, onBlur }) => (
                <Form.Select
                  value={value}
                  label="Payment Source"
                  onChange={(event, data) => onChange(data.value)}
                  onBlur={onBlur}
                  placeholder="Select an option"
                  error={!!errors?.source}
                  options={[
                    {
                      key: "S",
                      text: "Salary Saving",
                      value: "S",
                    },
                    {
                      key: "B",
                      text: "Direct Debit",
                      value: "B",
                    },
                    {
                      key: "C",
                      text: "Cash",
                      value: "C",
                    },
                    {
                      key: "O",
                      text: "Other",
                      value: "O",
                    },
                  ]}
                />
              )}
            />

            {source === "O" && (
              <Controller
                control={control}
                name="paysrc_othr.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    label="Other Payment Details"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 50) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.paysrc_othr}
                  />
                )}
              />
            )}

            <Form.Group grouped>
              <Controller
                control={control}
                name="paidprem.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Select
                    label="Have you paid your premium"
                    value={value}
                    onChange={(event, data) => onChange(data.value)}
                    onBlur={onBlur}
                    error={!!errors?.paidprem}
                    placeholder="Select an option"
                    options={[
                      {
                        key: "1",
                        text: "Yes",
                        value: "1",
                      },
                      {
                        key: "0",
                        text: "No",
                        value: "0",
                      },
                    ]}
                  />
                )}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="poldate.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Select
                    label="Date of policy"
                    fluid
                    type="date"
                    value={value}
                    options={[
                      {
                        key: "A",
                        text: "Date of Application",
                        value: "A",
                      },
                      {
                        key: "H",
                        text: "H/O Date",
                        value: "H",
                      },
                      {
                        key: "O",
                        text: "Other",
                        value: "O",
                      },
                    ]}
                    onChange={(event, data) => onChange(data.value)}
                    onBlur={onBlur}
                    error={!!errors.poldate}
                  />
                )}
              />
              <Controller
                control={control}
                name="poldate1.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    label={<label style={{ visibility: "hidden" }}>...</label>}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    type="date"
                    fluid
                    error={
                      !!errors.poldate && {
                        content: (
                          <List bulleted>
                            {errors.poldate1 && (
                              <List.Item>
                                {errors.poldate1.value.message}
                              </List.Item>
                            )}
                            {errors.poldate2 && (
                              <List.Item>
                                {errors.poldate2.value.message}
                              </List.Item>
                            )}
                            {errors.poldate3 && (
                              <List.Item>
                                {errors.poldate3.value.message}
                              </List.Item>
                            )}
                            {errors.poldate4 && (
                              <List.Item>
                                {errors.poldate4.value.message}
                              </List.Item>
                            )}
                          </List>
                        ),
                      }
                    }
                  />
                )}
              />
            </Form.Group>

            <Form.Group grouped>
              <Controller
                control={control}
                name="appdate.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    type="date"
                    label="Application Date"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.appdate}
                  />
                )}
              />
            </Form.Group>
          </Segment>

          <Divider hidden />
          <Header>Beneficiary</Header>
          <Segment padded stacked color="blue" loading={loading}>
            <Beneficiary />
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="benfname.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    label="Firstname"
                    fluid
                    value={value}
                    readOnly
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.benfname}
                  />
                )}
              />
              <Controller
                control={control}
                name="bensname.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    label="Surname"
                    fluid
                    value={value}
                    readOnly
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.bensname}
                  />
                )}
              />
            </Form.Group>
            <Form.Group grouped>
              <Controller
                control={control}
                name="benrel.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Select
                    label="Relationship to insured"
                    placeholder="Select a relationship"
                    search
                    clearable
                    fluid
                    value={value}
                    options={relationships}
                    onChange={(event, data) => {
                      if (benfname.length > 0 && data.value == 10) {
                        setError("benrel.value", {
                          type: "manual",
                          message:
                            "You cannot change beneficiary to Estate until you delete all of the existing beneficiaries",
                        });
                      } else {
                        if (!!errors?.benrel) {
                          clearErrors("benrel.value");
                        }
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={errors?.benrel?.value?.message}
                  />
                )}
              />
            </Form.Group>
          </Segment>

          <Divider hidden />
          <Header>Fund Allocation</Header>
          <Segment padded stacked color="blue" loading={loading}>
            <RiskSelector />
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="fund2.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Select
                    label="Goverment Bonds %"
                    value={parseInt(value)}
                    search
                    onChange={(event, data) => onChange(data.value)}
                    options={range(0, 110, 10).map((value) => {
                      return { key: value, text: `${value}`, value };
                    })}
                    onBlur={onBlur}
                    error={
                      !!errors.fund2 && { content: errors.fund2.value.message }
                    }
                    fluid
                  />
                )}
              />
              <Controller
                control={control}
                name="fund1.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Select
                    label="Corp. Bonds/Mortg. %"
                    value={parseInt(value)}
                    search
                    onChange={(event, data) => onChange(data.value)}
                    options={range(0, 110, 10).map((value) => {
                      return { key: value, text: `${value}`, value };
                    })}
                    onBlur={onBlur}
                    error={
                      !!errors.fund1 && { content: errors.fund1.value.message }
                    }
                    fluid
                  />
                )}
              />
              <Controller
                control={control}
                name="fund3.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Select
                    label="Property/Equity %"
                    value={parseInt(value)}
                    search
                    onChange={(event, data) => onChange(data.value)}
                    options={range(0, 60, 10).map((value) => {
                      return { key: value, text: `${value}`, value };
                    })}
                    onBlur={onBlur}
                    fluid
                    error={!!errors?.fund3}
                  />
                )}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Controller
                control={control}
                name="fundallocnotes.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.TextArea
                    label="Notes"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 100) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors?.fundallocnotes}
                  />
                )}
              />
            </Form.Group>
            <Divider hidden />
            <Segment basic clearing style={{ padding: "0" }}>
              <Button
                floated="right"
                primary
                size="large"
                onClick={handleOnNext}
                disabled={loading}
                type="button"
              >
                Save
              </Button>
              <Button
                type="button"
                floated="left"
                basic
                size="large"
                primary
                disabled={loading}
                onClick={handleOnBack}
              >
                Back
              </Button>
            </Segment>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default SectionC;
