import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import payments from "../../utils/payments";

const initialState = {
  description: "",
  cost: "0",
  delivery: "N",
  total: "0",
  referral: "",
};

/**
 * Creates a policy number for a product
 */
const getPolicy = createAsyncThunk(
  "policy/create",
  async (_, { requestId, rejectWithValue, getState }) => {
    const { checkout } = getState();
    const { referral } = checkout;

    try {
      const { data, status } = await payments.post("/ext_policy.php", null, {
        params: {
          ref: referral !== "" ? referral : requestId,
          pass: "72cBFdSNXPL3",
        },
      });

      if (status !== 200) {
        console.error(data);
        rejectWithValue(data);
      }

      console.log("data return from the call", data);
      console.log("requestId is ", requestId);
      return { policy: data.POLICY };
    } catch (error) {
      console.error("An error occured ", error);
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Creates a payment entry for a product
 */
const createPayment = createAsyncThunk(
  "payment/create",
  (options, { rejectWithValue }) => {
    const { appol, apclt, apdesc, amt, damt, fname, sname, email } = options;
    try {
      const { data } = payments.post("/ext_policy.php", null, {
        params: {
          appol,
          apclt,
          apdesc,
          amt,
          damt,
          fname,
          sname,
          link: process.env.REACT_APP_PAYMENTS_REDIRECT_URI,
          email,
        },
      });
    } catch (error) {
      console.error("An error occured creating payment ", error);
      return rejectWithValue(error.message);
    }
  }
);

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    checkout: (state, { payload }) => {
      state.description = payload.description;
      state.cost = payload.cost;

      if (state.delivery === "Y") {
        state.total = (parseFloat(state.cost) + 25).toFixed(2).toString();
        return;
      }
      state.total = payload.cost;
    },
    toggleDelivery: (state) => {
      state.delivery = state.delivery === "Y" ? "N" : "Y";
      if (state.delivery === "Y") {
        state.total = (parseFloat(state.cost) + 25).toFixed(2).toString();
        return;
      }

      state.total = state.cost;
    },
    clearCheckout: (state) => {
      return { ...state, ...initialState };
    },
  },
});

export const checkoutSelector = (state) => state.checkout;
export const { checkout, toggleDelivery, clearCheckout } =
  checkoutSlice.actions;
export default checkoutSlice.reducer;
