import React from "react";
import { Container } from "semantic-ui-react";

const NotFound = () => {
  return (
    <Container className="page">
      <h1>Page not found</h1>
    </Container>
  );
};

export default NotFound;
