import { createSlice, createSelector } from "@reduxjs/toolkit";
import { reset } from "../annuity/annuitySlice";

const initialState = {
  user: null,
  isAuthenticated: false,
  token: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, { payload }) => {
      const { googleId, name, imageUrl } = payload;
      state.user = { id: googleId, name, imageUrl };
      state.isAuthenticated = true;
    },
    logout: (state, _) => {
      state.user = null;
      state.isAuthenticated = null;
    },
    setToken: (state, action) => {
      state.token = action.payload.token;
    },
  },
});

export const selectAuth = (state) => state.auth;
export const { login, logout, setToken } = authSlice.actions;
export default authSlice.reducer;
