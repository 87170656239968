import { createSelector, createSlice } from "@reduxjs/toolkit";
import { selectAuth } from "../auth/authSlice";

const initialState = {
  open: false,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    open: (state, _) => {
      if (!state.open) {
        state.open = true;
      }
    },
    close: (state, _) => {
      if (state.open) {
        state.open = false;
      }
    },
    toggle: (state, _) => {
      state.open = !state.open;
    },
  },
});

const selectSelf = (state) => state.sidebar;
export const selectSidebar = createSelector(
  selectAuth,
  selectSelf,
  ({ isAuthenticated }, { open }) => ({
    open,
    isAuthenticated,
  })
);
export const { open, close, toggle } = sidebarSlice.actions;
export default sidebarSlice.reducer;
