import axios from "axios";

export default axios.create({
  baseURL: `${process.env.REACT_APP_PAYMENTS_URI}`,
  timeout: 1000 * 30,
  validateStatus: function (status) {
    return status === 200;
  },
});

/**
 *
 * HOW TO HANDLE PAYMENTS... MUST CHANGE IMPLEMENTATION FROM
 * OLD REDUX METHODS
 */

// export const checkPayment = () => async (dispatch, getState) => {
//   dispatch({ type: actions.MPP_CHECK_PAYMENT });
//   dispatch({ type: actions.MPP_CHECKING_PAYMENT });

//   const {
//     accidental: { payrwid, payid, paykey },
//   } = getState();

//   try {
//     const { data } = await payments.post(
//       "/ext_check.php",
//       qs.stringify({ pay_rwid: payrwid, pay_id: payid, pay_key: paykey })
//     );

//     if (data.STATUS == "SUCCESSFUL") {
//       dispatch({ type: actions.MPP_CHECKED_PAYMENT });
//       dispatch({ type: actions.MPP_CHECK_PAYMENT_SUCCESS });

//       setTimeout(() => {
//         dispatch({ type: actions.MPP_RESET });
//       }, 2000);

//       return;
//     }

//     throw new Error("Payment unsuccessful, try again later.");
//   } catch (error) {
//     dispatch({ type: actions.MPP_CHECKED_PAYMENT });
//     dispatch({ type: actions.MPP_CHECK_PAYMENT_ERROR });

//     // show error using toastr
//     //console.log(error);
//   }
// };

// export const showCreditCard =
//   ({ PAY_RWID, PAY_ID, PAY_KEY }) =>
//   (dispatch) => {
//     dispatch({ type: "accidental/CREDIT_CARD_PAYMENT" });
//     window.location.href = `${process.env.REACT_APP_CREDIT_CARD_URI}?pay_rwid=${PAY_RWID}&pay_id=${PAY_ID}&pay_key=${PAY_KEY}`;
//   };

// export const showDirectDebit = () => (dispatch) => {
//   dispatch({ type: "accidental/DIRECT_DEBIT_PAYMENT" });
//   window.location.href = `${process.env.REACT_APP_DIRECT_DEBIT_URI}`;
// };

// export const createPayment = () => async (dispatch, getState) => {
//   dispatch({ type: "accidental/CREATE_PAYMENT" });
//   dispatch({ type: "accidental/CREATING_PAYMENT" });

//   const {
//     accidental: { fname, sname, email },
//   } = getState();

//   try {
//     const { data } = await payments.post(
//       "/ext_create.php",
//       qs.stringify({
//         appol: "",
//         apclt: 0,
//         apdesc: "Simplified Life Insurance Product",
//         amt: 500, // get this from the store: {quote}
//         damt: 25,
//         fname,
//         sname,
//         link: process.env.REACT_APP_PAYMENTS_REDIRECT_URI, // Check the payment type before setting this
//         ref: Math.floor(Math.random() * 999999) + 900000, // using random number for now
//         email,
//       })
//     );

//     //{"PAY_ID":"1HCIUD1EcQdE","PAY_KEY":"3IVRCTLTQ3FC","PAY_RWID":"-100","MSG":"Payment added successfully"}
//     dispatch({ type: actions.MPP_CREATED_PAYMENT });
//     if (data.MSG === "Payment added successfully") {
//       dispatch({ type: actions.MPP_CREATE_PAYMENT_SUCCESS, payload: data });
//       return dispatch(showCreditCard(data));
//     } else {
//       throw new Error("Something went wrong, try again later");
//     }
//   } catch (error) {
//     dispatch({ type: actions.MPP_CREATED_PAYMENT });
//     dispatch({ type: actions.MPP_CREATE_PAYMENT_ERROR });

//     // show alert with toastr
//     console.log(error);
//   }
// };
