import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const lifeApi = createApi({
  reducerPath: "lifeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["Beneficiaries"],
  endpoints: (build) => ({}),
});
