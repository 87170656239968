import { createSlice } from "@reduxjs/toolkit";
import { merge } from "lodash";
import {
  completeAnnuityApplication,
  createPaymentEntry,
  getPolicyNumber,
  getAnnuitySectionB,
  resumeAnnuityApplication,
  sendClientPortalInformation,
  validateAnnuitySectionB,
} from "../../../thunks";
import { resetAnnuityApplication } from "../../../actions";

const initialState = {
  owncompany: {
    value: "",
    valid: true,
  },
  ownemail: {
    value: "",
    valid: true,
  },
  owncurradd1: {
    value: "",
    valid: true,
  },
  owncurradd2: {
    value: "",
    valid: true,
  },
  owncurradd3: {
    value: "",
    valid: true,
  },
  ownmailadd1: {
    value: "",
    valid: true,
  },
  ownmailadd2: {
    value: "",
    valid: true,
  },
  ownmailadd3: {
    value: "",
    valid: true,
  },
  owntel: {
    value: "",
    valid: true,
  },
  owntel2: {
    value: "",
    valid: true,
  },
  ownmobile: {
    value: "",
    valid: true,
  },
  ownno: {
    valid: true,
  },
  loading: "idle",
  error: null,
  is_initialized: false,
  currentRequestId: "",
};

const sliceB = createSlice({
  name: "annuity_slice_b",
  initialState,
  reducers: {
    update: (state, { payload }) => {
      return merge(state, payload);
    },
  },
  extraReducers: {
    [getPolicyNumber.pending]: (state) => {
      state.loading = "pending";
    },
    [getPolicyNumber.rejected]: (state) => {
      state.loading = "idle";
    },
    [getPolicyNumber.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [createPaymentEntry.pending]: (state) => {
      state.loading = "pending";
    },
    [createPaymentEntry.rejected]: (state) => {
      state.loading = "idle";
    },
    [createPaymentEntry.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [sendClientPortalInformation.pending]: (state) => {
      state.loading = "pending";
    },
    [sendClientPortalInformation.rejected]: (state) => {
      state.loading = "idle";
    },
    [sendClientPortalInformation.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [completeAnnuityApplication.pending]: (state) => {
      state.loading = "pending";
    },
    [completeAnnuityApplication.rejected]: (state) => {
      state.loading = "idle";
    },
    [completeAnnuityApplication.fulfilled]: (state) => {
      return Object.assign({}, state, initialState);
    },
    [resumeAnnuityApplication.fulfilled]: (state, action) => {
      return merge(state, initialState);
    },
    [getAnnuitySectionB.pending]: (state, action) => {
      const { requestId } = action.meta;
      const { loading } = state;
      if (loading === "idle") {
        state.loading = "pending";
        state.error = null;
        state.currentRequestId = requestId;
      }
    },
    [getAnnuitySectionB.rejected]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        let _state = { loading: "idle", currentRequestId: "" };

        if (action?.payload?.section_b) {
          _state = merge(_state, { is_initialized: true });
          _state = merge(_state, action.payload.section_b);
        }

        return merge(state, _state);
      }
    },
    [getAnnuitySectionB.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;

      if (loading === "pending" && currentRequestId === requestId) {
        let _state = {
          loading: "idle",
          error: null,
          currentRequestId: "",
          is_initialized: true,
        };

        if (action?.payload?.section_b) {
          _state = merge(_state, action.payload.section_b);
        }
        return merge(state, _state);
      }
    },
    [validateAnnuitySectionB.pending]: (state, action) => {
      const { requestId } = action.meta;
      const { loading } = state;
      if (loading === "idle") {
        state.loading = "pending";
        state.error = null;
        state.currentRequestId = requestId;
      }
    },
    [validateAnnuitySectionB.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        let _state = { loading: "idle", error: null, currentRequestId: "" };

        if (action?.payload?.section_b) {
          _state = merge(_state, action.payload.section_b);
        }

        return merge(state, _state);
      }
    },
    [validateAnnuitySectionB.rejected]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;

      if (loading === "pending" && currentRequestId === requestId) {
        let _state = { loading: "idle", error: null, currentRequestId: "" };

        if (action?.payload?.section_b) {
          _state = merge(_state, action.payload.section_b);
        }

        return merge(state, _state);
      }
    },
    [resetAnnuityApplication]: (state, action) => {
      return merge(state, initialState);
    },
  },
});

export const { update } = sliceB.actions;
export default sliceB.reducer;
