import React, { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Divider, Form, Segment, Button } from "semantic-ui-react";
import { Icon, Grid, Message } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { update } from "./slice";
import { getAnnuitySectionD, validateAnnuitySectionD } from "../../../thunks";
import useFormShortcuts from "../../../hooks/useFormShortcuts";
import { createSelector, unwrapResult } from "@reduxjs/toolkit";
import { DevTool } from "@hookform/devtools";
import { cloneDeep } from "lodash";

const selector = createSelector(
  (state) => state.annuity_section_d,
  (state) => state.annuity,
  (section, annuity) => {
    return {
      formValues: { ...section },
      loading: section.loading === "pending",
      is_initialized: section.is_initialized,
      has_policy: annuity.policy !== "",
      completed: annuity.completed,
    };
  }
);

const SectionD = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();

  const { formValues, loading, is_initialized, has_policy, completed } =
    useSelector(selector);

  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState,
    reset,
  } = useForm({
    defaultValues: { ...formValues },
  });

  const { isSubmitting } = formState;

  const onNext = async (values) => {
    try {
      dispatch(update(values));
      const promise = await dispatch(validateAnnuitySectionD()).unwrap();
      handleNext();
    } catch (error) {}
  };

  const onBack = (values) => {
    dispatch(update(values));
    handleBack();
  };

  const handleOnBack = useCallback(() => {
    handleSubmit(onBack)();
  }, []);

  const handleOnNext = useCallback(() => {
    if (loading) return;
    clearErrors();
    handleSubmit(onNext)();
  }, []);

  const setFormErrors = useCallback(() => {
    const newState = cloneDeep(formValues);

    reset(newState, { errors: false });

    if (formValues.da.valid === false) {
      setError("da.value", { type: "manual" });
    }

    if (formValues.db.valid === false) {
      setError("db.value", { type: "manual" });
    }

    if (formValues.dc.valid === false) {
      setError("dc.value", { type: "manual" });
    }

    if (formValues.dd.valid === false) {
      setError("dd.value", { type: "manual" });
    }

    if (formValues.de.valid === false) {
      setError("de.value", { type: "manual" });
    }

    if (formValues.df.valid === false) {
      setError("df.value", { type: "manual" });
    }

    if (formValues.dg.valid === false) {
      setError("dg.value", { type: "manual" });
    }

    if (formValues.dh.valid === false) {
      setError("dh.value", { type: "manual" });
    }

    if (formValues.di.valid === false) {
      setError("di.value", { type: "manual" });
    }

    if (formValues.dj.valid === false) {
      setError("dj.value", { type: "manual" });
    }

    if (formValues.da_remark.valid === false) {
      setError("da_remark.value", { type: "manual" });
    }

    if (formValues.db_remark.valid === false) {
      setError("db_remark.value", { type: "manual" });
    }

    if (formValues.dc_remark.valid === false) {
      setError("dc_remark.value", { type: "manual" });
    }

    if (formValues.dd_remark.valid === false) {
      setError("dd_remark.value", { type: "manual" });
    }

    if (formValues.de_remark.valid === false) {
      setError("de_remark.value", { type: "manual" });
    }

    if (formValues.df_remark.valid === false) {
      setError("df_remark.value", { type: "manual" });
    }

    if (formValues.dg_remark.valid === false) {
      setError("dg_remark.value", { type: "manual" });
    }

    if (formValues.dh_remark.valid === false) {
      setError("dh_remark.value", { type: "manual" });
    }

    if (formValues.di_remark.valid === false) {
      setError("di_remark.value", { type: "manual" });
    }

    if (formValues.dj_remark.valid === false) {
      setError("dj_remark.value", { type: "manual" });
    }
  }, [formValues, reset]);

  // initialize the section
  useEffect(() => {
    // don't initialize the section if already initialized
    if (is_initialized === false && has_policy === true) {
      const init = async () => {
        await dispatch(getAnnuitySectionD()).unwrap();
      };

      init();
    }
  }, [dispatch, is_initialized, has_policy]);

  // set for errors
  useEffect(() => {
    // dont' set errors when validation the section
    if (isSubmitting || loading) return;

    setFormErrors();
  }, [isSubmitting, loading]);

  useFormShortcuts(handleOnNext, handleOnBack);

  const da = watch("da.value");
  const db = watch("db.value");
  const dc = watch("dc.value");
  const dd = watch("dd.value");
  const de = watch("de.value");
  const df = watch("df.value");
  const dg = watch("dg.value");
  const dh = watch("dh.value");
  const di = watch("di.value");
  const dj = watch("dj.value");

  return (
    <Grid centered columns={1}>
      <Grid.Column width={15}>
        <Segment stacked padded loading={loading}>
          <Message
            info
            icon="info circle"
            content='Give details of all "Yes" answers in Remarks section'
          />
          <Form size="large" onSubmit={handleSubmit(onNext)}>
            <DevTool control={control} />
            <Form.Group grouped>
              <Controller
                control={control}
                name="da.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Field>
                    <label>
                      Have you any intention of changing your country of
                      residence within the next 6 months
                    </label>
                    <Form.Group inline>
                      <Form.Radio
                        label="Yes"
                        checked={value === 1}
                        onChange={() => onChange(1)}
                        onBlur={onBlur}
                      />

                      <Form.Radio
                        label="No"
                        checked={value === 0}
                        onChange={() => onChange(0)}
                        onBlur={onBlur}
                      />
                    </Form.Group>
                  </Form.Field>
                )}
              />

              {da === 1 && (
                <Controller
                  control={control}
                  name="da_remark.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.TextArea
                      label="Remarks"
                      value={value}
                      onChange={(event, data) => {
                        if (data.value.length > 2000) return;

                        onChange(data.value);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              )}
            </Form.Group>

            <Form.Group grouped>
              <Controller
                control={control}
                name="db.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Field>
                    <label>
                      Have you any intention of change your occupation within
                      the next 6 months?
                    </label>
                    <Form.Group inline>
                      <Form.Radio
                        label="Yes"
                        checked={value === 1}
                        onChange={() => onChange(1)}
                        onBlur={onBlur}
                      />

                      <Form.Radio
                        label="No"
                        checked={value === 0}
                        onChange={() => onChange(0)}
                        onBlur={onBlur}
                      />
                    </Form.Group>
                  </Form.Field>
                )}
              />
              {db === 1 && (
                <Controller
                  control={control}
                  name="db_remark.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.TextArea
                      label="Remarks"
                      value={value}
                      onChange={(event, data) => {
                        if (data.value.length > 2000) return;

                        onChange(data.value);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              )}
            </Form.Group>

            <Form.Group grouped>
              <Controller
                control={control}
                name="dc.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Field>
                    <label>
                      Have you flown other than as a fare paying passenger or
                      have you any intention of doing so?
                    </label>
                    <Form.Group inline>
                      <Form.Radio
                        label="Yes"
                        checked={value === 1}
                        onChange={() => onChange(1)}
                        onBlur={onBlur}
                      />

                      <Form.Radio
                        label="No"
                        checked={value === 0}
                        onChange={() => onChange(0)}
                        onBlur={onBlur}
                      />
                    </Form.Group>
                  </Form.Field>
                )}
              />
              {dc === 1 && (
                <Controller
                  control={control}
                  name="dc_remark.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.TextArea
                      label="Remarks"
                      value={value}
                      onChange={(event, data) => {
                        if (data.value.length > 2000) return;

                        onChange(data.value);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              )}
            </Form.Group>

            <Form.Group grouped>
              <Controller
                control={control}
                name="dd.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Field>
                    <label>
                      Have you any past, present or expected engagement in
                      diving, automobile or motor cycle racing, boxing,
                      wrestling, boating activities, or other hazardous soprts,
                      avocations or hobbies?
                    </label>
                    <Form.Group inline>
                      <Form.Radio
                        label="Yes"
                        checked={value === 1}
                        onChange={() => onChange(1)}
                        onBlur={onBlur}
                      />

                      <Form.Radio
                        label="No"
                        checked={value === 0}
                        onChange={() => onChange(0)}
                        onBlur={onBlur}
                      />
                    </Form.Group>
                  </Form.Field>
                )}
              />
              {dd === 1 && (
                <Controller
                  control={control}
                  name="dd_remark.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.TextArea
                      label="Remarks"
                      value={value}
                      onChange={(event, data) => {
                        if (data.value.length > 2000) return;

                        onChange(data.value);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              )}
            </Form.Group>

            <Form.Group grouped>
              <Controller
                control={control}
                name="de.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Field>
                    <label>
                      Has any proposal for Life, Dread Disease, Accident or
                      Health Insurance on your life ever been declined,
                      deferred, rated or accepted on special terms?
                    </label>
                    <Form.Group inline>
                      <Form.Radio
                        label="Yes"
                        checked={value === 1}
                        onChange={() => onChange(1)}
                        onBlur={onBlur}
                      />

                      <Form.Radio
                        label="No"
                        checked={value === 0}
                        onChange={() => onChange(0)}
                        onBlur={onBlur}
                      />
                    </Form.Group>
                  </Form.Field>
                )}
              />
              {de === 1 && (
                <Controller
                  control={control}
                  name="de_remark.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.TextArea
                      label="Remarks"
                      value={value}
                      onChange={(event, data) => {
                        if (data.value.length > 2000) return;

                        onChange(data.value);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              )}
            </Form.Group>

            <Form.Group grouped>
              <Controller
                control={control}
                name="df.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Field>
                    <label>
                      Is any application or negotiation for Life, Dread Disease,
                      Accident or Health insurance on your life now pending or
                      contemplated in this or any other Company?
                    </label>
                    <Form.Group inline>
                      <Form.Radio
                        label="Yes"
                        checked={value === 1}
                        onChange={() => onChange(1)}
                        onBlur={onBlur}
                      />

                      <Form.Radio
                        label="No"
                        checked={value === 0}
                        onChange={() => onChange(0)}
                        onBlur={onBlur}
                      />
                    </Form.Group>
                  </Form.Field>
                )}
              />
              {df === 1 && (
                <Controller
                  control={control}
                  name="df_remark.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.TextArea
                      label="Remarks"
                      value={value}
                      onChange={(event, data) => {
                        if (data.value.length > 2000) return;

                        onChange(data.value);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              )}
            </Form.Group>

            <Form.Group grouped>
              <Controller
                control={control}
                name="dg.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Field>
                    <label>
                      Have you ever requested or received any benefits, payment
                      or a pension because of an injury, sickness or disability?
                    </label>
                    <Form.Group inline>
                      <Form.Radio
                        label="Yes"
                        checked={value === 1}
                        onChange={() => onChange(1)}
                        onBlur={onBlur}
                      />

                      <Form.Radio
                        label="No"
                        checked={value === 0}
                        onChange={() => onChange(0)}
                        onBlur={onBlur}
                      />
                    </Form.Group>
                  </Form.Field>
                )}
              />
              {dg === 1 && (
                <Controller
                  control={control}
                  name="dg_remark.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.TextArea
                      label="Specify"
                      value={value}
                      onChange={(event, data) => {
                        if (data.value.length > 2000) return;

                        onChange(data.value);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              )}
            </Form.Group>
            {/*  */}
            <Form.Group grouped>
              <Controller
                control={control}
                name="dh.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Field>
                    <label>
                      Are there any current or have there ever been any suits or
                      judgements against you?
                    </label>
                    <Form.Group inline>
                      <Form.Radio
                        label="Yes"
                        checked={value === 1}
                        onChange={() => onChange(1)}
                        onBlur={onBlur}
                      />
                      <Form.Radio
                        label="No"
                        checked={value === 0}
                        onChange={() => onChange(0)}
                        onBlur={onBlur}
                      />
                    </Form.Group>
                  </Form.Field>
                )}
              />
              {dh === 1 && (
                <Controller
                  control={control}
                  name="dh_remark.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.TextArea
                      label="Specify"
                      value={value}
                      onChange={(event, data) => {
                        if (data.value.length > 2000) return;

                        onChange(data.value);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              )}
            </Form.Group>
            {/*  */}
            <Form.Group grouped>
              <Controller
                control={control}
                name="di.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Field>
                    <label>
                      Have you used any name other than the name shown in this
                      application?
                    </label>
                    <Form.Group inline>
                      <Form.Radio
                        label="Yes"
                        checked={value === 1}
                        onChange={() => onChange(1)}
                        onBlur={onBlur}
                      />
                      <Form.Radio
                        label="No"
                        checked={value === 0}
                        onChange={() => onChange(0)}
                        onBlur={onBlur}
                      />
                    </Form.Group>
                  </Form.Field>
                )}
              />
              {di === 1 && (
                <Controller
                  control={control}
                  name="di_remark.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.TextArea
                      label="Specify"
                      value={value}
                      onChange={(event, data) => {
                        if (data.value.length > 2000) return;

                        onChange(data.value);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              )}
            </Form.Group>
            {/*  */}
            <Form.Group grouped>
              <Controller
                control={control}
                name="dj.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Field>
                    <label>
                      Do you participate in any sport or fitness programme?
                    </label>
                    <Form.Group inline>
                      <Form.Radio
                        label="Yes"
                        checked={value === 1}
                        onChange={() => onChange(1)}
                        onBlur={onBlur}
                      />
                      <Form.Radio
                        label="No"
                        checked={value === 0}
                        onChange={() => onChange(0)}
                        onBlur={onBlur}
                      />
                    </Form.Group>
                  </Form.Field>
                )}
              />
              {dj === 1 && (
                <Controller
                  control={control}
                  name="dj_remark.value"
                  render={({ value, onChange, onBlur }) => (
                    <Form.TextArea
                      label="Remarks"
                      value={value}
                      onChange={(event, data) => {
                        if (data.value.length > 2000) return;

                        onChange(data.value);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              )}
            </Form.Group>
          </Form>
          <Divider hidden />
          <Segment basic clearing style={{ padding: "0" }}>
            <Button
              floated="right"
              primary
              size="large"
              disabled={loading}
              onClick={handleOnNext}
              type="button"
            >
              Save
            </Button>
            <Button
              type="button"
              floated="left"
              basic
              size="large"
              primary
              disabled={loading}
              onClick={handleOnBack}
            >
              Back
            </Button>
          </Segment>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default SectionD;
