import React, { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Divider,
  Form,
  Header,
  Segment,
  Button,
  Grid,
} from "semantic-ui-react";
import useFormShortcuts from "../../../hooks/useFormShortcuts";
import { getAnnuitySectionB, validateAnnuitySectionB } from "../../../thunks";
import { update } from "./slice";
import { createSelector, unwrapResult } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import ClientSystem from "../section-a/ClientSystem";

const selector = createSelector(
  (state) => state.annuity_section_b,
  (state) => state.annuity,
  (section, annuity) => {
    return {
      formValues: { ...section },
      has_policy: annuity.policy !== "",
      is_initialized: section.is_initialized,
      loading: section.loading === "pending",
      completed: annuity.completed,
    };
  }
);

const SectionB = React.memo(({ handleNext, handleBack }) => {
  const dispatch = useDispatch();

  const { formValues, loading, is_initialized, has_policy, completed } =
    useSelector(selector);

  const {
    control,
    handleSubmit,
    errors,
    setError,
    formState,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: { ...formValues },
  });

  const { isSubmitting } = formState;

  // next
  const onNext = async (values) => {
    // update values in the store
    dispatch(update(values));

    // Use unwrapResult to check if the request failed
    // A failed request or error in a thunk will never return
    // a rejected promise
    try {
      const promise = await dispatch(validateAnnuitySectionB()).unwrap();
      handleNext();
    } catch (error) {}
  };

  // back
  const onBack = (values) => {
    dispatch(update(values));
    handleBack();
  };

  const handleOnNext = useCallback(() => {
    // prevent sending duplicate requests
    if (loading) return;

    // clear form errors before sending validate request
    clearErrors(undefined);

    // validate section b
    handleSubmit(onNext)();
  }, [handleSubmit, onNext, clearErrors]);

  const handleOnBack = useCallback(() => {
    if (loading) return;
    handleSubmit(onBack)();
  });

  const setFormErrors = useCallback(() => {
    // get state
    const newState = cloneDeep(formValues);

    //reset the form
    reset(newState, { errors: false });

    // set manual errors
    if (
      formValues.owncompany.valid === false ||
      formValues.ownno.valid === false
    ) {
      setError("owncompany.value", {
        type: "manual",
        message:
          !!!formValues.ownno.valid && "No owner assinged in client system",
      });
    }

    if (formValues.owncurradd1.valid === false) {
      setError("owncurradd1.value", { type: "manual" });
    }

    if (formValues.owncurradd2.valid === false) {
      setError("owncurradd2.value", { type: "manual" });
    }

    if (formValues.ownmailadd1.valid === false) {
      setError("ownmailadd1.value", { type: "manual" });
    }

    if (formValues.ownmailadd2.valid === false) {
      setError("ownmailadd2.value", { type: "manual" });
    }

    if (formValues.owntel.valid === false) {
      setError("owntel.value", { type: "manual" });
    }

    if (formValues.ownemail.valid === false) {
      setError("ownemail.value", { type: "manual" });
    }
  }, [formValues, reset]);

  useEffect(() => {
    if (is_initialized === false && has_policy === true) {
      const init = async () => {
        await dispatch(getAnnuitySectionB());
      };
      init();
    }
  }, [dispatch, is_initialized, has_policy]);

  // don't set errors when validating the section
  useEffect(() => {
    if (isSubmitting || loading) return;
    setFormErrors();
  }, [isSubmitting, loading]);

  useFormShortcuts(handleOnNext, handleOnBack);

  return (
    <Grid centered columns={1}>
      <Grid.Column width={15}>
        <Segment padded stacked loading={loading}>
          <ClientSystem
            clientType="owner"
            label="Assign owner"
            onRefresh={handleOnNext}
          />
          <Form size="large" onSubmit={handleSubmit(handleOnNext)}>
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="owncompany.value"
                render={({ value }) => (
                  <Form.Input
                    label="Registered Company Name"
                    value={value}
                    id="owncompany"
                    readOnly
                    fluid
                    error={errors?.owncompany?.value?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name="ownemail.value"
                render={({ value }) => (
                  <Form.Input
                    label="Email Address"
                    value={value}
                    id="ownemail"
                    fluid
                    readOnly
                    error={!!errors.ownemail}
                  />
                )}
              />
            </Form.Group>

            <Header size="tiny">Registered Address</Header>
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="owncurradd1.value"
                render={({ value }) => (
                  <Form.Input
                    fluid
                    label="Line 1"
                    id="owncurradd1"
                    value={value}
                    readOnly
                    error={!!errors.owncurradd1}
                  />
                )}
              />
              <Controller
                control={control}
                name="owncurradd2.value"
                render={({ value }) => (
                  <Form.Input
                    fluid
                    label="Line 2"
                    id="owncurradd2"
                    value={value}
                    readOnly
                    error={!!errors.owncurradd2}
                  />
                )}
              />
              <Controller
                control={control}
                name="owncurradd3.value"
                render={({ value }) => (
                  <Form.Input
                    fluid
                    label="Line 3"
                    id="owncurradd3"
                    value={value}
                    readOnly
                    error={!!errors.owncurradd3}
                  />
                )}
              />
            </Form.Group>

            <Header size="tiny">Mailling Address</Header>
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="ownmailadd1.value"
                render={({ value }) => (
                  <Form.Input
                    fluid
                    label={<label for="ownmailadd1">Line 1</label>}
                    id="ownmailadd1"
                    value={value}
                    readOnly
                    error={!!errors.ownmailadd1}
                  />
                )}
              />
              <Controller
                control={control}
                name="ownmailadd2.value"
                render={({ value }) => (
                  <Form.Input
                    label={<label for="ownmailadd2">Line 2</label>}
                    id="ownmailadd2"
                    value={value}
                    readOnly
                    fluid
                    error={!!errors.ownmailadd2}
                  />
                )}
              />
              <Controller
                control={control}
                name="ownmailadd3.value"
                render={({ value }) => (
                  <Form.Input
                    label={<label for="ownmailadd3">Line 3</label>}
                    id="ownmailadd3"
                    value={value}
                    readOnly
                    fluid
                    error={!!errors.ownmailadd3}
                  />
                )}
              />
            </Form.Group>

            <Divider hidden />
            <Header size="tiny">Contact Numbers</Header>
            <Form.Group widths="equal">
              <Controller
                control={control}
                name="owntel.value"
                render={({ value }) => (
                  <Form.Input
                    label="Company No. 1"
                    fluid
                    id="owntel"
                    value={value}
                    readOnly
                    error={!!errors.owntel}
                  />
                )}
              />
              <Controller
                control={control}
                name="owntel2.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    label="Company No. 2"
                    fluid
                    id="owntel2"
                    type="number"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 7) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.owntel2}
                  />
                )}
              />
              <Controller
                control={control}
                name="ownmobile.value"
                render={({ value, onChange, onBlur }) => (
                  <Form.Input
                    label="Mobile No."
                    type="number"
                    fluid
                    id="ownmobile"
                    value={value}
                    onChange={(event, data) => {
                      if (data.value.length > 7) {
                        return;
                      }
                      onChange(data.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors.ownmobile}
                  />
                )}
              />
            </Form.Group>
          </Form>
          <Divider hidden />
          <Segment basic clearing style={{ padding: "0" }}>
            <Button
              floated="right"
              primary
              size="large"
              disabled={loading}
              type="button"
              onClick={handleOnNext}
            >
              Save
            </Button>
            <Button
              type="button"
              floated="left"
              size="large"
              basic
              primary
              onClick={handleOnBack}
              disabled={loading}
            >
              {" "}
              Back
            </Button>
          </Segment>
        </Segment>
      </Grid.Column>
    </Grid>
  );
});

export default SectionB;
