import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

const selectState = (state) => state;

export const useMlclSelector = () => useSelector((state) => state.mlcl);

export const useAuthSelector = () => useSelector((state) => state.auth);

export const useSidebarSelector = (fn) =>
  useSelector(fn ? fn : (state) => state.sidebar);

export const accidentalSelector = createSelector(
  selectState,
  (state) => state.accidental
);
