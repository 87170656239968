import { createSlice } from "@reduxjs/toolkit";
import {
  getAnnuitySectionC,
  validateAnnuitySectionC,
  resumeAnnuityApplication,
  completeAnnuityApplication,
  getPolicyNumber,
  createPaymentEntry,
  sendClientPortalInformation,
  getClientNumber,
} from "../../../thunks";
import { merge } from "lodash";
import { resetAnnuityApplication } from "../../../actions";

const initialState = {
  anntype1: {
    value: "",
    valid: false,
  },
  anntype2: {
    value: "",
    valid: false,
  },
  retage: {
    value: "",
    valid: false,
  },
  plan1: {
    value: "",
  },
  plan2: {
    value: "",
  },
  plan3: {
    value: "",
  },
  plan4: {
    value: "",
  },
  plan5: {
    value: "",
  },
  plan6: {
    value: "",
  },
  mpap1: {
    value: "",
    valid: false,
  },
  mpap2: {
    value: "",
  },
  mpap3: {
    value: "",
  },
  mpap4: {
    value: "",
  },
  mpap5: {
    value: "",
  },
  mpap6: {
    value: "",
  },
  mdtapr: {
    value: "",
  },
  birno: {
    value: "",
    valid: true,
  },
  mdtfpr: {
    value: "",
  },
  mdfreq: {
    value: "",
  },
  source: {
    value: "",
    valid: true,
  },
  paidprem: {
    value: "",
    valid: true,
  },
  poldate: {
    value: "",
    valid: true,
  },
  poldate1: {
    value: "",
    valid: true,
  },
  poldate2: {
    value: "",
    valid: true,
  },
  poldate3: {
    value: "",
    valid: true,
  },
  poldate4: {
    value: "",
    valid: true,
  },
  poldated: {
    value: "",
  },
  poldatem: {
    value: "",
  },
  poldatey: {
    value: "",
  },
  benname: {
    value: "",
    value: true,
  },
  benfname: {
    value: "",
    valid: true,
  },
  bensname: {
    value: "",
    valid: true,
  },
  fund1: {
    value: "",
    valid: true,
  },
  fund2: {
    value: "",
    valid: true,
  },
  fund3: {
    value: "",
  },
  appdate: {
    value: "",
  },
  appdatey: {
    value: "",
  },
  appdatem: {
    value: "",
  },
  appdated: {
    value: "",
  },
  benrel: {
    value: "",
    valid: true,
  },
  wp: {
    value: "",
  },
  medrata: {
    value: "",
    valid: true,
  },
  medratb: {
    value: "",
    valid: true,
  },
  retdate: {
    value: "",
  },
  rr: {
    value: "",
  },
  anntype2_othr: {
    value: "",
    valid: true,
  },
  paysrc_othr: {
    value: "",
    valid: true,
  },
  fundallocnotes: {
    value: "",
  },
  agt: {
    value: "",
  },
  cltno: {
    value: "",
  },
  loading: "idle",
  error: null,
  is_initialized: false,
  bencode: "",
  currentRequestId: "",
};

const sliceC = createSlice({
  name: "annuity_section_c",
  initialState,
  reducers: {
    update: (state, action) => {
      merge(state, action.payload);
    },
    setFundValues: (state, action) => {
      const { payload } = action;
      state.fund1.value = payload.fund1;
      state.fund2.value = payload.fund2;
      state.fund3.value = payload.fund3;
    },
  },
  extraReducers: {
    [getPolicyNumber.pending]: (state) => {
      state.loading = "pending";
    },
    [getPolicyNumber.rejected]: (state) => {
      state.loading = "idle";
    },
    [getPolicyNumber.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [createPaymentEntry.pending]: (state) => {
      state.loading = "pending";
    },
    [createPaymentEntry.rejected]: (state) => {
      state.loading = "idle";
    },
    [createPaymentEntry.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [sendClientPortalInformation.pending]: (state) => {
      state.loading = "pending";
    },
    [sendClientPortalInformation.rejected]: (state) => {
      state.loading = "idle";
    },
    [sendClientPortalInformation.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [resetAnnuityApplication]: (state, action) => {
      return merge(state, initialState);
    },
    [completeAnnuityApplication.pending]: (state) => {
      state.loading = "pending";
    },
    [completeAnnuityApplication.rejected]: (state) => {
      state.loading = "idle";
    },
    [completeAnnuityApplication.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [resumeAnnuityApplication.fulfilled]: (state, { payload }) => {
      return Object.assign({}, state, initialState, {
        bencode: payload.bencode,
      });
    },
    [getAnnuitySectionC.pending]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "idle") {
        state.loading = "pending";
        state.error = null;
        state.currentRequestId = requestId;
      }
    },
    [getAnnuitySectionC.rejected]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        let _state = { loading: "idle", currentRequestId: "" };

        if (action?.payload?.section_c) {
          _state = merge(_state, { is_initialized: true });
          _state = merge(_state, action.payload.section_c);
        }

        return merge(state, _state);
      }
    },
    [getAnnuitySectionC.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        let _state = {
          loading: "idle",
          error: null,
          currentRequestId: "",
          is_initialized: true,
        };

        if (action?.payload?.section_c) {
          _state = merge(_state, action.payload.section_c);
        }

        return merge(state, _state);
      }
    },
    [getClientNumber.pending]: (state, action) => {
      const { requestId } = action.meta;
      const { loading } = state;
      if (loading === "idle") {
        state.loading = "pending";
        state.error = null;
        state.currentRequestId = requestId;
      }
    },
    [getClientNumber.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        state.bencode = action.payload;
        state.loading = "idle";
        state.currentRequestId = "";
      }
    },
    [getClientNumber.rejected]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;

      if (loading === "pending" && currentRequestId === requestId) {
        state.loading = "idle";
        state.currentRequestId = "";
      }
    },
    [validateAnnuitySectionC.pending]: (state, action) => {
      const { requestId } = action.meta;
      const { loading } = state;
      if (loading === "idle") {
        state.loading = "pending";
        state.error = null;
        state.currentRequestId = requestId;
      }
    },
    [validateAnnuitySectionC.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;

      if (loading === "pending" && currentRequestId === requestId) {
        let _state = { loading: "idle", error: null, currentRequestId: "" };

        if (action?.payload?.section_c) {
          _state = merge(_state, action.payload.section_c);
        }

        return merge(state, _state);
      }
    },
    [validateAnnuitySectionC.rejected]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;

      if (loading === "pending" && currentRequestId === requestId) {
        let _state = { loading: "idle", error: null, currentRequestId: "" };

        if (action.payload?.section_c) {
          _state = merge(_state, action.payload.section_c);
        }

        if (action.payload?.msg) {
          _state = merge(_state, { error: action.payload.msg });
        }

        return merge(state, _state);
      }
    },
    [resetAnnuityApplication]: (state, action) => {
      return merge(state, initialState);
    },
  },
});

export const { update, setFundValues } = sliceC.actions;
export default sliceC.reducer;
