import React from "react";
import styles from "./SectionTitle.module.css";
import cn from "classnames";
import { Icon, Segment } from "semantic-ui-react";

const SectionTitle = ({
  title,
  active,
  completed,
  onClick,
  index,
  disabled,
}) => {
  return (
    <Segment
      style={{
        marginBottom: 0,
      }}
      className={`${styles.sectionTitle} ${
        active === true ? styles.active : ""
      } ${completed === true ? styles.completed : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div>
        <h4>
          {completed === false && (
            <span>
              <Icon circular size="large">
                <strong>{index + 1}</strong>
              </Icon>
            </span>
          )}
          {completed === true && active === false && (
            <span>
              <Icon
                circular
                name="check"
                size="large"
                style={{ background: "#21ba45", color: "white" }}
              />
            </span>
          )}
          {completed === true && active === true && (
            <span>
              <Icon
                circular
                name="check"
                size="large"
                style={{
                  background: "transparent",
                  color: "white",
                  boxShadow: "0em 0em 0em 0.1em rgb(255 255 255 / 100%) inset",
                }}
              />
            </span>
          )}
          {title}
        </h4>
      </div>
    </Segment>
  );
};

export default SectionTitle;
