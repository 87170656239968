import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import maritime from "../utils/maritime";
import payments from "../utils/payments";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-left",
  showConfirmButton: false,
  showCloseButton: true,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

// TODO add all async thunks here to fix circular dependency issues

export const resumeAnnuityApplication = createAsyncThunk(
  "annuity/resume",
  async ({ policy }, { rejectWithValue }) => {
    try {
      const { data } = await maritime.get(`/eapps/annuity/${policy}`);
      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;

        Toast.fire({
          icon: "error",
          titleText:
            data?.msg || "Something went wrong, contact technology services",
        });

        return rejectWithValue(error.response.data);
      }

      Toast.fire({
        icon: "error",
        titleText:
          error.message || "Something went wrong, contact technology services",
      });

      return rejectWithValue();
    }
  },
  {
    condition: (args, { getState, requestId }) => {
      const { loading, currentRequestId } = getState().annuity;
      if (loading === "pending" && requestId !== currentRequestId) {
        return false;
      }
    },
  }
);

/**
 * Initializes and annuity application
 */
export const initialize = createAsyncThunk(
  "ANNUITY/APPLICATION/INITIALIZE",
  async (args, { getState, rejectWithValue, requestId }) => {
    const { client, appclient, quote, agent, policy, appid } =
      getState().annuity;
    try {
      const { data } = await maritime.post(
        `/eapps/annuity/${quote}/initialize`,
        {
          policy,
          client,
          appclient,
          agent,
          appid,
          quote,
          appref: requestId,
        }
      );
      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;

        Toast.fire({
          icon: "error",
          titleText: data.msg || "Something went wrong, contact technology",
        });

        return rejectWithValue(data);
      }

      Toast.fire({
        icon: "error",
        titleText:
          error.mesage || "Something went wrong, contact technology services",
      });

      throw error;
    }
  }
);

/**
 *  Sends an email to the client about the client portal
 */
export const sendClientPortalInformation = createAsyncThunk(
  "annuity/email/send",
  async (args, thunkApi) => {
    const { getState, rejectWithValue } = thunkApi;
    const { annuity, annuity_section_a, annuity_section_b } = getState();
    const { pay_rwid, pay_id, pay_key, completed, email_sent } = annuity;

    const { insfname, insemail } = annuity_section_a;
    const { owncompany, ownemail } = annuity_section_b;

    if (completed === true || email_sent === true) {
      return;
    }

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_EMAIL_URI}`,
        null,
        {
          params: {
            pass: process.env.REACT_APP_MAIL_PASS,
            key: process.env.REACT_APP_MAIL_KEY,
            pay_rwid,
            pay_id,
            pay_key,
            pay_fname:
              insfname.value !== "" ? insfname.value : owncompany.value,
            pay_email: insemail.value !== "" ? insemail.value : ownemail.value,
          },
        }
      );

      if (data.Status !== "SUCCESSFUL") {
        throw new Error(
          "Error sending client portal email, contact technology services"
        );
      }

      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;

        Toast.fire({
          icon: "error",
          titleText:
            data.msg || "Something went wrong, contact technology services",
        });

        return rejectWithValue(data);
      }

      Toast.fire({
        icon: "error",
        titleText: error.message,
      });
    }
  }
);

/**
 * Creates a payment entry in the online payment system
 */
export const createPaymentEntry = createAsyncThunk(
  "annuity/payment/create",
  async (args, thunkApi) => {
    const { getState, rejectWithValue } = thunkApi;
    const { annuity, annuity_section_a, annuity_section_b, annuity_section_c } =
      getState();
    const { final_policy, client, appref, pay_rwid, completed } = annuity;
    const { inssurname, insfname, insemail } = annuity_section_a;
    const { ownemail, owncompany } = annuity_section_b;
    const { mdtfpr } = annuity_section_c;

    if (pay_rwid !== "" || completed === true) {
      return;
    }

    try {
      // using separate axios instance for external requests
      const { data } = await axios.post(
        `${process.env.REACT_APP_PAYMENTS_URI}/ext_create.php`,
        null,
        {
          params: {
            appol: final_policy,
            apclt: client || 0,
            apdesc: "Triflex Annuity Application",
            amt: mdtfpr.value === "" ? 0 : mdtfpr.value,
            damt: 0,
            fname: insfname.value !== "" ? insfname.value : owncompany.value,
            sname: inssurname.value,
            link: "http://128.1.2.49:3000/applications/annuity/section-e",
            ref: appref,
            email: insemail.value !== "" ? insemail.value : ownemail.value,
          },
        }
      );

      if (data.MSG === "Payment added successfully") {
        return data;
      }
      throw new Error("Could not create payment, contact technology services");
    } catch (error) {
      if (error.response) {
        const { data } = error.response;

        Toast.fire({
          icon: "error",
          titleText:
            data.msg || "Something went wrong, contact technology services",
        });

        return rejectWithValue(data);
      }

      Toast.fire({
        icon: "error",
        titleText:
          error.message || "Something went wrong, contact technology services",
      });

      return rejectWithValue();
    }
  }
);

/**
 * Creates and returns a policy number
 */
export const getPolicyNumber = createAsyncThunk(
  "policy/create",
  async (args, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const { appref, final_policy, completed } = getState().annuity;

    if (final_policy !== "" || completed === true) {
      return;
    }

    try {
      // using separate axios instance for external requests
      // const { data } = await maritime.post("/eapps/policy", { appref });

      const { data } = await payments.post("/ext_policy.php", null, {
        params: {
          ref: appref,
          pass: process.env.REACT_APP_POLICY_PASS,
        },
      });

      if (parseInt(data.POLICY) > 0) {
        return data.POLICY;
      }
      throw new Error("Could not get a policy number");
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        Toast.fire({
          icon: "error",
          titleText:
            data.msg || "Something went wrong, contact technology services",
        });

        return rejectWithValue(data);
      }

      Toast.fire({
        icon: "error",
        titleText: error.message,
      });

      return rejectWithValue();
    }
  }
);

/**
 * TODO: need to confirm if this works
 */
export const saveAnnuity = createAsyncThunk(
  "annuity/save",
  async (args, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const {
      annuity,
      annuitySectionA,
      annuitySectionB,
      annuitySectionC,
      annuitySectionD,
      annuitySectionE,
    } = getState();

    try {
      const { data } = await maritime.post("/eapps/annuity/save", {
        ...annuity,
        ...annuitySectionA,
        ...annuitySectionB,
        ...annuitySectionC,
        ...annuitySectionD,
        ...annuitySectionE,
      });
      Swal.fire({
        icon: "success",
        title: "Application saved",
        toast: true,
        position: "bottom-left",
        timer: 3000,
        showConfirmButton: false,
        showCloseButton: true,
      });
      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        Swal.fire({
          icon: "error",
          titleText:
            data?.msg || "Something went wrong, contact technology services",
          toast: true,
          timer: 3000,
          position: "bottom-left",
          showConfirmButton: false,
        });

        return rejectWithValue(error.response.data.error);
      }

      Swal.fire({
        icon: "error",
        titleText:
          error.message || "Something went wrong, contact technology services",
        toast: true,
        timer: 3000,
        position: "bottom-left",
        showConfirmButton: false,
        showCloseButton: true,
      });

      return rejectWithValue();
    }
  }
);

/**
 * Completes an annuity application
 */
export const completeAnnuityApplication = createAsyncThunk(
  "annuity/application/complete",
  async (args, { getState, rejectWithValue }) => {
    const { annuity } = getState();
    const { appid, agent, policy, client, final_policy, quote, completed } =
      annuity;

    if (completed === true) {
      return;
    }

    try {
      const { data } = await maritime.post("/eapps/annuity/complete", {
        appid,
        quote,
        agent,
        policy,
        client,
        final_policy,
      });

      Toast.fire({
        icon: "success",
        titleText: "Application completed",
      });

      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;

        Toast.fire({
          icon: "error",
          titleText:
            data?.msg || "Something went wrong, contact technology services",
        });

        return rejectWithValue(data);
      }

      Toast.fire({
        icon: "error",
        titleText:
          error.message || "Something went wrong, contact technology services",
      });

      return rejectWithValue();
    }
  }
);

/**
 * Loads application configuration files on first load
 */
export const getConfiguration = createAsyncThunk(
  "global/getConfiguration",
  async (args, { rejectWithValue }) => {
    try {
      const { data } = await maritime.get("/eapps/config");
      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        return rejectWithValue(data);
      }
      return rejectWithValue();
    }
  }
);

/**
 * Generates a fake policy number
 */
export const getFakePolicy = createAsyncThunk(
  "global/getFakePolicy",
  async (args, { requestId, rejectWithValue, getState }) => {
    try {
      const { data } = await payments.post("/ext_policy.php", null, {
        params: {
          ref: requestId,
          pass: process.env.REACT_APP_POLICY_PASS,
          mode: 1,
        },
      });
      return data.POLICY;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        Toast.fire({
          icon: "error",
          titleText:
            data.msg || "Something went wrong, contact technology services",
        });

        return rejectWithValue(data);
      }

      Toast.fire({
        icon: "error",
        titleText:
          error.message || "Something went wrong, contact technology services",
      });

      return rejectWithValue();
    }
  }
);

export const validateAnnuitySectionA = createAsyncThunk(
  "annuity/sectionA/validate",
  async (args, { getState, requestId, rejectWithValue }) => {
    const { loading, currentRequestId, ...rest } = getState().annuity_section_a;
    const { quote, client, policy } = getState().annuity;

    try {
      const { data } = await maritime.post(`/eapps/annuity/a/validate`, {
        ...rest,
        qid: quote,
        policy,
        client,
      });

      if (data?.section_a?.is_section_valid === false) {
        Toast.fire({
          icon: "error",
          title: "Please fix errors in this section",
        });

        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        Toast.fire({
          icon: "error",
          title:
            data?.msg || "Something went wrong, contact technology services",
        });
        return rejectWithValue(data);
      }

      Toast.fire({
        icon: "error",
        titleText:
          error.message || "Something went wrong, contact technology services",
      });

      return rejectWithValue();
    }
  },
  {
    condition: (args, { getState, requestId }) => {
      const { currentRequestId, loading } = getState().annuity_section_a;
      if (currentRequestId !== requestId && loading === "pending") {
        return false;
      }
    },
  }
);

export const getAnnuitySectionB = createAsyncThunk(
  "annuity/sectionB/get",
  async (args, { getState, rejectWithValue }) => {
    const { policy, client, quote } = getState().annuity;
    const { loading, currentRequestId, beneficiary_code, ...rest } =
      getState().annuity_section_b;
    try {
      const { data } = await maritime.post("/eapps/annuity/b/get", {
        ...rest,
        policy,
        client,
        quote,
      });
      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        Toast.fire({
          icon: "error",
          titleText:
            data?.msg || "Something went wrong, contact technology services",
        });
        return rejectWithValue(data);
      }

      Toast.fire({
        icon: "error",
        titleText:
          error.message || "Something went wrong, contact technology serivces",
      });

      return rejectWithValue();
    }
  },
  {
    condition: (args, { getState, requestId }) => {
      const { currentRequestId, loading } = getState().annuity_section_b;
      if (requestId !== currentRequestId && loading === "pending") {
        return false;
      }
    },
  }
);

export const validateAnnuitySectionB = createAsyncThunk(
  "annuity/sectionB/validate",
  async (args, { getState, rejectWithValue }) => {
    const { loading, currentRequestId, ...rest } = getState().annuity_section_b;
    const { quote, client, policy, appclient } = getState().annuity;

    try {
      const { data } = await maritime.post(`/eapps/annuity/b/validate`, {
        ...rest,
        qid: quote,
        policy,
        client,
        appclient,
      });

      if (!data?.section_b?.is_section_valid) {
        Toast.fire({
          icon: "error",
          titleText: "Please fix errors in this section",
        });

        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        Toast.fire({
          icon: "error",
          titleText: data?.msg || "Something went wrong, try again later",
        });
        return rejectWithValue(data.error);
      }

      Toast.fire({
        icon: "error",
        titleText: error.message || "Something went wrong, try again later",
      });

      return rejectWithValue();
    }
  },
  {
    condition: (args, { getState, requestId }) => {
      const { loading, currentRequestId } = getState().annuity_section_b;
      if (loading === "pending" && requestId !== currentRequestId) {
        return false;
      }
    },
  }
);

export const getAnnuitySectionC = createAsyncThunk(
  "annuity/sectionC/get",
  async (args, { getState, rejectWithValue, signal }) => {
    const { policy, client, quote } = getState().annuity;
    const { loading, currentRequestId, ...rest } = getState().annuity_section_c;
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const { data } = await maritime.post(
        "/eapps/annuity/c/get",
        {
          ...rest,
          policy,
          client,
          quote,
        },
        { cancelToken: source.token }
      );
      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        Toast.fire({
          icon: "error",
          titleText:
            data?.msg || "Something went wrong, contact technology services",
        });

        return rejectWithValue(data);
      }

      Toast.fire({
        icon: "error",
        titleText:
          error.message || "Something went wrong, contact technology services",
      });

      return rejectWithValue();
    }
  },
  {
    condition: (args, { getState, requestId }) => {
      const { loading, currentRequestId } = getState().annuity_section_c;
      if (loading === "pending" && requestId !== currentRequestId) {
        return false;
      }
    },
  }
);

export const getFakePolicyNumber = createAsyncThunk(
  "CLIENT/FAKE_POLICY_NUMBER/GET",
  async (args, { requestId, rejectWithValue }) => {
    try {
      const { data } = await maritime.post("/eapps/policy/fake", { requestId });

      return data.POLICY;
    } catch (err) {
      if (err.response) {
        const { data } = err.response;
        Toast.fire({
          icon: "error",
          titleText:
            data.msg || "Something went wrong, contact technology services",
        });
        return rejectWithValue(data);
      }

      Toast.fire({
        icon: "error",
        titleText: err.message,
      });

      throw err;
    }
  }
);

export const getFakeClientNumber = createAsyncThunk(
  "ANNUITY/FAKE_CLIENT_NUMBER/GET",
  async (args, { rejectWithValue, requestId }) => {
    try {
      const { data } = await maritime.post("/eapps/client/fake", { requestId });
      return data;
    } catch (err) {
      if (err.response) {
        const { data } = err.response;

        Toast.fire({
          icon: "error",
          titleText:
            data.msg || "Something went wrong, contact technology services",
        });

        return rejectWithValue(data);
      }

      Toast.fire({
        icon: "error",
        titleText: err.message,
      });

      throw err;
    }
  }
);

export const getClientNumber = createAsyncThunk(
  "annuity/clientNumber/get",
  async (args, { getState, rejectWithValue }) => {
    const { fname, sname } = args;
    const { agent } = getState().annuity;
    const body = {
      fname,
      sname,
      agent,
    };
    try {
      const { data } = await maritime.post("/eapps/client", body);
      return data.CLTNO;
    } catch (err) {
      if (err.response) {
        const { data } = err.response;

        Toast.fire({
          icon: "error",
          titleText:
            data.msg || "Something went wrong, contact technology services",
        });

        return rejectWithValue(data.data.CLTNO);
      }

      Toast.fire({
        icon: "error",
        titleText: err.message,
      });

      throw err;
    }
  }
);

export const validateAnnuitySectionC = createAsyncThunk(
  "annuity/sectionC/validate",
  async (args, { getState, rejectWithValue }) => {
    const { loading, currentRequestId, ...rest } = getState().annuity_section_c;
    const { quote, client, policy, appclient } = getState().annuity;

    try {
      const { data } = await maritime.post(`/eapps/annuity/c/validate`, {
        ...rest,
        qid: quote,
        policy,
        client,
        appclient,
      });

      if (!data?.section_c.is_section_valid) {
        Toast.fire({
          icon: "error",
          titleText: "Please fix errors in this section",
        });

        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        Toast.fire({
          icon: "error",
          titleText:
            data?.msg || "Something went wrong, contact technolog services",
        });
        return rejectWithValue(data);
      }

      Toast.fire({
        icon: "error",
        titleText:
          error.message || "Something went wrong, contact technolog services",
      });

      throw error;
    }
  },
  {
    condition: (args, { getState, requestId }) => {
      const { loading, currentRequestId } = getState().annuity_section_c;
      if (loading === "pending" && requestId !== currentRequestId) {
        return false;
      }
    },
  }
);

export const getAnnuitySectionD = createAsyncThunk(
  "annuity/sectionD/get",
  async (args, { getState, rejectWithValue }) => {
    const { policy, client, quote } = getState().annuity;
    const { loading, currentRequestId, ...rest } = getState().annuity_section_d;
    try {
      const { data } = await maritime.post("/eapps/annuity/d/get", {
        ...rest,
        policy,
        client,
        quote,
      });

      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        Toast.fire({
          icon: "error",
          titleText:
            data?.msg || "Something went wrong, contact technology services",
        });
        return rejectWithValue(data);
      }

      Toast.fire({
        icon: "error",
        titleText:
          error.message || "Something went wrong, contact technology services",
      });

      return rejectWithValue();
    }
  },
  {
    condition: (args, { getState, requestId }) => {
      const { loading, currentRequestId } = getState().annuity_section_d;
      if (loading === "pending" && requestId !== currentRequestId) {
        return false;
      }
    },
  }
);

export const validateAnnuitySectionD = createAsyncThunk(
  "annuity/sectionD/validate",
  async (args, { getState, rejectWithValue }) => {
    const { loading, currentRequestId, ...rest } = getState().annuity_section_d;
    const { quote, client, policy, appclient } = getState().annuity;

    try {
      const { data } = await maritime.post(`/eapps/annuity/d/validate`, {
        ...rest,
        qid: quote,
        policy,
        client,
        appclient,
      });

      if (!data?.section_d?.is_section_valid) {
        Toast.fire({
          icon: "error",
          titleText: "Please fix errors in this section",
        });

        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        Toast.fire({
          icon: "error",
          titleText:
            data?.msg || "Something went wrong, contact technology services",
        });

        return rejectWithValue(data.error);
      }

      Toast.fire({
        icon: "error",
        titleText:
          error.message || "Something went wrong, contact technology services",
      });

      return rejectWithValue();
    }
  },
  {
    condition: (args, { getState, requestId }) => {
      const { loading, currentRequestId } = getState().annuity_section_d;
      if (loading === "pending" && requestId !== currentRequestId) {
        return false;
      }
    },
  }
);

export const getAnnuityAgentCert = createAsyncThunk(
  "annuity/agentCert/get",
  async (args, { getState, rejectWithValue, signal }) => {
    const { policy, client, quote } = getState().annuity;
    const { loading, currentRequestId, ...rest } = getState().annuity_section_d;
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const { data } = await maritime.post(
        "/eapps/annuity/agent-cert/get",
        {
          ...rest,
          policy,
          client,
          quote,
        },
        { cancelToken: source.token }
      );
      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        Toast.fire({
          icon: "error",
          titleText:
            data?.msg || "Something went wrong, contact technology services",
        });
        return rejectWithValue(data);
      }

      Toast.fire({
        icon: "error",
        titleText:
          error.message || "Something went wrong, contact technology services",
      });

      return rejectWithValue();
    }
  },
  {
    condition: (args, { getState, requestId }) => {
      const { loading, currentRequestId } = getState().annuity_agent_cert;
      if (loading === "pending" && requestId !== currentRequestId) {
        return false;
      }
    },
  }
);

export const validateAnnuityAgentCert = createAsyncThunk(
  "annuity/agentCert/validate",
  async (args, { getState, rejectWithValue }) => {
    const { loading, currentRequestId, ...rest } =
      getState().annuity_agent_cert;
    const { quote, client, policy, appclient } = getState().annuity;

    try {
      const { data } = await maritime.post(
        `/eapps/annuity/agent-cert/validate`,
        {
          ...rest,
          qid: quote,
          policy,
          client,
          appclient,
        }
      );

      if (!data?.agent_cert?.is_section_valid) {
        Toast.fire({
          icon: "error",
          titleText: "Please fix errors in this section",
        });

        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;

        Toast.fire({
          icon: "error",
          titleText:
            data?.msg || "Something went wrong, contact technology services",
        });

        return rejectWithValue(data.error);
      }

      Toast.fire({
        icon: "error",
        titleText:
          error.message || "Something went wrong, contact technology services",
      });

      return rejectWithValue();
    }
  },
  {
    condition: (args, { getState, requestId }) => {
      const { loading, currentRequestId } = getState().annuity_agent_cert;
      if (loading === "pending" && requestId !== currentRequestId) {
        return false;
      }
    },
  }
);
