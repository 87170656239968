import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { selectWhiteScreen } from "../annuitySlice";
import BeneficiaryList from "./BeneficiaryList";

const getType = (type) => {
  if (type === "gold") return "P";
  if (type === "platnium") return "C";
  return "";
};

const Trigger = ({ onClick, disabled }) => {
  return (
    <Form.Group widths="equal" style={{ marginBottom: "0" }}>
      <Form.Field>
        <Button
          icon
          type="button"
          floated="right"
          onClick={onClick}
          disabled={disabled}
        >
          <Icon name="user plus" />
        </Button>
      </Form.Field>
    </Form.Group>
  );
};

const Beneficiary = ({ disabled }) => {
  const { policy, xagt, type } = useSelector(selectWhiteScreen);
  const [open, setOpen] = useState(false);
  const t = getType(type);
  const baseUrl = process.env.REACT_APP_CLIENT_SYSTEM_BASE_URL;

  const onAdd = () => {
    const win = window.open(
      `${baseUrl}/ea_clt001.pgm?rtype=3&pol=${policy}&xagt=${xagt}&t=${t}`,
      "clientSystem"
    );
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      dimmer="inverted"
      trigger={<Trigger onClick={onOpen} disabled={disabled} />}
    >
      <Modal.Header>Maintain Beneficiary</Modal.Header>
      <Modal.Content>
        <BeneficiaryList onAdd={onAdd} />
      </Modal.Content>
      <Modal.Actions>
        <Button basic size="small" onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default Beneficiary;
