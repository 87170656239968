import { useEffect, useCallback } from "react";

function useFormShortcuts(next, back) {
  if (!next || typeof next !== "function") {
    throw new Error(
      "The first parameter to `useFormShortcuts` must be a function."
    );
  }

  const keydownListener = useCallback(
    (keydownEvent) => {
      const { code, altKey, repeat } = keydownEvent;
      if (repeat) return;
      if (code === "KeyP" && altKey) {
        if (typeof back === "function") {
          // throw new Error(
          //   "The second parameter to `useFormShortcuts` must be a function"
          // );
          back();
        }
      }
      if (code === "KeyN" && altKey) {
        if (typeof next === "function") {
          next();
        }
      }
    },
    [next, back]
  );

  useEffect(() => {
    window.addEventListener("keydown", keydownListener, true);

    return () => {
      window.removeEventListener("keydown", keydownListener, true);
    };
  }, [next, back]);
}

export default useFormShortcuts;
