import React from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { Toast } from "../../../utils/options";
import {
  useDeleteBeneficiaryMutation,
  useGetBeneficiariesQuery,
  useMakePrimaryMutation,
} from "./beneficiarySlice";

const BeneficiaryListItem = ({ clientId }) => {
  const { client } = useGetBeneficiariesQuery("", {
    selectFromResult: ({ data, ...rest }) => ({
      client: data.find((ben) => ben.ABCLTNO === clientId),
      ...rest,
    }),
  });

  const [makePrimary, { isLoading: isPrimaryLoading }] =
    useMakePrimaryMutation();

  const [deleteBeneficiary, { isLoading: isDeleteLoading }] =
    useDeleteBeneficiaryMutation();

  const handleMakePrimary = (clientId) => {
    makePrimary(clientId)
      .unwrap()
      .then((payload) => {
        Toast.fire({
          icon: "success",
          titleText: "Primary beneficiary changed.",
        });
      });
  };

  const handleDeleteBeneficiary = (clientId) => {
    deleteBeneficiary(clientId)
      .unwrap()
      .then((payload) => {
        Toast.fire({
          icon: "success",
          titleText: "Beneficiary deleted.",
        });
      });
  };

  return (
    <Table.Row>
      <Table.Cell collapsing>{client?.ABCLTNO}</Table.Cell>
      <Table.Cell>{client?.BEN}</Table.Cell>
      <Table.Cell>{client?.MRELDE}</Table.Cell>
      <Table.Cell>
        <Button
          icon
          size="tiny"
          labelPosition="right"
          primary={parseInt(client?.ABSEQ) === 1}
          loading={isPrimaryLoading}
          disabled={isPrimaryLoading || isDeleteLoading}
          onClick={() => handleMakePrimary(client?.ABCLTNO)}
        >
          Primary
          <Icon name="arrow up" />
        </Button>
      </Table.Cell>
      <Table.Cell>
        <Button
          icon
          size="small"
          labelPosition="right"
          loading={isDeleteLoading}
          disabled={isPrimaryLoading || isDeleteLoading}
          onClick={() => handleDeleteBeneficiary(client?.ABCLTNO)}
        >
          Delete <Icon name="user delete" />
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

export default BeneficiaryListItem;
