import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SectionA from "./section-a/SectionA";
import SectionB from "./section-b/SectionB";
import SectionC from "./section-c/SectionC";
import SectionD from "./section-d/SectionD";
import AgentCertificate from "./agent-certificate/AgentCertificate";

import {
  resumeAnnuityApplication,
  initialize,
  saveAnnuity,
  getFakePolicy,
} from "../../thunks";
import { reset, update as updateAnnuity } from "./annuitySlice";
import useQuery from "../../hooks/useQuery";
import Application from "../application/Application";
import { createSelector, unwrapResult } from "@reduxjs/toolkit";
import useFundValues from "../../hooks/useFundValues";
import { setToken } from "../auth/authSlice";

const initialSections = [
  {
    key: "annuity-section-a",
    title: "Section A",
    disabled: false,
    visible: true,
    hidden: false,
  },
  {
    key: "annuity-section-b",
    title: "Section B",
    disabled: false,
    visible: false,
    hidden: false,
  },
  {
    key: "annuity-section-c",
    title: "Section C",
    disabled: false,
    visible: false,
    hidden: false,
  },
  {
    key: "annuity-section-d",
    title: <div>Section D</div>,
    disabled: false,
    visible: false,
    hidden: false,
  },
  {
    key: "annuity-agent-certificate",
    title: "Agent Certificate",
    disabled: false,
    visible: false,
    hidden: false,
  },
];

const annuitySelector = createSelector(
  (state) => state.annuity,
  (annuity) => annuity
);

const Annuity = (props) => {
  const query = useQuery();

  const dispatch = useDispatch();

  let history = useHistory();
  const state = useSelector(annuitySelector);

  const save = (values) => {
    dispatch(updateAnnuity(values));
    dispatch(saveAnnuity());
  };

  const agentNext = (values) => {};

  const [sections, setSections] = useState(initialSections);

  const progress = () => {
    if (state.is_application_valid) {
      return 100;
    }

    let totalSections = sections.filter((item) => item.hidden === false).length;
    let completedSections = state.completed_sections.length;
    let ratio = completedSections / totalSections;

    return Math.floor(ratio * 100);
  };

  const handleNext = () => {
    const currentIndex = sections.findIndex((val) => val.visible === true);
    if (currentIndex === sections.length - 1) return;

    const nextIndex = sections.findIndex(
      (val, _index) =>
        _index > currentIndex && !val.hidden && !val.disabled && !val.visible
    );

    setSections((prev) =>
      prev.map((val, _index) => {
        if (_index === nextIndex) {
          return { ...val, visible: true };
        }
        return { ...val, visible: false };
      })
    );
  };

  const handleBack = () => {
    const currentIndex = sections.findIndex((val) => val.visible === true);

    if (currentIndex - 1 < 0) return;

    const previousIndex = sections.reduce((acc, value, index) => {
      if (
        index < currentIndex &&
        !value.disabled &&
        !value.hidden &&
        !value.visible
      ) {
        return index;
      }

      return acc;
    }, -1);

    setSections((prev) =>
      prev.map((val, _index) => {
        if (_index === previousIndex) {
          return { ...val, visible: true };
        }
        return { ...val, visible: false };
      })
    );
  };

  const handleSelectSection = (sectionKey) => {
    const _currentSection = sections.find((val) => val.visible === true);
    if (_currentSection.key === sectionKey) return;
    setSections((prev) =>
      prev.map((val) => {
        if (val.key === sectionKey) {
          return { ...val, visible: true };
        }
        return { ...val, visible: false };
      })
    );
  };

  const agentBack = (values) => {
    dispatch(updateAnnuity(values));
    handleBack();
  };

  const forms = [
    {
      key: "annuity-section-a",
      form: <SectionA handleNext={handleNext} />,
    },
    {
      key: "annuity-section-b",
      form: <SectionB handleNext={handleNext} handleBack={handleBack} />,
    },
    {
      key: "annuity-section-c",
      form: <SectionC handleNext={handleNext} handleBack={handleBack} />,
    },
    {
      key: "annuity-section-d",
      form: <SectionD handleNext={handleNext} handleBack={handleBack} />,
    },
    {
      key: "annuity-agent-certificate",
      form: (
        <AgentCertificate
          defaultValues={state}
          next={agentNext}
          back={agentBack}
          save={save}
        />
      ),
    },
  ];

  const start = query.get("isNew") === "true";
  const quote = query.get("qid");
  const agent = query.get("agtno");
  const client = query.get("cltno");
  const appclient = query.get("appclient");
  const policy = query.get("policy");
  const resume = query.get("resume") === "true";
  const token = query.get("token");

  const { is_initialized, is_resumed } = state;

  useEffect(() => {
    // checks if applications is new and intializes it
    if (start && !isNaN(quote)) {
      (async function () {
        history.replace(
          `/applications/annuity/?agtno=${agent}&cltno=${client}&appclient=${appclient}&qid=${quote}`
        );

        // update the information from nova
        dispatch(reset({ quote, client, agent, appclient }));
        dispatch(setToken({ token }));
        try {
          // must unwrap result to handle throw errors properly
          dispatch(updateAnnuity({ loading: "pending", action: "initialize" }));

          // get the fake policy number
          const p1 = await dispatch(getFakePolicy());
          const p1Result = unwrapResult(p1);

          // update the fake policy number in the store
          dispatch(
            updateAnnuity({
              policy: p1Result,
              appref: p1.meta.requestId,
              fakePolicy: p1Result,
            })
          );

          // initialize the application
          const p2 = await dispatch(initialize());
          const p2Result = unwrapResult(p2);

          history.replace(`/applications/annuity/?policy=${p1Result}`);
        } catch (err) {
          dispatch(updateAnnuity({ loading: "idle", action: "" }));
        }
      })();
    }
  }, [
    dispatch,
    start,
    quote,
    agent,
    client,
    appclient,
    is_initialized,
    history,
    token,
  ]);

  // resume application
  useEffect(() => {
    if (resume && !isNaN(policy)) {
      (async function () {
        history.replace(`/applications/annuity/?policy=${policy}`);

        // reset client state
        dispatch(reset());

        dispatch(setToken({ token }));

        // get application information from the server
        try {
          await dispatch(resumeAnnuityApplication({ policy }));
        } catch (err) {}
      })();
    }
  }, [policy, resume, is_resumed, dispatch, history, token]);

  useEffect(() => {
    // hide section d if application does not have waiver premium
    setSections((prev) => {
      return prev.map((item) => {
        if (item.key === "annuity-section-d") {
          return { ...item, hidden: !state.has_waiver_premium };
        }
        return item;
      });
    });
  }, [state.has_waiver_premium]);

  useEffect(() => {
    // hide section if gold plan
    if (state.annuity_type === "gold") {
      setSections((prev) => {
        return prev.map((item) => {
          if (item.key === "annuity-section-b") {
            return { ...item, hidden: true };
          }
          return item;
        });
      });
    }

    // show section if platinum plan
    if (state.annuity_type === "platinum") {
      setSections((prev) => {
        return prev.map((item) => {
          if (item.key === "annuity-section-b") {
            return { ...item, hidden: false };
          }
          return item;
        });
      });
    }
  }, [state.annuity_type]);

  useFundValues(query.get("gov"), query.get("corp"), query.get("prop"));

  return (
    <>
      <Application
        progress={progress()}
        forms={forms}
        sections={sections}
        completed={state.is_application_valid}
        loading={
          state.loading === "pending" &&
          ["initialize", "resume"].includes(state.action)
        }
        header="Annuity Application"
        subheader="Maritime Life (Caribbean) Limited"
        completedSections={state.completed_sections}
        onSectionClicked={handleSelectSection}
      />
    </>
  );
};

export default Annuity;
