import React from "react";
import {
  Button,
  Container,
  Header,
  Icon,
  Menu,
  Image,
} from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { toggle } from "../sidebar/sidebarSlice";
import logo from "../../img/maritime_logo.png";
import { Link } from "react-router-dom";

const Nav = () => {
  // const dispatch = useDispatch();
  // const openMenu = () => dispatch(toggle());

  return (
    <Menu className="maritime" inverted secondary size="small">
      <Container fluid>
        <Menu.Item>
          <Image src={logo} style={{ height: "4.8em" }} />
        </Menu.Item>
        {/* <Menu.Menu position="right">
          <Menu.Item as={Link} to="/mlcl" link>
            Life Application
          </Menu.Item>
          <Menu.Item>
            <Button
              icon
              style={{ background: "none" }}
              size="small"
              onClick={openMenu}
            >
              <Icon name="bars" className="white" size="large" />
            </Button>
          </Menu.Item>
        </Menu.Menu> */}
      </Container>
    </Menu>
  );
};

export default Nav;
