import React from "react";
import { Sidebar } from "semantic-ui-react";
import Nav from "./features/navigation/Nav";
import Routes from "./components/routes";

const AppContent = () => {
  return (
    <Sidebar.Pusher>
      <Nav />
      <Routes />
    </Sidebar.Pusher>
  );
};

export default AppContent;
