import React from "react";
import { Button, Icon, Loader, Table } from "semantic-ui-react";
import BeneficiaryListItem from "./BeneficiaryListItem";
import { useGetBeneficiariesQuery } from "./beneficiarySlice";

const BeneficiaryList = ({ onAdd }) => {
  const { data, isLoading, isFetching, isError, error, refetch } =
    useGetBeneficiariesQuery("", { pollingInterval: 1000 * 30 });

  const onRefresh = () => {
    refetch();
  };

  if (isLoading) {
    return <Loader active content="Loading..." inline="centered" />;
  }

  if (isError) {
    return <div>{JSON.stringify(error)}</div>;
  }

  return (
    <div>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Client #</Table.HeaderCell>
            <Table.HeaderCell>Client Name</Table.HeaderCell>
            <Table.HeaderCell>Relationship</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={5} textAlign="center">
                No beneficiaries found
              </Table.Cell>
            </Table.Row>
          )}
          {data.map((client) => (
            <BeneficiaryListItem
              key={client.ABCLTNO}
              clientId={client.ABCLTNO}
            />
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="5">
              <Button size="small" basic onClick={onAdd}>
                Add
              </Button>
              <Button
                icon
                loading={isFetching}
                disabled={isFetching}
                size="small"
                basic
                type="button"
                onClick={onRefresh}
              >
                Reload <Icon name="redo" />
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
};

export default BeneficiaryList;
