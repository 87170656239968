import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setFundValues } from "../features/annuity/section-c/slice";

const useFundValues = (government_bonds, corporate_bonds, property_bonds) => {
  let dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    const { location } = history;
    const { pathname, search } = location;
    if (government_bonds && corporate_bonds && property_bonds) {
      dispatch(
        setFundValues({
          fund1: corporate_bonds,
          fund2: government_bonds,
          fund3: property_bonds,
        })
      );

      // delete values from url
      let searchParams = new URLSearchParams(search);
      searchParams.delete("gov");
      searchParams.delete("prop");
      searchParams.delete("corp");
      history.replace(`${pathname}${searchParams.toString()}`);
    }
  }, [government_bonds, corporate_bonds, property_bonds, history]);
};

export default useFundValues;
