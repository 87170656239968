import { createSelector } from "@reduxjs/toolkit";
import React from "react";
import { useSelector } from "react-redux";
import { Button, Segment } from "semantic-ui-react";
const styles = {
  padding: "0",
};

const selectFundValues = createSelector(
  [
    (state) => state.annuity,
    (state) => state.annuity_section_a,
    (state) => state.auth,
  ],
  (annuity, sectionA, { token }) => {
    return {
      id: annuity.policy,
      fname: sectionA.insfname.value,
      lname: sectionA.inssurname.value,
      token,
    };
  }
);

const RiskSelector = () => {
  const { id, fname, lname, token } = useSelector(selectFundValues);
  const handleClick = (e) => {
    e.stopPropagation();
    let url = process.env.REACT_APP_RISK_SELECTOR_URL;
    let return_url = process.env.REACT_APP_RISK_SELECTOR_RETURN_URL;

    window.location.href = `${url}/?id=${id}&fname=${fname}&lname=${lname}&return_url=${return_url}&csrf_token=${token}`;
  };
  return (
    <Segment basic clearing style={styles}>
      <Button onClick={handleClick} type="button" floated="right">
        Risk Selector
      </Button>
    </Segment>
  );
};

export default RiskSelector;
