import { combineReducers } from "redux";
import mlcl from "../features/mlcl/mlclSlice";
import applications from "../features/application/applicationSlice";
import mpp from "../features/mpp/mppSlice";
import sidebar from "../features/sidebar/sidebarSlice";
import auth from "../features/auth/authSlice";
import accidental from "../features/accidental/accidentalSlice";
import quote from "../features/quote/quoteSlice";
import checkout from "../features/checkout/checkoutSlice";
import annuity from "../features/annuity/annuitySlice";
import annuity_section_a from "../features/annuity/section-a/sliceA";
import annuity_section_b from "../features/annuity/section-b/slice";
import annuity_section_c from "../features/annuity/section-c/slice";
import annuity_section_d from "../features/annuity/section-d/slice";
import annuity_agent_cert from "../features/annuity/agent-certificate/slice";
import global from "../reducers/global";
import { lifeApi } from "../services/lifeApi";

export default combineReducers({
  mpp,
  mlcl,
  global,
  sidebar,
  auth,
  applications,
  accidental,
  quote,
  checkout,
  annuity,
  annuity_section_a,
  annuity_section_b,
  annuity_section_c,
  annuity_section_d,
  annuity_agent_cert,
  [lifeApi.reducerPath]: lifeApi.reducer,
});
