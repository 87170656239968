import React, { useState, createRef, useRef, useEffect } from "react";
import { nanoid } from "@reduxjs/toolkit";
import {
  Grid,
  Header,
  Progress,
  Ref,
  Segment,
  Sticky,
  Transition,
  Placeholder,
  Container,
  Button,
} from "semantic-ui-react";
import SectionTitle from "./SectionTitle";
import styles from "./Application.module.css";
import { ReactComponent as ApplicationHeaderLogoLarge } from "../../img/application-header-large.svg";
import CompleteApplication from "../annuity/complete-application/CompleteApplication";

// dumb componen that renders the layout of the application
// parent of this component subscribes to the state

const ApplicationHeader = ({ header, subheader }) => {
  return (
    <Header size="huge" textAlign="left" className={styles.header}>
      {header}
      <Header.Subheader className={styles.subHeader}>
        {subheader}
      </Header.Subheader>
    </Header>
  );
};

const ApplicationNavigation = ({ percent }) => {
  return (
    <Segment>
      <Header content="Application Progress" sub textAlign="center" />
      <Progress
        percent={percent}
        color="green"
        active
        size="small"
        progress
        style={{ marginBottom: "0" }}
      />
    </Segment>
  );
};

const Application = ({
  header,
  subheader,
  progress,
  sections,
  forms,
  completedSections,
  loading,
  onSectionClicked,
  completed,
}) => {
  const stickyRef = useRef(null);
  return (
    <>
      <div
        style={{
          position: "absolute",
          display: "black",
          width: "100%",
          margin: "0",
          padding: "0",
        }}
      >
        <ApplicationHeaderLogoLarge
          style={{
            width: "100%",
            height: "100%",
            margin: 0,
            padding: 0,
          }}
        />
      </div>
      <Container className="page" style={{ position: "relative" }}>
        <ApplicationHeader header={header} subheader={subheader} />
        <Grid>
          <Ref innerRef={stickyRef}>
            <Grid.Column width="6">
              {loading === true ? (
                <Segment.Group>
                  <Segment
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Placeholder
                      style={{
                        height: "25px",
                        width: "25%",
                        marginBottom: "4px",
                        display: "inline-block",
                      }}
                    >
                      <Placeholder.Line />
                    </Placeholder>
                    <Placeholder
                      style={{
                        height: "17px",
                        width: "95%",
                        marginTop: "0",
                        display: "inline-block",
                        borderRadius: "5px",
                      }}
                    >
                      <Placeholder.Image />
                    </Placeholder>
                  </Segment>
                  <Segment>
                    <Placeholder>
                      <Placeholder.Header image>
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Segment>
                  <Segment>
                    <Placeholder>
                      <Placeholder.Header image>
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                    </Placeholder>
                  </Segment>
                </Segment.Group>
              ) : (
                <Sticky context={stickyRef} offset={10}>
                  <Segment.Group>
                    <ApplicationNavigation percent={progress} />
                    {sections
                      .filter((item) => item.hidden === false)
                      .map((_section, index) => (
                        <SectionTitle
                          key={_section.key}
                          active={_section.visible === true}
                          title={_section.title}
                          completed={
                            completedSections.includes(_section.key) ||
                            completed
                          }
                          onClick={() => {
                            if (_section.disabled === true) {
                              return;
                            }
                            onSectionClicked(_section.key);
                          }}
                          disabled={_section.disabled}
                          index={index}
                        />
                      ))}
                    <CompleteApplication />
                  </Segment.Group>
                </Sticky>
              )}
            </Grid.Column>
          </Ref>
          <Grid.Column width="10">
            {loading === true ? (
              <Segment>
                <Grid columns={2}>
                  <Grid.Column>
                    <Placeholder
                      style={{
                        height: "25px",
                        width: "20%",
                        marginBottom: "8px",
                      }}
                    >
                      <Placeholder.Line />
                    </Placeholder>
                    <Placeholder style={{ height: "28px", marginTop: 0 }} fluid>
                      <Placeholder.Image />
                    </Placeholder>
                  </Grid.Column>
                  <Grid.Column>
                    <Placeholder
                      style={{
                        height: "25px",
                        width: "30%",
                        marginBottom: "8px",
                      }}
                    >
                      <Placeholder.Line />
                    </Placeholder>
                    <Placeholder style={{ height: "28px", marginTop: 0 }}>
                      <Placeholder.Image />
                    </Placeholder>
                  </Grid.Column>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Placeholder
                        style={{
                          height: "25px",
                          width: "20%",
                          marginBottom: "8px",
                        }}
                      >
                        <Placeholder.Line />
                      </Placeholder>
                      <Placeholder
                        style={{ height: "40px", marginTop: 0 }}
                        fluid
                      >
                        <Placeholder.Image />
                      </Placeholder>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={3}>
                    <Grid.Column>
                      <Placeholder
                        style={{
                          height: "25px",
                          width: "25%",
                          marginBottom: "8px",
                        }}
                      >
                        <Placeholder.Line />
                      </Placeholder>
                      <Placeholder
                        style={{ height: "28px", marginTop: 0 }}
                        fluid
                      >
                        <Placeholder.Image />
                      </Placeholder>
                    </Grid.Column>
                    <Grid.Column>
                      <Placeholder
                        style={{
                          height: "25px",
                          width: "30%",
                          marginBottom: "8px",
                        }}
                      >
                        <Placeholder.Line />
                      </Placeholder>
                      <Placeholder
                        style={{ height: "28px", marginTop: 0 }}
                        fluid
                      >
                        <Placeholder.Image />
                      </Placeholder>
                    </Grid.Column>
                    <Grid.Column>
                      <Placeholder
                        style={{
                          height: "25px",
                          width: "20%",
                          marginBottom: "8px",
                        }}
                      >
                        <Placeholder.Line />
                      </Placeholder>
                      <Placeholder
                        style={{ height: "28px", marginTop: 0 }}
                        fluid
                      >
                        <Placeholder.Image />
                      </Placeholder>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            ) : (
              <Transition.Group animation="fade left">
                {sections.map(({ visible, key }) => {
                  if (visible) {
                    const selectedForm = forms.find((val) => val.key === key);
                    return <div key={key}>{selectedForm.form}</div>;
                  }
                })}
              </Transition.Group>
            )}
          </Grid.Column>
        </Grid>
      </Container>
    </>
  );
};

export default Application;
