import { createSlice } from "@reduxjs/toolkit";
import {
  getConfiguration,
  initialize,
  resumeAnnuityApplication,
} from "../thunks";

const initialState = {
  occupations: [],
  banks: [],
  agents: [],
  sources: [],
  relationships: [],
  is_initial_load: true,
  loading: false,
};

const slice = createSlice({
  name: "global",
  initialState,
  reducers: {
    initializeSystem: (state, action) => {
      state.is_initial_load = false;
    },
  },
  extraReducers: {
    [initialize.fulfilled]: (state, action) => {
      if (action.payload?.banks) {
        state.banks = action.payload.banks;
      }

      if (action.payload?.agents) {
        state.agents = action.payload.agents;
      }

      if (action.payload?.sources) {
        state.sources = action.payload.sources;
      }

      if (action.payload?.relationships) {
        state.relationships = action.payload.relationships;
      }

      if (action.payload?.occupations) {
        state.occupations = action.payload.occupations;
      }
    },
    [resumeAnnuityApplication.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.banks = payload.banks;
        state.sources = payload.sources;
        state.agents = payload.agents;
        state.relationships = payload.relationships;
        state.occupations = payload.occupations;
      }
    },
    [getConfiguration.pending]: (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    },
    [getConfiguration.rejected]: (state, action) => {
      if (state.loading === true) {
        state.loading = false;
      }
    },
    [getConfiguration.fulfilled]: (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.occupations = action.payload;
      }
    },
  },
});

export const {} = slice.actions;

export default slice.reducer;
