import React from "react";
import { Route } from "react-router-dom";

const ConfigRoute = ({ component: Component, ...props }) => {
  return (
    <>
      <Route {...props} component={Component} />
    </>
  );
};

export default ConfigRoute;
