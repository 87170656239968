import { createSlice } from "@reduxjs/toolkit";
import { merge } from "lodash";
import { resetAnnuityApplication } from "../../../actions";
import {
  getAnnuityAgentCert,
  validateAnnuityAgentCert,
  resumeAnnuityApplication,
  completeAnnuityApplication,
  getPolicyNumber,
  createPaymentEntry,
  sendClientPortalInformation,
  validateAnnuitySectionC,
} from "../../../thunks";

const initialState = {
  ac1: {
    value: "",
    valid: false,
  },
  ac2: {
    value: "",
    valid: false,
  },
  ac3a: {
    value: "",
    valid: false,
  },
  ac3b: {
    value: "",
    valid: false,
  },
  ac4: {
    value: "",
    valid: false,
  },
  ac5: {
    value: "",
    valid: false,
  },
  ac6: {
    value: "",
  },
  ac7a: {
    value: "",
    valid: false,
  },
  ac7b: {
    value: "",
    valid: true,
  },
  ac8a: {
    value: "",
  },
  ac8b: {
    value: "",
    valid: true,
  },
  acss: {
    value: "9999",
    valid: true,
  },
  acbank: {
    value: "9999",
    valid: true,
  },
  acbankbr: {
    value: "9999",
    valid: true,
  },
  acr: {
    value: "",
    valid: true,
  },
  agtno: {
    value: "0",
    valid: true,
  },
  agtno2: {
    value: "0",
    value: true,
  },
  agtno3: {
    value: "0",
    value: true,
  },
  agtsp1: {
    value: "100.00",
    valid: true,
  },
  agtsp2: {
    value: "0",
    valid: true,
  },
  agtsp3: {
    value: "0",
    valid: true,
  },
  opaysrc: {
    value: "",
  },
  comments: {
    value: "",
  },
  agt: "",
  loading: "idle",
  error: null,
  currentRequestId: "",
  is_initialized: false,
};

const slice = createSlice({
  name: "annuity_agent_cert",
  initialState,
  reducers: {
    update: (state, action) => {
      return merge(state, action.payload);
    },
  },
  extraReducers: {
    [getPolicyNumber.pending]: (state) => {
      state.loading = "pending";
    },
    [getPolicyNumber.rejected]: (state) => {
      state.loading = "idle";
    },
    [getPolicyNumber.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [createPaymentEntry.pending]: (state) => {
      state.loading = "pending";
    },
    [createPaymentEntry.rejected]: (state) => {
      state.loading = "idle";
    },
    [createPaymentEntry.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [sendClientPortalInformation.pending]: (state) => {
      state.loading = "pending";
    },
    [sendClientPortalInformation.rejected]: (state) => {
      state.loading = "idle";
    },
    [sendClientPortalInformation.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [resetAnnuityApplication]: (state, action) => {
      return merge(state, initialState);
    },
    [completeAnnuityApplication.pending]: (state) => {
      state.loading = "pending";
    },
    [completeAnnuityApplication.rejected]: (state) => {
      state.loading = "idle";
    },
    [completeAnnuityApplication.fulfilled]: (state) => {
      return Object.assign({}, state, initialState);
    },
    [resumeAnnuityApplication.fulfilled]: (state, action) => {
      return merge(state, initialState);
    },
    [getAnnuityAgentCert.pending]: (state, action) => {
      const { requestId } = action.meta;
      state.loading = "pending";
      state.error = null;
      state.currentRequestId = requestId;
    },
    [getAnnuityAgentCert.rejected]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;

      // reset loading and requestId
      let _state = { loading: "idle", currentRequestId: "" };

      if (action?.payload?.agent_cert) {
        // update values if present
        _state = merge(_state, { is_initialized: true });
        _state = merge(_state, action.payload.agent_cert);
      }

      if (action?.payload?.msg) {
        _state = _state.merge(_state, { error: action.payload });
      }

      // update against current state
      return merge(state, _state);
    },
    [getAnnuityAgentCert.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;

      if (loading === "pending" && currentRequestId === requestId) {
        let _state = {
          loading: "idle",
          error: null,
          currentRequestId: "",
          is_initialized: true,
        };
        if (action?.payload?.agent_cert) {
          _state = merge(_state, action.payload.agent_cert);
        }
        return merge(state, _state);
      }
    },
    [validateAnnuitySectionC.fulfilled]: (state) => {
      state.is_initialized = false;
    },
    [validateAnnuityAgentCert.pending]: (state, action) => {
      const { requestId } = action.meta;
      const { loading } = state;
      if (loading === "idle") {
        state.loading = "pending";
        state.error = null;
        state.currentRequestId = requestId;
      }
    },
    [validateAnnuityAgentCert.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        let _state = { loading: "idle", error: null, currentRequestId: "" };

        if (action?.payload?.agent_cert) {
          _state = merge(_state, action.payload.agent_cert);
        }

        return merge(state, _state);
      }
    },
    [validateAnnuityAgentCert.rejected]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;

      if (loading === "pending" && currentRequestId === requestId) {
        let _state = { loading: "idle", error: null, currentRequestId: "" };

        if (action?.payload?.agent_cert) {
          _state = merge(_state, action.payload.agent_cert);
        }

        return merge(state, _state);
      }
    },
  },
});

export const { update } = slice.actions;

export default slice.reducer;
