import { createSlice } from "@reduxjs/toolkit";
import { initial, merge } from "lodash";
import {
  completeAnnuityApplication,
  createPaymentEntry,
  getPolicyNumber,
  initialize,
  resumeAnnuityApplication,
  sendClientPortalInformation,
  validateAnnuitySectionA,
} from "../../../thunks";
import { resetAnnuityApplication } from "../../../actions";

const initialState = {
  inssurname: {
    value: "",
    valid: true,
  },
  insfname: {
    value: "",
    valid: true,
  },
  insmidname: {
    value: "",
  },
  insmaidname: {
    value: "",
  },
  insdob: {
    value: "",
  },
  insdobd: {
    value: "",
    valid: true,
  },
  insdobm: {
    value: "",
    valid: true,
  },
  insdoby: {
    value: "",
    valid: true,
  },
  insage: {
    value: "",
    valid: true,
  },
  inscountry: {
    value: "",
    valid: true,
  },
  citizenship: {
    value: "",
  },
  inssex: {
    value: "",
    valid: true,
  },
  insmaritalst: {
    value: "",
    valid: true,
  },
  insid: {
    value: true,
  },
  insiddp: {
    value: "",
    valid: true,
  },
  insidid: {
    value: "",
    valid: true,
  },
  insidpass: {
    value: "",
  },
  insmailadd: {
    value: "",
    valid: true,
  },
  insmailadd1: {
    value: "",
    valid: true,
  },
  insmailadd2: {
    value: "",
    valid: true,
  },
  insmailadd3: {
    value: "",
  },
  inscurradd: {
    value: "",
  },
  inscurradd1: {
    value: "",
    valid: true,
  },
  inscurradd1: {
    value: "",
    value: true,
  },
  inscurradd2: {
    value: "",
    valid: true,
  },
  inscurradd3: {
    value: "",
  },
  postalcode: {
    value: "",
  },
  timeataddr: {
    value: "",
  },
  instel: {
    value: "",
    valid: true,
  },
  insemail: {
    value: "",
    valid: true,
  },
  hometel: {
    value: "",
  },
  mobileno: {
    value: "",
  },
  insprevadd: {
    value: "",
  },
  empstatus: {
    value: "",
    valid: true,
  },
  insoccupation: {
    value: "",
    valid: true,
  },
  insjobtitleduties: {
    value: "",
    valid: true,
  },
  insemployer: {
    value: "",
    valid: true,
  },
  insworkphone: {
    value: "",
    valid: true,
  },
  insempadd: {
    value: "",
  },
  insempadd1: {
    value: "",
    valid: true,
  },
  insempadd2: {
    value: "",
    valid: true,
  },
  insempadd3: {
    value: "",
  },
  seregistered: {
    value: "",
  },
  setype: {
    value: "",
  },
  sename: {
    value: "",
  },
  insparttimejob: {
    value: "",
  },
  insptemployer: {
    value: "",
  },
  insno: {
    valid: true,
  },
  loading: "idle",
  currentRequestId: "",
  is_section_valid: false,
  is_application_valid: false,
};

const sliceA = createSlice({
  name: "annuity_section_a",
  initialState,
  reducers: {
    update: (state, { payload }) => {
      return merge(state, payload);
    },
    reset: (state) => {
      return merge(state, initialState);
    },
  },
  extraReducers: {
    [resetAnnuityApplication]: (state) => {
      return merge(state, initialState);
    },
    [initialize.fulfilled]: (state, { payload }) => {
      if (payload?.section_a) {
        return merge(state, payload.section_a);
      }
    },
    [getPolicyNumber.pending]: (state) => {
      state.loading = "pending";
    },
    [getPolicyNumber.rejected]: (state) => {
      state.loading = "idle";
    },
    [getPolicyNumber.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [createPaymentEntry.pending]: (state) => {
      state.loading = "pending";
    },
    [createPaymentEntry.rejected]: (state) => {
      state.loading = "idle";
    },
    [createPaymentEntry.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [sendClientPortalInformation.pending]: (state) => {
      state.loading = "pending";
    },
    [sendClientPortalInformation.rejected]: (state) => {
      state.loading = "idle";
    },
    [sendClientPortalInformation.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [resumeAnnuityApplication.fulfilled]: (state, { payload }) => {
      if (payload?.section_a) {
        return merge(state, payload.section_a);
      }
    },
    [completeAnnuityApplication.pending]: (state) => {
      state.loading = "pending";
    },
    [completeAnnuityApplication.rejected]: (state) => {
      state.loading = "idle";
    },
    [completeAnnuityApplication.fulfilled]: (state) => {
      return Object.assign({}, state, initialState);
    },
    [validateAnnuitySectionA.pending]: (state, action) => {
      const { requestId } = action.meta;
      const { loading } = state;
      if (loading === "idle") {
        state.loading = "pending";
        state.error = null;
        state.currentRequestId = requestId;
      }
    },
    [validateAnnuitySectionA.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;
      if (loading === "pending" && currentRequestId === requestId) {
        let _state = { loading: "idle", error: null, currentRequestId: "" };

        if (action?.payload?.section_a) {
          _state = merge(_state, action.payload.section_a);
        }
        return _state;
      }
    },
    [validateAnnuitySectionA.rejected]: (state, action) => {
      const { requestId } = action.meta;
      const { loading, currentRequestId } = state;

      if (loading === "pending" && currentRequestId === requestId) {
        let _state = { loading: "idle", error: null, currentRequestId: "" };
        if (action?.payload?.section_a) {
          _state = merge(_state, action.payload.section_a);
        }

        if (action?.payload?.msg) {
          _state = merge(_state, { error: action.payload.msg });
        }

        return merge(state, _state);
      }
    },
  },
});

export const { update, reset } = sliceA.actions;
export default sliceA.reducer;
